import {
    Box,
    Grid,
    TextField,
    Button,
    InputAdornment
} from '@mui/material';
import { FaSearch } from "react-icons/fa";

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};
const styledButton = {
    backgroundColor: '#33383b',
    height: 40,
    color: '#c4af6c',
    '&:hover': {
        backgroundColor: '#c4af6c',
        color: '#33383b',
    },
};

const FiltroTrabajadoresDrawer = ({
    handleChange,
    handleBlur,
    filtros,
    setFiltros,
    handleSubmit,
    values,
    touched,
    errors
}) => {
    return (
        <>
            <div className='middleline'>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ p: 1 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <TextField
                                        id="input-with-icon-textfield"
                                        label="Buscador"
                                        margin="normal"
                                        name="buscador"
                                        sx={inputStyles}
                                        size="small"
                                        value={values.buscador}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.buscador && Boolean(errors.buscador)}
                                        helperText={touched.buscador && errors.buscador}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FaSearch />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    type="submit"
                                    sx={styledButton}
                                    size='small'
                                    variant="outline-dark"
                                    onClick={handleSubmit}
                                >
                                    <FaSearch />
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </div >
        </>
    );
}

export default FiltroTrabajadoresDrawer;
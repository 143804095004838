import { useState, useEffect } from 'react';
import { Drawer, Box, Typography, Checkbox, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../Tables/DataTable';
import { useTrabajadores } from '../../hooks';
import { useFormValidation } from '../../utilities/formValidator';
import * as Yup from 'yup';
import { FiltroTrabajadoresDrawer} from '../Filtros';

const CheckboxStyles = {
    '&.Mui-checked': {
        color: '#c4af6c',
    },
    '& .MuiSvgIcon-root': {
        color: '#33383b',
        fontSize: 28
    },
    '&:hover': {
        backgroundColor: 'rgba(196, 175, 108, 0.04)',
    },
};

const DrawerTrabajadores = ({ open, setOpen, setTrabajador, trabajador, toSave, sucursal }) => {
    const { loading, data, fetchData } = useTrabajadores();
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState({sucursal_id:sucursal?.id});
    const [initialValues, setInitialValues] = useState({
        sucursal_id: null,
        buscador:''
    });
    
    const validationSchema = Yup.object().shape({
        sucursal_id: Yup.string().nullable(),
        buscador: Yup.string().nullable(),
    });
    const headers = [ 
        {
            checkCli: 'checkCli',
            numeric: false,
            disabledPadding: false,
            label: '',
            overrideFunc: (data, row) => (
                
                <Checkbox
                    sx={CheckboxStyles}
                    onChange={(e) => addTrabajador(e, row)}
                    checked={trabajador?.id === row?.id}
                />
            ),
        },
        {
            nombre: 'nombre',
            numeric: false,
            disabledPadding: false,
            label: 'Nombre',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.nombre}
                </Typography>
            )
        },
        {
            apellidos: 'apellidos',
            numeric: false,
            disabledPadding: false,
            label: 'Apellidos',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.apellidos}
                </Typography>
            )
        },
        {
            telefono: 'telefono',
            numeric: false,
            disabledPadding: false,
            label: 'Teléfono',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.telefono}
                </Typography>
            )
        },
        {
            email: 'email',
            numeric: false,
            disabledPadding: false,
            label: 'Email',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.email}
                </Typography>
            )
        },
    ];
    
    useEffect(() => {
        if (sucursal && sucursal?.id) {
            setFiltros(prevData => ({
                ...prevData,
                sucursal_id: sucursal?.id
            }));
            setInitialValues(prevValues => ({
                ...prevValues,
                sucursal_id: sucursal?.id
            }));
        }
    }, [sucursal, trabajador]);
    const addTrabajador = (e, tr) => {
        const checked = e.target;

        if (checked) {
            setTrabajador(prevState => ({ ...prevState, [`${toSave}`]: tr }));
        } else {
            setTrabajador(prevState => ({ ...prevState}));
        }
    };
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setValues, handleReset } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const { buscador } = values;
            await fetchData({ offset: currentPage, limit: 1000,
                filtros: { 
                    sucursal_id: initialValues.sucursal_id, 
                    buscador:buscador
                } 
            });
        },
    });
    return (
        <Drawer anchor="right" open={open} onClose={() => setOpen(false)} sx={{zIndex:2000}}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <IconButton size='small' onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <div className='container'>
                <Box component="section" sx={{ p: 3 }}>
                    <Typography variant='h3'>Trabajadores</Typography>
                    <Typography variant='p'>Debes seleccionar un trabajador</Typography>
                </Box>
            </div>
            <FiltroTrabajadoresDrawer
                handleChange={handleChange}
                handleBlur={handleBlur}
                filtros={filtros}
                values={values}
                touched={touched}
                errors={errors}
                setFiltros={setFiltros}
                handleSubmit={handleSubmit}
            />
            <div className='middleline'>

                <DataTable 
                    headers={headers}
                    data={data}
                    isLoading={loading}
                    filtros={filtros}
                    currentPage={currentPage}
                    fetcher={fetchData}
                    mensaje={"No hay trabajadores disponibles"}
                />
            </div>
        </Drawer>
    );
}

export default DrawerTrabajadores;

import axios from "axios";

const urlPath = "articles";

export const listsArticles = async ({ offset, limit }) => {
  try {
    const response = await axios.get(urlPath, { offset, limit });
    console.log(response);
    
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const listTrends = async (data) => {
  try {
      const response = await axios.post(`${urlPath}/filter`, data.filtros, {
          params: data.params,
      });
      // console.log(response.data);
      return response.data;
  } catch (error) {

      console.log(error);
  }
};

export const articleDetails = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const articleCreate = async (data) => {
  try {
    const response = await axios.post(urlPath, data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const articleUpdate = async (id, data) => {
  try {
    const response = await axios.put(`${urlPath}/${id}`, data);

    console.log(response);
    return response.status;
  } catch (error) {
    console.log(error);
  }
};

export const articleDelete = async (id) => {
  try {
    const response = await axios.delete(`${urlPath}/${id}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const articleImage = async (img) => {
  try {
    const response = await axios.get(`/images/${img}`);
    return response;
  } catch (error) {
    console.log(error);
  }
};
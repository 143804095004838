import React, { useState, useEffect, useReducer } from "react";
import {
  Download,
  XCircleFill,
  PencilFill,
  EyeFill,
  TrashFill,
  TypeUnderline,
} from "react-bootstrap-icons";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import Paginacion from "../../components/Paginacion";
import { Link } from "react-router-dom";
import {
  destinationCreate,
  destinationDelete,
  listDestinations,
} from "../../services";
import { SyncLoader } from "react-spinners";
import { SnackbarUtilities } from "../../utilities";
import { Button, Form, InputGroup } from "react-bootstrap";
import LHModal from "../../components/Modals/LHModal";
import Swal from "sweetalert2";

const columns = [
  { Header: "ID", accessor: "id" },
  {
    Header: "Nombre",
    accessor: "name",
  },
  /* {
    Header: "Código ISO",
    accessor: "iso_code",
  }, */
  { Header: "Acción", accessor: "actions" },
];

function Destinos() {
  const [destinos, setDestinos] = useState([]);
  const [destinationForm, setDestinationForm] = useState({
    destination: "",
  });
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const tableInstance = useTable(
    { columns, data: destinos, initialState: { pageSize: 20 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, // Obtener la página actual
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = tableInstance;

  const fetchData = async () => {
    try {
      const response = await listDestinations({ offset: 1, limit: 1000 });
      // console.log(response.data.data);

      setDestinos(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const resetForm = () => {
    setDestinationForm({
      destination: "",
    });
    setShowModal(false);
  };

  const handleClick = (e) => {
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        title: "¿Borrar este Destino?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Si",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const response = await destinationDelete(id);
          // console.log(response.data);
          await fetchData();
          SnackbarUtilities.success("Destino eliminado con éxito");
        } else if (result.isDenied) {
          return;
        }
      });
    } catch (error) {
      console.error(error);
      SnackbarUtilities.error("Error al eliminar el Destino");
    }
  };

  const handleCreateDestination = async () => {
    try {
      const response = await destinationCreate({ ...destinationForm });
      // console.log(response.data);
      SnackbarUtilities.success("Destino creado con éxito");
      await fetchData();
    } catch (error) {
      console.error(error);
      SnackbarUtilities.error("Error al crear el Destino");
    } finally {
      resetForm();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container" style={{ position: "relative" }}>
      {/* <BreadCrumb /> */}
      <LHModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={"Crear Nuevo Destino"}
        closeButtomTitle={"Guardar"}
        style={{ marginLeft: "6%" }}
        size="small"
        onButtonClick={handleCreateDestination}
      >
        <InputGroup className="mb-3" style={{ gap: "16px" }}>
          <Form.Control
            placeholder="Nombre del Destino"
            name="name"
            onChange={({ target }) =>
              setDestinationForm({ destination: target.value })
            }
          />
          {/* <Form.Control
            placeholder="Código ISO"
            name="iso_code"
            onChange={({ target }) =>
              setLanguageForm({ ...languageForm, iso_code: target.value })
            }
          /> */}
        </InputGroup>
      </LHModal>
      <div className="top-line">
        <div style={{ textAlign: "start" }}>
          <h3>Destinos</h3>
        </div>
      </div>
      <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
        <Button onClick={handleClick} variant="outline-dark">
          Crear
        </Button>
      </div>
      <div className="middleline">
        <div className="form-group buscador">
          <input
            className="form-control"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar en la tabla..."
          />
        </div>
        <div style={{ display: "flex", flex: 1, justifyContent: "end" }}>
          <Paginacion
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
          />
        </div>
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            placeItems: "center",
            placeContent: "center",
            width: "100%",
            minHeight: 450,
            backgroundColor: "#fff",
            marginBottom: 15,
            borderRadius: 5,
          }}
        >
          <SyncLoader color="#33383b" />
        </div>
      ) : (
        <table
          {...getTableProps()}
          className="table"
          style={{ width: "100%", backgroundColor: "#fff" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Añadir la funcionalidad para permitir el ordenamiento
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " ↓"
                          : " ↑"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="table-group-divider" {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const prop = row.original;
              if(prop.status){
                return (
                  <tr
                    className="pb-5"
                    {...row.getRowProps()}
                    key={row.id}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{prop.id}</td>
                    <td>{prop.destination}</td>
                    {/* <td>{prop.iso_code}</td> */}
                    {/*  <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {prop.translations?.map((lang) => (
                          <GetLanguageFlags
                            countryCode={lang.language.iso_code}
                            key={lang.id}
                          />
                        ))}
                      </div>
                    </td> */}
                    {/* <td>{prop.role.nombre}</td> */}
                    <td>
                      <div className="botones">
                        <button
                          className="btn btn-outline-dark"
                          style={{ marginLeft: "10px" }}
                          onClick={() => handleDelete(prop.id)}
                        >
                          <TrashFill />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      )}
      <Paginacion
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
      />
    </div>
  );
}

export default Destinos;

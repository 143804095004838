export const validateTextosIdioma = (textos) => {
    let alert = {
        proyecto: { es: '', en: '' },
        entorno: { es: '', en: '' },
        disenyo: { es: '', en: '' },
        ubicacion: { es: '', en: '' }
    };
    // console.log(textos);
    textos.forEach(element => {

        if (element.proyecto == null || element.proyecto.length <= 0) {
            if (element.id_idioma == 1) {
                alert.proyecto['es'] = `El proyecto en Español debe tener más de 30 caracteres y no puede estar vacío`;
            } else {
                alert.proyecto['en'] = `El proyecto en Inglés debe tener más de 30 caracteres y no puede estar vacío`;
            }

        }
        if (element.entorno == null || element.entorno.length <= 0) {
            if (element.id_idioma == 1) {
                alert.entorno['es'] = `El entorno en Español debe tener más de 30 caracteres y no puede estar vacío`;
            } else {
                alert.entorno['en'] = `El entorno en Inglés debe tener más de 30 caracteres y no puede estar vacío`;
            }

        }
        if (element.disenyo == null || element.disenyo.length <= 0) {
            if (element.id_idioma == 1) {
                alert.disenyo['es'] = `El diseño en Español debe tener más de 30 caracteres y no puede estar vacío`;
            } else {
                alert.disenyo['en'] = `El diseño en Inglés debe tener más de 30 caracteres y no puede estar vacío`;
            }
        }
        if (element.ubicacion == null || element.ubicacion.length <= 0) {
            if (element.id_idioma == 1) {
                alert.ubicacion['es'] = `El ubicación en Español debe tener más de 30 caracteres y no puede estar vacío`;
            } else {
                alert.ubicacion['en'] = `El ubicación en Inglés debe tener más de 30 caracteres y no puede estar vacío`;
            }
        }
    });
    return alert;
};

export const normalizarProvincias = (provincia) => {
    // console.log(provincia);
    // console.log(provincia.charAt(0).toUpperCase() + provincia.slice(1).toLowerCase());

    switch (provincia?.charAt(0)?.toUpperCase() + provincia?.slice(1)?.toLowerCase()) {
        case 'Almeria':
            return 'Almería';
        case 'Alicante/alacant':
            return 'Alicante/Alacant';
        case 'Alicante':
            return 'Alicante/Alacant';
        case 'A coruña':
            return 'A Coruña';
        case 'Araba/álava':
            return 'Araba/Álava';
        case 'Illes balears':
            return 'Illes Balears';
        case 'Baleares':
            return 'Illes Balears';
        case 'Castellon':
            return 'Castellón/Castelló';
        case 'Castellón/castelló':
            return 'Castellón/Castelló';
        case 'Cadiz':
            return 'Cádiz';
        case 'Ciudad real':
            return 'Ciudad Real';
        case 'La rioja':
            return 'La Rioja';
        case 'Las palmas':
            return 'Las Palmas';
        case 'Santa cruz de tenerife':
            return 'Santa Cruz de Tenerife';
        case 'Valencia/valència':
            return 'Valencia/València';
        case 'Valencia':
            return 'Valencia/València';
            
        default:
            return provincia?.charAt(0)?.toUpperCase() + provincia?.slice(1)?.toLowerCase();
    }
};
export const normalizarMunicipios = (provincia) => {
    switch (provincia?.charAt(0)?.toUpperCase() + provincia?.slice(1)?.toLowerCase()) {
        case 'Cadiz':
            return 'Cádiz';
        case 'Ciudad real':
            return 'Ciudad Real';
        case 'La rioja':
            return 'La Rioja';
        case 'Las palmas':
            return 'Las Palmas';
        case 'Santa cruz de tenerife':
            return 'Santa Cruz de Tenerife';
        case 'Valencia/valència':
            return 'Valencia/València';
        case 'Valencia':
            return 'Valencia/València';
        default:
            return provincia?.charAt(0)?.toUpperCase() + provincia?.slice(1)?.toLowerCase();
    }
}

function levenshteinDistance(a, b) {
    const matrix = Array.from({ length: a.length + 1 }, () =>
        Array(b.length + 1).fill(0)
    );

    for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
    for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

    for (let i = 1; i <= a.length; i++) {
        for (let j = 1; j <= b.length; j++) {
            if (a[i - 1] === b[j - 1]) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j] + 1,
                    matrix[i][j - 1] + 1,
                    matrix[i - 1][j - 1] + 1
                );
            }
        }
    }

    return matrix[a.length][b.length];
}

export const findMostSimilarItem = (text, array, property) => {
    try {
        const normalizedText = text.trim().toLowerCase();
        let bestMatch = null;
        let highestScore = -Infinity;

        for (const item of array) {
            const value = item[property]?.trim().toLowerCase() || "";

            let score = 0;

            // Coincidencia exacta
            if (value === normalizedText) {
                score += 100; // Prioridad máxima
            }

            // Coincidencia parcial (substring)
            if (value.includes(normalizedText)) {
                score += 70;
            }

            // Coincidencia palabra por palabra
            const inputWords = normalizedText.split(/\s+/);
            const valueWords = value.split(/[\s/]+/);
            for (const word of inputWords) {
                if (valueWords.includes(word)) {
                    score += 50; // Coincidencia parcial por palabra
                }
            }

            // Similaridad por Levenshtein
            const distance = levenshteinDistance(normalizedText, value);
            const similarity = 1 - distance / Math.max(normalizedText.length, value.length);
            score += similarity * 50; // Ajusta el peso de Levenshtein según la longitud

            // Determinar el mejor match
            if (score > highestScore) {
                highestScore = score;
                bestMatch = item;
            }
        }

        return bestMatch.name;
    } catch (error) {
        console.log(error);

    }

}


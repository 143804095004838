import React, { useState, useEffect, useMemo } from "react";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { useParams } from "react-router-dom";
import {sucursalDetails, JsonCustomTexts } from "../../services/sucursales.service";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  styled,
  List,
  ListItemText,
  ListSubheader,
  ListItemButton,
  ListItemIcon

} from '@mui/material';
import UserProfile from '../trabajadores/TrabajadorInfoCard';
import { FaMapLocationDot, FaPhone, FaCity, FaRegEnvelope } from "react-icons/fa6";

import { DataTable, FiltrosPropiedades, DrawerSucursal } from "../../components";
import { useSucursales, useTrabajadores } from '../../hooks';
import { dynamicTableHeader } from "../../utilities/transformHeaders";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
export default function FichaSucursal() {
  const [property, setProperty] = useState({});
  const [tab, setTab] = useState(0);
  const { id } = useParams();
  const [sucursal, setSucursal] = useState([]);

  // const { data: dataTrabajadores, fetchData: fetchDataTrabajadores, loading: loadingTrabajadores, limite: limiteTrabajadores } = useTrabajadores();
  const { data, fetchData, loading, limite, dataCustomNames } = useTrabajadores();

  const [currentPage, setCurrentPage] = useState(1);
  const [filtros, setFiltros] = useState({});
  const [propiedad, setPropiedad] = useState({});
  const [contactos, setContactos] = useState({});
  const [clientes, setClientes] = useState({});
  const [trabajadores, setTrabajadores] = useState({});
  const [customHeaders, setCustomHeaders] = useState(null);

  let titulosPersonalizadosTrabajadores;
  let titulosPersonalizadosContactos;
  let titulosPersonalizadosClientes;

  const [dataSucursal, setDataSucursal] = useState({});

  useEffect(() => {
    if (id) fetchDataSucursal();
  }, []);

  const fetchDataSucursal = async () => {
    const response = await sucursalDetails(id);
    // console.log(response);
    
    delete response.id;
    delete response.createdAt;
    delete response.updatedAt;
    delete response.status;
    delete response.id_respo;
    delete response.id_coor;
    delete response.tipo;
    delete response.responsable;
    delete response.coordinador;
    // console.log(response);
    
    titulosPersonalizadosTrabajadores = JsonCustomTexts(response.sucursal_trabajadores, "Trabajadores", "titulos_tabla");
    titulosPersonalizadosContactos = JsonCustomTexts(response.sucursal_contactos, "Contactos", "titulos_tabla");

    setTrabajadores(titulosPersonalizadosTrabajadores);
    if (response.estado === 1) {
      response.estado = 'Publicado en la Web';
    } else {
      response.estado = 'No Publicado';
    }
    delete response.sucursal_contactos;
    // delete response.sucursal_clientes;
    delete response.sucursal_trabajadores;

    setDataSucursal(response);
  }
  const accordiondata = (item) => {

    return (<UserProfile UserInfo={item.id}></UserProfile>)
  }
  const filtrosMemorizados = useMemo(() => ({
    sucursal_id: id
  }), [id]);
  
  return (
    <div className="container">
      {/* <BreadCrumb data={property?.referencia} /> */}
      <div className="top-line">
        <div style={{ textAlign: "start" }}>

          <div className="contenido">
            <div className="header">
              <h2>Datos generales de {dataSucursal.nombre_sucursal}</h2>
            </div>
            <hr />
            <div className="info-cliente">
              <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <FaMapLocationDot />
                  </ListItemIcon>
                  <ListItemText primary="Ubicación" secondary={`${dataSucursal.provincia} - ${dataSucursal.municipio}`} />
                </ListItemButton>
                <ListItemButton>
                  <ListItemIcon>
                    <FaCity />
                  </ListItemIcon>
                  <ListItemText primary="Direccion" secondary={`${dataSucursal.direccion} - ${dataSucursal.cp}`} />
                </ListItemButton>
                <ListItemButton>
                  <ListItemIcon>
                    <FaPhone/>
                  </ListItemIcon>
                  <ListItemText primary="Teléfono" secondary={`${dataSucursal.telefono}`} />
                </ListItemButton>
                <ListItemButton>
                  <ListItemIcon>
                    <FaRegEnvelope/>
                  </ListItemIcon>
                  <ListItemText primary="Email" secondary={`${dataSucursal?.email}`} />
                </ListItemButton>
              </List>
              <Table>
                <TableBody>
                  {Object.entries(sucursal).map(([key, value]) =>
                    <StyledTableRow key={key}>
                      {key == "Nombre Sucursal" ? <></> :
                        <StyledTableCell component="th" scope="row">
                          <b>{key}</b>
                        </StyledTableCell>
                      }
                      {key == "Nombre Sucursal" ? <></> :
                        <StyledTableCell component="th" scope="row">
                          {value}
                        </StyledTableCell>
                      }
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>

          <div className="contenido">
            <Tabs /*onSelect={(index, label) => setTab(index)}*/>
              <Tab label="TRABAJADORES">
                {Array.isArray(trabajadores) && trabajadores.length > 0 ? (
                  // console.log("hiii"),
                  <DataTable
                  data={data}
                  fetcher={fetchData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  mensaje={"No hay trabajadores disponibles"}
                  isLoading={loading}
                  orderTable={"ASC"}
                  orderTableBy={"nombre"}
                  // filters={values}
                  // onClickRow={(row)=>console.log(row.id)}
                  accordionData={accordiondata}
                  filtros={filtrosMemorizados}
                  headers={dynamicTableHeader({ headerArrayData: dataCustomNames, showJustThis: ["Nombre", "Apellidos", "Email", "Teléfono Personal", "Teléfono Empresarial"] })}
              />
                ) : (<><br></br><h3 style={{ textAlign: "center" }}>No hay registros</h3></>)}
              </Tab>

            </Tabs>
          </div>

        </div>
      </div>
    </div >
  )
}

import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useLayoutEffect,
} from "react";

// import "../clientes/clientes.css";
import "./propiedades.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import {
  properties,
  publishPropertie,
  noPublishPropertie,
} from "../../services";

import BreadCrumb from "../../components/navbar/Breadcrumb";
import Swal from "sweetalert2";
import { usePropiedades } from "../../hooks";
import { DataTable, FiltrosPropiedades, DrawerSucursal } from "../../components";
import { UserContext } from '../../hooks/UserContext'; // Importa el contexto
import { useUser } from "../../hooks/UserContextLogin";
import { checkSectionViewPermision, getPermissionProperties, verificarPermisosAcciones, verificarPermisosPublicar } from "../../utilities/permissionHelper";
import { dynamicTableHeader } from "../../utilities/transformHeaders";
import AlertDialog from "../../utilities/messageConfirmation";
import { SnackbarUtilities } from "../../utilities";

const Propiedades = () => {
  //const [propiedades, dispatch] = useReducer(reducer, prs);
  const { loading, data, fetchData, limite, dataCustomNames } = usePropiedades();
  // console.log(data);
  const [page, setPage] = useState(1);
  const [filtros, setFiltros] = useState({});
  const [sideOpen, setSideOpen] = useState(false);
  const [sucursal, setSucursal] = useState({});
  const [propiedad, setPropiedad] = useState({});
  const { usuario } = useContext(UserContext); // Accede al contexto del usuario
  const { user, setUser } = useUser();
  const [resetPagination, setResetPagination] = useState(null);
  // console.log(user);
  
  const publicarPropertie = async (prop) => {

    setPropiedad(prop);
    if (!prop.numsucursal) {
      setSideOpen(true); // Abre el drawer solo si numsucursal es null o undefined
    } else {
      const id_propiedad = prop?.id;

      const res = await publishPropertie({ id_propiedad });
      // console.log(res)
      if (res == 201) {
        SnackbarUtilities.success(`Publicacion con referencia ${propiedad?.referencia} hecha correctamente!`);

        await fetchData({ offset: page, limit: limite, filtros: filtros });
      } else {
        SnackbarUtilities.error(`${res === 404 ? "No se encuentra la publicación" : ""}`);
      }
    }

  };

  const publicarPropertieAddSucursal = async (datosSucursal) => {

    const { id } = propiedad;
    const id_propiedad = id;
    const { id_ext } = datosSucursal;

    // console.log(id_ext)
    const res = await publishPropertie({ id_propiedad, "numsucursal": id_ext });
    setSideOpen(!sideOpen);

    await fetchData({ offset: page, limit: limite, filtros: filtros });
    if (res == 201) {
      SnackbarUtilities.success(`Publicacion con referencia ${propiedad?.referencia} hecha correctamente!`);
    } else {
      SnackbarUtilities.error(`${res === 404 ? "No se encuentra la publicación" : ""}`);

    }

  };

  const noPublicarPropertie = async (id_propiedad) => {
    // console.log(id_propiedad);

    const res = await noPublishPropertie(id_propiedad);

    if (res === 201) {
      await fetchData({ offset: page, limit: limite, filtros: filtros });
      SnackbarUtilities.success("Publicacion Eliminada correctamente");
    } else {
      SnackbarUtilities.error("Publicacion No Eliminada correctamente");

    }

  };

  const handleSubmit = async () => {
    //console.log(filtros)
    await fetchData({ offset: page, limit: limite, filtros: filtros });
  };

  const extraCustomColumn = [
    {

      item: (name) => {
        // console.log(name);
        return (
          <div style={{ display: "flex", justifyContent: "left" }}>
            <AlertDialog txtbtnOpentitle={name.published ? "Publicado" : "Despublicado"} title={"Confirmación"} description={`¿Está seguro de que quiere ${name.published ? 'eliminar la publicación ?' : 'publicar esta propiedad ?'}`} onClickOk={() => name.published ? noPublicarPropertie(name.id) : publicarPropertie(name)}
              disable={verificarPermisosPublicar(['edit', 'delete', 'all'], user, 'Propiedades', name.numsucursal, name)}
            ></AlertDialog>
          </div>
        )
      },
      position: 9,
      titleHeader: "Situación",
      nameColumn: "published"
    },
    {
      item: (name) => {
        return (
          <div style={{ display: "flex", justifyContent: "left" }}>
            {verificarPermisosAcciones(['edit', 'delete', 'all'], user, 'Propiedades', name.numsucursal, name, null, "/Propiedades/")}
          </div>
        )
      },
      position: 9,
      titleHeader: "Acciones",
      nameColumn: "",
      order: "disable",
    }

  ]

  return (
    <>
      <div className="container">
        {/* <BreadCrumb /> */}
        <div className="topline">
          <div>
            <h3>Propiedades</h3>
          </div>
          <div>
            {/* <button className='btn btn-default me-2' onClick={() => reportProperties(filtrosSeleccionados)}><Download /></button> */}
            {/* <Link to={'/propiedades/crear'}><button className='btn btn-dark'><small className='d-flex'>Crear Inmueble</small></button></Link> */}
          </div>
        </div>
        <div className="middleline">
          <FiltrosPropiedades
            fetchData={fetchData}
            resetPagination={resetPagination}
            setResetPagination={setResetPagination}
          />
        </div>
        <div className="middleline">

          <DataTable
            headers={dynamicTableHeader({
              headerArrayData: dataCustomNames,
              showJustThis: ['Referencia', 'Agente', 'Sucursal', 'Provincia',
                'Ciudad', 'Estado', 'MLS'],
              addNewCustomHeader: extraCustomColumn
            })}
            data={data}
            isLoading={loading}
            chickable = {false}
            fetcher={fetchData}
            mensaje={"No hay propiedades disponibles"}
            filtros={filtros}
            properties={properties}
            resetPagination={resetPagination}
            setResetPagination={setResetPagination}
          />
        </div>
        <DrawerSucursal
          open={sideOpen}
          setOpen={setSideOpen}
          setSucursal={setSucursal}
          publicarPropertie={publicarPropertieAddSucursal} />
      </div>
    </>
  );
};

export default Propiedades;

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { ErrorOutline } from '@mui/icons-material';

export default function ConfirmationMessage({ open, title, description, txtBtnAceptar = "Aceptar", txtBtnCancelar = "Cancelar", onClickOk, onClose }) {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px 0" }}>
                <ErrorOutline sx={{ fontSize: 100, color: "#facea8", m: 0, p: 0 }} />
            </div>
            <DialogTitle id="alert-dialog-title" sx={{ display: "flex", justifyContent: "center", paddingTop: 0 }}>
                {title}
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ fontSize: "1.2rem", textAlign:"center" }}>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center", gap: 0.6, marginBottom: "10px" }}>
                <Button onClick={onClose} sx={{
                    color: "#fff",
                    backgroundColor: "#c4af6c",
                    '&:hover': {
                        color: "#fff",
                        backgroundColor: "#000"
                    }
                }}>{txtBtnCancelar}</Button>
                <Button
                    onClick={() => {
                        if (onClickOk) {
                            onClickOk();  // Ejecuta la acción de onClickOk
                        }
                        onClose(); // Luego cierra el diálogo
                    }}
                    sx={{
                        color: "#fff",
                        backgroundColor: "#c4af6c",
                        '&:hover': {
                            color: "#fff",
                            backgroundColor: "#000"
                        }
                    }} autoFocus>
                    {txtBtnAceptar}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box, Typography } from '@mui/material';
import { thousandFormatNumber } from '../../utilities/formDataHelper';

// Componente personalizado para filtrar valores 0 en el tooltip
function FilteredTooltipContent(props) {
    // Filtrar las series para eliminar las que tienen valores 0
    const filteredSeries = props.series.filter((serie) => serie.value !== 0);

    return (
        <div>
            {filteredSeries.map((serie, index) => (
                <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{serie.label}</span>
                    <span>{serie.formattedValue}</span>
                </div>
            ))}
        </div>
    );
}

export default function BarrasGraficoMultiColor({ series, xLabels }) {
    const noData = !series || series.length === 0 || !xLabels || xLabels.length === 0;

    // Define el formateador de valores
    const valueFormatter = (value) => (value !== 0 ? thousandFormatNumber(value, '€') : null);

    // Añade el formateador a cada serie
    series = series.map((item) => ({
        ...item,
        valueFormatter, // Agregar el valueFormatter a cada serie
    }));

    return (
        <>
            {noData ? (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="h6" color="textSecondary">
                        No hay datos disponibles para mostrar.
                    </Typography>
                </Box>
            ) : (
                <BarChart
                    width={580}
                    height={400}
                    series={series}
                    margin={{ top: 10, bottom: 30, left: 100, right: 10 }}
                    xAxis={[
                        {
                            data: xLabels,
                            scaleType: 'band',
                        },
                    ]}
                    slotProps={{
                        legend: {
                            direction: 'column',
                            position: { vertical: 'top', horizontal: 'middle' },
                            padding: 0,
                            hidden: true,
                        },
                        tooltip: {
                            slots: {
                                itemContent: FilteredTooltipContent, // Usa el componente personalizado para el tooltip
                            },
                        },
                        popper: {
                            sx: {
                                '& .MuiChartsTooltip-root': {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    '& .MuiChartsTooltip-seriesLabel': {
                                        textAlign: 'left', // Alinea el nombre con la bolita de color a la izquierda
                                        marginRight: 'auto', // Empuja el contenido hacia la izquierda
                                    },
                                    '& .MuiChartsTooltip-valueCell': {
                                        textAlign: 'right', // Centra los valores numéricos
                                    },
                                },
                            },
                        },
                    }}
                />
            )}
        </>
    );
}

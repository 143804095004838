import React, { useEffect, useState } from "react";
import {
  categoryCreate,
  categoryDetails,
  categoryUpdate,
  listLanguages,
} from "../../services";

import { useParams, useNavigate } from "react-router-dom";
import ButtonPermission from "../../utilities/ButtonPermission";
import * as Yup from 'yup';
import { useFormValidation } from "../../utilities/formValidator";
import { NoteAdd } from '@mui/icons-material';
import {
  Button, TextField, Box, Accordion, AccordionDetails, AccordionSummary
} from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { GetLanguageFlags, SnackbarUtilities } from "../../utilities";


function CreateCategoria() {
  const { id: category_id } = useParams();
  const [languages, setLanguages] = useState([]);

  const fetchLanguages = async () => {
    try {
      const response = await listLanguages({ offset: 1, limit: 1000 });

      setLanguages(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCategory = async () => {
    try {
      const response = await categoryDetails(category_id);

      let { name, translations, slug } = response.data.data;
      translations = translations.map(item => {
        const { language, ...rest } = item;
        return rest;
      });

      setValues({
        name: name,
        translations: translations,
      });

      setEditMode(true);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLanguages();
    if (category_id) fetchCategory();
  }, []);

  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    name: '',
    slug: '',
    translations: [
      {
        language_id: null,
        name: '',
      }
    ],
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo obligatorio'),
    slug: Yup.string().nullable(),
    translations: Yup.array().of(
      Yup.object().shape({
        language_id: Yup.string().nullable(),
        name: Yup.string().required('Campo obligatorio'),
      })
    ),
  });
  const { values, errors, touched, handleChange, handleReset, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
    initialValues: initialValues,
    validationSchema: validationSchema,
    nullConverter: true,
    onSubmit: async (values, modifiedFields) => {
      if (values) {
        console.log(values);

        // // Actualizar translations con id_idioma correspondiente
        const updatedTranslations = values.translations.map((translation, index) => ({
          ...translation,
          language_id: languages[index]?.id || translation.language_id,
        }));

        values.translations = updatedTranslations;
        const { name, translations } = values;

        try {
          // Llamar a la función para crear el artículo usando FormData
          const response = editMode ? await categoryUpdate(category_id, { name, translations }) : await categoryCreate({ name, translations });
          // console.log(response);

          if (response.status === 201) {
            SnackbarUtilities.success("Categoría creado correctamente!!");
            setTimeout(() => {
              navigate(`/Categorias`);

            }, 2000);
          }
          else if (category_id && response.status === 200) {
            SnackbarUtilities.success("Categoría Editado correctamente!!");
            setTimeout(() => {
              navigate(`/Categorias`);

            }, 2000);
          }
          else {
            SnackbarUtilities.error("Ha habido un error, intente más tarde");
          }
        } catch (error) {
          console.error("Error al crear la categoría:", error);
          SnackbarUtilities.error("Ha habido un error, intente más tarde");
        }

      } else {
        SnackbarUtilities.error("Error en algun validador del campo");
      }
    },
  });

  return (
    <div className="container">

      <div className="top-line">
        <div style={{ textAlign: "start" }}>
          <h3>{editMode ? 'Editar Categoría' : 'Crear Categoría'}</h3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
          <ButtonPermission variant="contained" type="submit" endIcon={<NoteAdd />}
            action="edit"
            resource="Trabajadores"
            sx={{
              color: '#c4af6c',
              backgroundColor: '#33383b',
              height: 40,
              marginBottom: 1,
              borderColor: 'transparent',
              '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
            }}>
            Guardar
          </ButtonPermission>
        </div>
        {
          (process.env.NODE_ENV == "development" && Object.keys(errors).length > 0) &&
          <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
            {JSON.stringify(errors)}
          </div>
        }

          <div className="middleline">
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { sm: '1fr' },
                  gap: 2,
                }}
              >
                <div >
                  <TextField
                    fullWidth
                    label="Título de la Categoría"
                    name="name"
                    margin="normal"
                    required
                    size="small"
                    sx={inputStyles}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </div>

                {languages.length &&
                  languages.map((lang, index) => (
                    <div style={{ gridColumn: 'span 1' }}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ArrowDownwardIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {/* <Typography>Accordion 1</Typography> */}
                          <GetLanguageFlags countryCode={lang.iso_code} />
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: { sm: '1fr' },
                            gap: 2,
                          }}>
                            <input
                              type="hidden"
                              name={`translations[${index}].language_id`}
                              value={lang.id}
                            />

                            <div >
                              <TextField
                                fullWidth
                                label="Título Categoría"
                                name={`translations[${index}].name`}
                                margin="normal"
                                required
                                size="small"
                                sx={inputStyles}
                                value={values.translations[index]?.name || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.translations?.[index]?.name && Boolean(errors.translations?.[index]?.name)}
                                helperText={touched.translations?.[index]?.name && errors.translations?.[index]?.name}
                              />
                            </div>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
              </Box>
            </div>
          </div>
        </form>

    </div>
  );
}
const inputStyles = {
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#c4af6c',
    },
  },
  '& .MuiInputAdornment-root': {
    color: '#333', // Color del adorno
  },
  width: "100%",
  marginBottom: 0,
  marginTop: 0
};

export default CreateCategoria;

import React, { useState, useEffect, useReducer } from "react";
import Paginacion from "../../components/Paginacion";
import {
  categoryCreate,
  categoryDelete,
  listUsers,
  listsCategories,
} from "../../services";
import { GetLanguageFlags, SnackbarUtilities } from "../../utilities";
import { useNavigate, Link } from "react-router-dom";
import ButtonPermission from "../../utilities/ButtonPermission";
import { Edit, Close, Check, Delete, NoteAdd, Search, CleaningServices } from '@mui/icons-material/';
import useCategorias from "../../hooks/useCategorias";
import { dynamicTableHeader } from "../../utilities/transformHeaders";
import { DataTable } from "../../components";
import useDialog from "../../hooks/useDialog";
import ConfirmationMessage from '../../utilities/confirmationMessage';

function Categorias() {
  const navigate = useNavigate();
  const { data, fetchData, loading, dataCustomNames } = useCategorias();
  const [dialogConfig, openDialog, closeDialog] = useDialog();

  const deleteItem = async (id) => {
    try {
      const response = await categoryDelete(id);
      // console.log(response);
      if (response.status == 200) {
        SnackbarUtilities.success("Artículo eliminado correctamente!");
        fetchData({ page: 1, limit: 1000 });
        // Aquí puedes agregar cualquier lógica adicional, como actualizar la lista de trabajadores
      } else {
        SnackbarUtilities.error("Error al eliminar el Artículo. Inténtalo de nuevo más tarde.");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const extraHeader = [
    {
      item: (name) => {
        // console.log(name)
        return (
          <div style={{ display: "flex", justifyContent: "left" }}>
            <Link to={`/Categorias/form/${name.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark" >
              <Edit />
            </Link>
            <button className="btn btn-outline-dark"
              onClick={() => openDialog({
                title: "Confirmación de eliminación",
                description: `¿Está seguro de que quiere eliminar el elemento con titulo:  ${name.nombre} ?`,
                onClickOk: () => deleteItem(name.id)
              })}
              style={{ marginLeft: "20px" }}>
              <Delete />
            </button>
          </div>
        )
      },
      position: -1,
      titleHeader: "Acciones",
      nameColumn: "",
      order: "disable",
    },
    {
      item: (name) => {
        return (
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            alignItems: "left",
            justifyContent: "left",
          }}>
            {name.translations?.map((lang) => (
              lang?.language?.iso_code == null
                ?
                <></>
                :
                <GetLanguageFlags
                  countryCode={lang?.language?.iso_code}
                  key={lang?.id}
                />
            ))}
          </div>
        )
      },
      position: 3,
      titleHeader: "Traducciones",
      nameColumn: ""
    }
  ];

  return (
    <div className="container">
      {/* <BreadCrumb /> */}

      <div className="topline">
        <div>
          <h3>Categorias</h3>
        </div>
        <div>
          <ButtonPermission onClick={() => { navigate("/Categorias/form") }} variant="contained" endIcon={<NoteAdd />}
            action="edit"
            resource="Categorias"
            sx={{
              color: '#c4af6c',
              backgroundColor: '#33383b',
              height: 40,
              marginBottom: 1,
              borderColor: 'transparent',
              '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
            }}>
            Crear
          </ButtonPermission>
        </div>
      </div>
      <DataTable
        data={data}
        headers={dynamicTableHeader({
          headerArrayData: dataCustomNames,
          showJustThis: ['ID', 'Nombre'],
          addNewCustomHeader: extraHeader
        })}
        chickable={false}
        isLoading={loading}
        fetcher={fetchData}
        mensaje={"No hay categorias disponibles"}
      />
      <ConfirmationMessage
        {...dialogConfig}
        onClose={closeDialog}
      />
    </div>
  );
}

export default Categorias;

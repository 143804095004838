import { useState } from 'react';
import {
    TextField,
    InputAdornment,
    Typography,
    Popover,
    Select,
    FormControl,
    MenuItem
} from '@mui/material';
const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
};
const HojaPrescriptorForm = ({ 
    handleChange, 
    handleClose, 
    anchorEl, 
    open, 
    ide, 
    vinculo, 
    formData,
    handleClick 
}) => {
    const [position, setPosition] = useState({ top: '401px !important', left: '1191px !important' });
    return (
        <>
            <TextField
                onChange={handleChange}
                // onClick={handleClick}
                id="porCola"
                label=""
                size="small"
                name="porCola"
                type='text'
                placeholder="00.00"
                value={formData?.colaboradores[0]?.porCola ?? ''}
                sx={{
                    ...inputStyles,
                    width: "10%",
                    "& .MuiInputAdornment-root": {
                        width: "unset",
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                    )
                }}
            />
            <Popover
                id={ide}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{ '& .MuiPaper-root': { width: '250px' } }}
            >
                <Typography sx={{ p: 2 }}>Vincular Colaborador con:</Typography>
                <FormControl fullWidth sx={{ padding: '25px' }}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleChange}
                        value={vinculo}
                        name="vinculo"
                        sx={{
                            textAlign: 'left',
                            '&.MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: '#c4af6c',
                                },
                            },
                        }}
                    >
                        {['NADIE', 'AAFF', 'VENDEDOR', 'CAPTADOR',].map(val => (
                            <MenuItem value={val}>{val}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Popover>
        </>
    )
}
export default HojaPrescriptorForm;
import { useEffect, useState } from 'react';
import { Drawer, Box, Typography, Checkbox,  IconButton, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../Tables/DataTable';
import { useAdminFincas } from '../../hooks';
import { FiltroContactosDrawer } from '../Filtros';
import { useFormValidation } from '../../utilities/formValidator';
import * as Yup from 'yup';
const CheckboxStyles = {
    '&.Mui-checked': {
        color: '#c4af6c',
    },
    '& .MuiSvgIcon-root': {
        color: '#33383b',
        fontSize: 28
    },
    '&:hover': {
        backgroundColor: 'rgba(196, 175, 108, 0.04)',
    },
};
const DrawerAaff = ({ open, setOpen, setAaff, aaff, toSave, sucursal }) => {
    const { loading, data, dataCustomNames ,fetchData } =  useAdminFincas();
    const [currentPage, setCurrentPage] = useState(0);
    const [filtros, setFiltros] = useState({tipo:'Administrador de Fincas', id_sucursal:sucursal?.id});
    const [initialValues, setInitialValues] = useState({
        id_sucursal: null,
        tipo: 'Administrador de Fincas',
        nif: '',
        buscador:''
    });
    const validationSchema = Yup.object({
        id_sucursal: Yup.number().nullable(),
        nif: Yup.string().notRequired().nullable(),
        buscador:Yup.string().notRequired().nullable(),
    });
    const headers = [ 
        {
            checkAaff: 'checkAaff',
            numeric: false,
            disabledPadding: false,
            label: '',
            overrideFunc: (data, row) => (
                <Checkbox
                    sx={CheckboxStyles}
                    onChange={(e) => addAaff(e, row)}
                    checked={aaff?.id === row?.id}
                />
            ),
        },
        {
            id:'id',
            numeric: false,
            disabledPadding: false,
            label: 'NIF /CIF',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.numero_identificacion}
                </Typography>
            )
        },
        {
            nombre:'nombre',
            numeric: false,
            disabledPadding: false,
            label: 'Nombre',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.nombre}
                </Typography>
            )
        },
        {
            apellidos:'apellidos',
            numeric: false,
            disabledPadding: false,
            label: 'Apellidos',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.apellidos}
                </Typography>
            )
        },
        {
            nombreComercial:'Nombre comercial',
            numeric: false,
            disabledPadding: false,
            label: 'R. Social',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.razon_social}
                </Typography>
            )
        },
        {
            telefono:'telefono',
            numeric: false,
            disabledPadding: false,
            label: 'Teléfono',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.telefono}
                </Typography>
            )
        },
        {
            email:'email',
            numeric: false,
            disabledPadding: false,
            label: 'Email',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.email}
                </Typography>
            )
        },
    ];
    const addAaff = (e, af) => {
        const {checked} = e.target; 
        setAaff(prevState => {
            const currentAaff = Array.isArray(prevState[toSave]) ? prevState[toSave] : [];
           
            if (checked) {
                // return { 
                //     ...prevState, 
                //     [`${toSave}`]: [...(prevState[`${toSave}`] || []), af] 
                // }; // para ñadir más
                return { 
                    ...prevState, 
                    [toSave]: [af] 
                };
            } else {
                return { 
                    ...prevState, 
                    [`${toSave}`]: (prevState[`${toSave}`] || []).filter(item => item !== af) 
                };
            }
           
        });
    };
    const el_check = [{
        item: (row)=> {
            // console.log(aaff);
            return (
                
                <Checkbox
                    sx={CheckboxStyles}
                    onChange={(e) => addAaff(e, row)}
                    checked={Array.isArray(aaff) && aaff[0]?.id === row?.id}
                />
            );
        },
        position: 1,

    }];
    useEffect(()=> {
        console.log(filtros);
        setFiltros(prevData =>({
            ...prevData,
            id_sucursal:sucursal?.id
        }));
    },[sucursal]);
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setValues, handleReset } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const { nif, buscador, tipo} = values;
            await fetchData({ offset: currentPage, limit: 1000,
                filtros: { 
                    id_sucursal: filtros.id_sucursal, 
                    tipo:tipo,
                    numero_identificacion:nif, 
                    buscador:buscador
                } 
            });
        },
    });
    return(
        <>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)} sx={{zIndex:2000}}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <IconButton size='small' onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <div className='container'>
                    <Box component="section" sx={{ p: 3 }}>
                        <Typography variant="h3" gutterBottom>
                            Administradores de fincas
                        </Typography>
                        <Typography variant="p" gutterBottom>Debes Seleccionar un Administrador de fincas</Typography>
                    </Box>
                </div>
                <FiltroContactosDrawer
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    filtros={filtros}
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFiltros={setFiltros}
                    handleSubmit={handleSubmit}
                />
                <div className='middleline'>
                    <DataTable 
                        headers={headers}
                        data={data}
                        filtros={filtros}
                        isLoading={loading}
                        fetcher={fetchData}
                        mensaje={"No hay Administradores disponibles"}
                    />
                </div>
            </Drawer>
        </>
    );
}

export default DrawerAaff;
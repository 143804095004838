import React, { useEffect, useState } from 'react';
import BarrasGrafico from './BarrasGrafico';
import { listpropiedadesPrecioBarras, listpropiedadesPrecioBarrasMultiColor } from '../../services/graficos.service';
import QuesoGrafico from './QuesoGrafico';
import * as Yup from 'yup';
import { useFormValidation } from '../../utilities/formValidator';
import { CleaningServices, Search } from '@mui/icons-material';
import useGraficos from "../../hooks/useGraficos";
import BarrasGraficoMultiColor from './BarrasGraficoMultiColor';
import { CircularProgress, TextField, Button, Box, MenuItem, Paper } from '@mui/material'; // Importa el componente de carga
import { getCurrentDate } from '../../utilities';
import Resumen from './ResumenGraficos';
import { useUser } from "../../hooks/UserContextLogin";

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};

export default function GraficosComponente() {
    const [dataBarras, setDataBarras] = useState(null);
    const [dataBarrasMultiColor, setDataBarrasMultiColor] = useState(null);
    const { data: datagraficoQueso, fetchData, loading } = useGraficos();
    const { user, setUser } = useUser();


    // const orderByMode = ["day", "month"];
    const comisionSelect = [{ name: "Importe Propiedades", db_name: "total" }, { name: "Comisión Bruta", db_name: "comisionTotal" }, { name: "Comisión Lae-Homes", db_name: "comisionLae" }, { name: "Comisión Neta Lae-Homes", db_name: "totalComisionNeta" }];
    const accionSelect = [{ name: "COMPRA - VENTA", db_name: "COMPRA_VENTA" }, { name: "ALQ OPCIÓN COMPRA", db_name: "ALQ_OPCION_COMPRA" }, { name: "ALQUILER", db_name: "ALQUILER" }, { name: "TRASPASO", db_name: "TRASPASO" }];

    const initialValues = {
        fechaInicio: '2024-01-01',
        fechaFinal: getCurrentDate() ?? '2024-07-01',
        byCustomDate: 'month',
        tipoComision: 'total',
        tipoAccion: 'COMPRA_VENTA',
        id_sucursal: user?.rol?.permisos?.customViews['Graficos']?.includes('View Oficinas') ? user?.usuarioDatos?.sucursal_id : null
    };

    const validationSchema = Yup.object().shape({
        fechaInicio: Yup.date().required("Por favor, seleccione una fecha"),
        fechaFinal: Yup.date().required("Por favor, seleccione una fecha"),
        byCustomDate: Yup.string().notRequired().nullable(),
        tipoComision: Yup.string().notRequired().nullable(),
        tipoAccion: Yup.string().notRequired().nullable(),
        id_sucursal: Yup.string().notRequired().nullable(),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setValues, handleReset } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (values) {
                fetchData({ offset: 1, limit: 1000, filtros: values });
                if (values.id_sucursal && user?.rol?.permisos?.customViews['Graficos']?.includes('View Oficinas')) {
                    await fetchDataBarra(values);
                } else {
                    await fetchDataBarraColores(values);

                }
            }
        },
    });
    const handleResetCustom = () => {
        handleReset()
    }

    const fetchDataBarra = async (filtros) => {

        const data = await listpropiedadesPrecioBarras({ filtros: filtros });
        if (!data.chartData.length == 0 && !data.series.length == 0) {
            setDataBarras(data); // Ajusta esto según la estructura de los datos retornados
        }
    };
    const fetchDataBarraColores = async (filtros) => {
        const dataMultiColor = await listpropiedadesPrecioBarrasMultiColor({ filtros: filtros });
        setDataBarrasMultiColor(dataMultiColor); // Ajusta esto según la estructura de los datos retornados

    }
    useEffect(() => {

        if (values.id_sucursal && user?.rol?.permisos?.customViews['Graficos']?.includes('View Oficinas')) {
            fetchDataBarra(values);
        } else {
            fetchDataBarraColores(initialValues);
        }
        fetchData({ offset: 1, limit: 1000, filtros: values });
    }, []);

    function formatearDatos(precios) {
        // console.log(precios);

        const data = Object.entries(precios.groupedPrices).map(([key, value]) => ({
            id: key,
            value: Math.round(value.porcentaje * 100) / 100,
            label: key + ` ${value.totalSucursal.toLocaleString('es-ES')} €`,
            total: Math.round(value.porcentaje * 100) / 100 + " %",
            color: value.color,
        }));
        return data;
    }

    return (
        <div className="container">
            <div className="topline">
                {/* <div>
                    <h3>Gráficos</h3>
                </div> */}
            </div>
            {/* <BreadCrumb /> */}
            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                        gap: 2,
                    }}
                >
                    <div>
                        <TextField
                            sx={inputStyles}
                            label="Desde"
                            name="fechaInicio"
                            type="date"
                            size="small"
                            margin="normal"
                            value={values.fechaInicio}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.fechaInicio && Boolean(errors.fechaInicio)}
                            helperText={touched.fechaInicio && errors.fechaInicio}
                        />
                    </div>
                    <div>
                        <TextField
                            sx={inputStyles}
                            label="Hasta"
                            name="fechaFinal"
                            type="date"
                            size="small"
                            margin="normal"
                            value={values.fechaFinal}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.fechaFinal && Boolean(errors.fechaFinal)}
                            helperText={touched.fechaFinal && errors.fechaFinal}
                        />
                    </div>
                    <div>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Tipo Comisión"
                            size="small"
                            name="tipoComision"
                            margin="normal"
                            sx={inputStyles}
                            value={values.tipoComision}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.tipoComision && Boolean(errors.tipoComision)}
                            helperText={touched.tipoComision && errors.tipoComision}
                        >
                            {comisionSelect ? comisionSelect.map((option) => (
                                <MenuItem key={option.db_name} value={option.db_name}>
                                    {option.name}
                                </MenuItem>
                            )) : null}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Tipo Acción"
                            size="small"
                            name="tipoAccion"
                            margin="normal"
                            sx={inputStyles}
                            value={values.tipoAccion}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.tipoAccion && Boolean(errors.tipoAccion)}
                            helperText={touched.tipoAccion && errors.tipoAccion}
                        >
                            {accionSelect ? accionSelect.map((option) => (
                                <MenuItem key={option.db_name} value={option.db_name}>
                                    {option.name}
                                </MenuItem>
                            )) : null}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            id="outlined-select-currency2"
                            select
                            label="Ordenar Por"
                            size="small"
                            name="byCustomDate"
                            margin="normal"
                            sx={inputStyles}
                            value={values.byCustomDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.byCustomDate && Boolean(errors.byCustomDate)}
                            helperText={touched.byCustomDate && errors.byCustomDate}
                        >
                            {[{ name: "Año", key: "year" }, { name: "Mes", key: "month" }].map((option, index) => (
                                <MenuItem key={index} value={option.key}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div style={{ display: "flex", flex: 0.5, justifyContent: "start", gap: '10px' }}>

                        <Button
                            type="submit"
                            variant="outlined"
                            sx={{
                                color: '#c4af6c',
                                backgroundColor: '#33383b',
                                height: 40,
                                borderColor: 'transparent',
                                '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                            }}
                        >
                            <Search style={{ fontSize: "1.3rem", '&:hover': { color: '#33383b' } }} />
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                color: '#33383b',
                                backgroundColor: '#c4af6c',
                                borderColor: 'transparent',
                                '&:hover': { color: '#c4af6c', backgroundColor: '#fff', borderColor: '#33383b', }
                            }}
                            onClick={() => {
                                fetchData({ offset: 1, limit: 1000, filtros: initialValues });
                                if (values.id_sucursal && user?.rol?.permisos?.customViews['Graficos']?.includes('View Oficinas')) {
                                    fetchDataBarra(initialValues)
                                } else {
                                    fetchDataBarraColores(initialValues);
                                }
                                // console.log(values);
                                handleResetCustom();
                            }}                        >
                            <CleaningServices style={{ color: '#33383b', fontSize: "1.3rem", '&:hover': { color: '#c4af6c' } }} />
                        </Button>
                    </div>
                </Box>

            </form>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div style={{ display: "flex", flexDirection: "column", gap: "2em", marginTop: "2em" }}>
                    <Paper elevation={3} sx={{ py: 5 }}>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                            {datagraficoQueso && <div><QuesoGrafico preciosGraficos={formatearDatos(datagraficoQueso)} total={datagraficoQueso.totalPrecios} /></div>}
                            {dataBarrasMultiColor && <div><BarrasGraficoMultiColor series={dataBarrasMultiColor.series} xLabels={dataBarrasMultiColor.xLabels} /></div>}
                            {dataBarras && <div><BarrasGrafico data={dataBarras.chartData} seriesColumn={dataBarras.series} /></div>}
                            {!datagraficoQueso && !dataBarrasMultiColor && !dataBarras && <h4>No hay datos</h4>}
                        </div>
                    </Paper>

                    {!values.id_sucursal && dataBarrasMultiColor && <Resumen comisionNombre={comisionSelect.find(item => item.db_name === values.tipoComision)} dataQueso={datagraficoQueso && datagraficoQueso} dataBarras={dataBarrasMultiColor && dataBarrasMultiColor}></Resumen>}

                </div>

            )}


        </div>
    );
}

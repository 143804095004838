import React, { useState, useEffect, useContext } from "react";
import { deleteSucursal, publicar, despublicar } from "../../services";
import { SnackbarUtilities } from "../../utilities";
import "./suscursales.css"
import EditIcon from '@mui/icons-material/Edit';
import { DataTable } from "../../components";
import { dynamicTableHeader } from "../../utilities/transformHeaders";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSucursales } from "../../hooks";
import { UserContext } from '../../hooks/UserContext'; // Importa el contexto
import { Link, useNavigate } from "react-router-dom";
import ButtonPermission from "../../utilities/ButtonPermission";
import { useUser } from "../../hooks/UserContextLogin";
import ConfirmationMessage from "../../utilities/confirmationMessage";
import useDialog from "../../hooks/useDialog";
import { verificarPermisosAcciones } from "../../utilities/permissionHelper";

const Sucursales = () => {
    const { setLoading, data, fetchData, dataCustomNames } = useSucursales();

    const [vista, setVista] = useState(true);

    const { usuario } = useContext(UserContext); // Accede al contexto del usuario
    const { user, setUser } = useUser();
    const [dialogConfig, openDialog, closeDialog] = useDialog();
    const [hover, setHover] = useState(true);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/Oficinas/form",);
    }
    const handleClickGoTo = (id) => {
        navigate(`/Oficinas/form/${id}`, "_blank");
    }
    const handleClickGoToFicha = (id) => {
        navigate(`/Oficinas/ficha/${id}`, "_top");
    }
    const handleChange = () => {
        setVista(!vista);
    }
    const handleDespublicarSucursal = async (id) => {
        const res = await despublicar(id);
        if (res.status == 201) {
            SnackbarUtilities.info("Oficina despublicada");
            setTimeout(() => {
                navigate('/Oficinas');
                fetchData({ offset: 1, limit: 1000 });
            }, 1000);
        }
    }
    const handlePublicarSucursal = async (id) => {
        const res = await publicar(id);
        if (res == 201) {
            SnackbarUtilities.success("Oficina publicada !");
            setTimeout(() => {
                navigate('/Oficinas');
                fetchData({ offset: 1, limit: 1000 });
            }, 1000);
        }
    }
    const handleDelete = async (id) => {
        const response = await deleteSucursal(id);
        if (response.status === 201) {
            SnackbarUtilities.success("Sucursal actualizada correctamente");
            setTimeout(() => {
                fetchData({ offset: 1, limit: 1000 });
            }, 1000);
        }
    }

    const iconColumn = [];
    if (!user?.rol?.permisos?.tablas['Oficinas']?.includes('Estados')) {
        iconColumn.push(
            {
                item: (name) => {
                    return (
                        <div
                            style={{ marginLeft: "15px", cursor: "pointer" }}
                            onMouseEnter={() => {
                                const element = document.getElementById(`icon-${name.id}`);
                                if (element) {
                                    element.style.color = "#000"; // Cambia a rojo
                                }
                            }}
                            onMouseLeave={() => {
                                const element = document.getElementById(`icon-${name.id}`);
                                if (element) {
                                    element.style.color = name?.estado ? "green" : "red"; // Cambia al color original
                                }
                            }}
                            onClick={() => openDialog({
                                title: "Confirmación",
                                description: `¿Está seguro de que quiere ${name?.estado ? 'despublicar' : 'publicar'} ${name.nombre_sucursal} ?`,
                                onClickOk: () => { name?.estado ? handleDespublicarSucursal(name.id) : handlePublicarSucursal(name.id) }
                            })}
                        >
                            {name?.estado ? (
                                <CheckIcon id={`icon-${name.id}`} sx={{ color: "green", fontSize: 38 }} />
                            ) : (
                                <CloseIcon id={`icon-${name.id}`} sx={{ color: "red", fontSize: 38 }} />
                            )}
                        </div>
                    );
                },
                position: data.rows.length,
                titleHeader: "Publicado",
                nameColumn: "estado"
            }
        );
    }
    if (!user?.rol?.permisos?.tablas['Oficinas']?.includes('Acciones')) {
        // console.log("ENTRÓ");
        iconColumn.push({
            item: (name) => {
                return (
                    <div style={{ display: "flex", justifyContent: "left" }}>
                        {verificarPermisosAcciones(['edit', 'delete', 'all'], user, 'Oficinas', name.numsucursal, name, handleDelete, "/Oficinas/form/")}
                    </div>
                )
            },
            position: 9,
            titleHeader: "Acciones",
            nameColumn: "",
            order: "disable",
        });
    }
    return (
        <div className="container">
            {/* <BreadCrumb /> */}
            <div className="top-line">
                <div style={{ textAlign: "start" }}>
                    <h3>Oficinas</h3>
                </div>
            </div>
            <div style={{ display: "flex", flex: 0.5, justifyContent: "end", gap: 8, marginBottom: 10 }}>
                {
                    <ButtonPermission
                        variant="outlined"
                        sx={{
                            color: '#c4af6c',
                            backgroundColor: '#33383b',
                            height: 40,
                            borderColor: 'transparent',
                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}
                        action="create"
                        resource="Oficinas" onClick={handleClick}>
                        Crear
                    </ButtonPermission>
                }
            </div>
            <div className="middleline">
                <DataTable
                    onClickRow={(row) => handleClickGoToFicha(row.id)}
                    data={data}
                    fetcher={fetchData}
                    headers={dynamicTableHeader({ headerArrayData: dataCustomNames, showJustThis: ["Provincia", "Municipio", "Dirección", "Teléfono", "CP", "Email"], addNewCustomHeader: iconColumn, userPermision: user, permissionResourceName: "Oficinas" })}
                    orderTable={"ASC"}
                    orderTableBy={"Provincia"}
                />
            </div>
            <ConfirmationMessage
                {...dialogConfig}
                onClose={closeDialog}
            />
        </div>

    );
}

export default Sucursales;
import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

const BarrasGrafico = ({ data, seriesColumn }) => {
    // Configuración del gráfico
    const chartSetting = {
        yAxis: [{}], // Configuración opcional de Y-Axis
        width: data.length > 40 ? 8000 : 580, // Ajusta el ancho en función de los datos
        height: 400, // Altura razonable
        sx: {
            [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-20px, 0)', // Ajusta la posición de las etiquetas
            },
        },
    };

    // Añadir colores a las series
    const formattedSeries = seriesColumn.map((serie) => ({
        ...serie,
        style: { fill: serie.color }, // Usa el color definido en cada serie
    }));

    return (
        <div style={{ overflowX: 'auto', maxWidth: 600 }}>
            <BarChart
                series={formattedSeries}
                xAxis={[{ dataKey: 'fecha', scaleType: 'band', categoryGapRatio: 0.1 }]} // Configuración del eje X
                {...chartSetting}
                margin={{ top: 10, bottom: 30, left: 100, right: 10 }}
                dataset={data}
                slotProps={{
                    legend: {
                        direction: 'column',
                        position: { vertical: 'top', horizontal: 'middle' },
                        padding: 0,
                        labelStyle: {},
                        hidden: false, // Mostrar leyenda
                    },
                }}
            />
        </div>
    );
};

export default BarrasGrafico;

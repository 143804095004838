import { useState, useContext, useEffect } from "react";
import { listCategorias, listContactos, listsCategories } from "../services";

function useCategorias() {

    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta: {},
        rows: [],
    });
    const [loading, setLoading] = useState(true);
    const [dataCustomNames, setDataCustomNames] = useState(null);
    const customNames = [
        'ID', 'Nombre', 'Traducciones'];

    const formatData = (data) => {
        // console.log(data);
        return data.map((row) => (
            // console.log(row),            
            {
                id: row.id,
                nombre: row.name,
                traducciones: row.tipo,
                translations: row.translations ?? null,
                // apellidos: row.reg_generales?.apellidos,
                // nombre_comercial: row.reg_generales?.nombre_comercial,
                // // razon_social: row.reg_generales?.razon_social,
                // telefono: row.reg_generales?.telefono1,
                // email: row.reg_generales?.email1,
                // perfil: row.contacto_perfiles,
                // numero_identificacion: row?.numero_identificacion,

            }));
    };

    const customNameDataTable = (formattedData) => {
        // Obtener las claves del primer objeto para asignar key y label
        const keys = Object.keys(formattedData[0] || {});

        // Retornar un array de objetos con key y label
        return keys.map((key, index) => ({
            key,  // La clave original
            label: customNames[index] || key  // El nombre personalizado o la clave si no hay un nombre en customNames
        }));
    }

    const fetchData = async ({ offset, limit, filtros, orderBy, order }) => {
        setLimite(limit);
        let payload;
        if (orderBy && order) {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit), orderBy: `${orderBy},${order}` },
                filtros
            };
        } else {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit) },
                filtros
            };
        }
        try {
            setLoading(true);
            let response = await listCategorias(payload);
            const { meta, data } = response;
            // console.log(data);

            const formattedData = formatData(data);  // Formatear los datos
            const customData = customNameDataTable(formattedData);  // Personalizar los nombres
            // console.log(customData);

            setData({
                meta,
                rows: formatData(data),
            });
            setDataCustomNames(customData);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return {
        data,
        loading,
        fetchData,
        limite,
        dataCustomNames
    };
};

export default useCategorias;

import React, { useEffect, useState, useMemo } from "react";
import * as Yup from 'yup';
import { useFormValidation } from "../../utilities/formValidator";
import { formatDate, formatDateGuiones, SnackbarUtilities } from "../../utilities";
import { ContactPhone, Add, NoteAdd, Remove } from '@mui/icons-material';
import {
    Button, TextField, Box, MenuItem, InputAdornment,
} from "@mui/material";
import ButtonPermission from "../../utilities/ButtonPermission";
import Flag from 'react-world-flags'; // Para mostrar las banderas
// import { allCountries } from 'country-region-data'; // Importa todos los países
import { createCliente, createContacto, getCliente, getContacto, listSucursales, listTrabajadores, saveSucursal, sucursalDetails, updateCliente, updateContacto, updateSucursal, updateTrabajador } from "../../services";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import provinces from "@doncicuto/es-provinces";
import { createAAFF, getAAFF, updateAAFF } from "../../services/aaff.service";
import municipalitiesWithProvinces from "../../utilities/municipalitiesWithProvinces.json"; // Ruta al JSON
import { CircularProgress } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';

export default function FormSucursal() {

    //Multi-Select
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const theme = useTheme();

    const { id: sucursal_id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const [sucursalMap, setSucursalMap] = useState(null);
    const [provinceSelected, setProvinceSelected] = useState(null);
    const [municipios, setMunicipios] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [coodinador, setCoodinador] = useState(null);
    const [responsable, setResponsable] = useState(null);
    const [personName, setPersonName] = React.useState([]);


    const initialValues = {
        nombre_sucursal: '',
        provincia: '',
        municipio: '',
        direccion: '',
        cp: '',
        telefono: '',
        email: '',
        tipo: '',
        id_ext: '',
        id_coor: '',
        id_respo: [],
        estado: 1,
        color_grafico: ''
    };

    const validationSchema = Yup.object().shape({
        nombre_sucursal: Yup.string().min(5, 'El nombre de la Oficina debe de tener más de 4 letras').required('Campo obligatorio'),
        provincia: Yup.string().required('Campo obligatorio'),
        municipio: Yup.string().required('Campo obligatorio'),
        direccion: Yup.string().nullable(),
        cp: Yup.string().nullable(),
        telefono: Yup.string().nullable(),
        email: Yup.string().email('Formato de correo no válido').matches(/@laehomes\.com$/, 'Debe de ser un Email Corporativo de laehomes.com', { excludeEmptyString: true }).required('Campo obligatorio'),
        tipo: Yup.string().nullable(),
        id_ext: Yup.number().nullable().transform((value, originalValue) => originalValue === "" ? null : value),
        id_coor: Yup.number().nullable().transform((value, originalValue) => originalValue === "" ? null : value),
        id_respo: Yup.array().nullable(),
        estado: Yup.number(),
        color_grafico: Yup.string(),

    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleReset, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        nullConverter: true,
        onSubmit: async (values, modifiedFields) => {
            if (values) {
                console.log(values);
                const response = editMode ? await updateSucursal(sucursal_id, values) : await saveSucursal(values);

                if (response.status == 201) {
                    if (sucursal_id) {
                        SnackbarUtilities.success("Oficina Editada correctamente!!");
                    } else {
                        SnackbarUtilities.success("Oficina creada correctamente!!");
                    }
                    setTimeout(() => {
                        navigate(`/Oficinas`);
                    }, 2000);
                } else {
                    SnackbarUtilities.error("ha habido un error, intente más tarde");
                }

            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    const fetchSucursales = async () => {
        const response = await sucursalDetails(sucursal_id);
        // console.log(response);
        const responsables_id = response.responsables
            ? response.responsables.map(dep => dep.id)
            : [];
        setValues({
            id_ext: response.id_ext,
            id_respo: responsables_id,
            id_coor: response.id_coor,
            nombre_sucursal: response.nombre_sucursal,
            provincia: response.provincia,
            municipio: response.municipio,
            direccion: response.direccion,
            telefono: response.telefono,
            cp: response.cp,
            email: response.email,
            tipo: response.tipo,
            estado: response.estado,
            color_grafico: response.color_grafico,
        });
        const selectedProvince = provinces.filter((p) => p.name == response?.provincia);
        setProvinceSelected(selectedProvince[0]?.code);
        setEditMode(true);
    };

    const fetchCoordinador = async () => {
        const response = await listTrabajadores({ offset: 1, limit: 1000, filtros: { user_rol: [2], user_rol_filter: true } });
        // console.log(response.data);
        setCoodinador(response.data || []);
    }
    const fetchResponsable = async () => {
        const response = await listTrabajadores({ offset: 1, limit: 1000, filtros: { user_rol: [21], user_rol_filter: true } });
        // console.log(response.data);
        setResponsable(response.data || []);
    }

    useEffect(() => {
        fetchCoordinador();
        fetchResponsable();

        if (sucursal_id) {
            fetchSucursales();
        }

    }, [])

    const handleCustomChange = (event) => {
        const { name, value, type, checked } = event.target;
        // console.log(name, value, type, checked);

        if (name == "provincia") {
            const selectedProvince = provinces.filter((p) => p.name == value);
            if (selectedProvince) {
                // console.log(selectedProvince[0].code);
                setProvinceSelected(selectedProvince[0]?.code);
                handleChange(event)
            }
        }
        if (name == "id_respo") {
            const {
                target: { value },
            } = event;
            setCustomFieldValue('id_respo', value);
            setPersonName(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value
            );
        }
        handleChange(event);

    }
    useEffect(() => {
        if (provinceSelected) {
            setLoading(true);

            // Filtrar municipios basados en la provincia seleccionada
            const filteredMunicipalities = municipalitiesWithProvinces.filter(
                (m) => m.provCode == provinceSelected
            );

            setMunicipios(filteredMunicipalities);
            setLoading(false);
        } else {
            setMunicipios([]);
        }
    }, [provinceSelected]);

    return (
        <div className="container">
            {/* <BreadCrumb /> */}
            <div className="top-line">
                <div style={{ textAlign: "start" }}>
                    <h3>{editMode ? 'Editar Oficinas' : 'Crear Oficinas'}</h3>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
                    <ButtonPermission variant="contained" type="submit" endIcon={<NoteAdd />}
                        action="edit"
                        resource="Trabajadores"
                        sx={{
                            color: '#c4af6c',
                            backgroundColor: '#33383b',
                            height: 40,
                            marginBottom: 1,
                            borderColor: 'transparent',
                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}>
                        Guardar
                    </ButtonPermission>
                </div>
                {
                    (process.env.NODE_ENV == "development" && Object.keys(errors).length > 0) &&
                    <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                        {JSON.stringify(errors)}
                    </div>
                }
                <div className="middleline">
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                                gap: 2,
                            }}
                        >
                            <div >
                                <TextField
                                    fullWidth
                                    label="Nombre Oficina"
                                    name="nombre_sucursal"
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.nombre_sucursal}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.nombre_sucursal && Boolean(errors.nombre_sucursal)}
                                    helperText={touched.nombre_sucursal && errors.nombre_sucursal}
                                />
                            </div>
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    label="Provincia"
                                    name="provincia"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.provincia}
                                    size="small"
                                    onChange={handleCustomChange}
                                    onBlur={handleBlur}
                                    error={touched.provincia && Boolean(errors.provincia)}
                                    helperText={touched.provincia && errors.provincia}
                                >
                                    {provinces.map((item, index) => (
                                        <MenuItem key={index} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    disabled={(sucursal_id || provinceSelected) ? false : true}
                                    label="Municipio"
                                    name="municipio"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.municipio}
                                    size="small"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.municipio && Boolean(errors.municipio)}
                                    helperText={touched.municipio && errors.municipio}
                                >
                                    {loading ? (
                                        <MenuItem disabled>
                                            <CircularProgress size={24} />
                                        </MenuItem>
                                    ) : (
                                        municipios.map((item, index) => (
                                            <MenuItem key={index} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))
                                    )}
                                </TextField>
                            </div>
                            <div >
                                <TextField
                                    fullWidth
                                    label="Dirección"
                                    name="direccion"
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.direccion}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.direccion && Boolean(errors.direccion)}
                                    helperText={touched.direccion && errors.direccion}
                                />
                            </div>


                            <div >
                                <TextField
                                    fullWidth
                                    label="Código Postal"
                                    name="cp"
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.cp}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.cp && Boolean(errors.cp)}
                                    helperText={touched.cp && errors.cp}
                                />
                            </div>

                            <div style={{ display: "flex", gap: 6, gridColumn: 'span 1' }} >
                                <TextField
                                    id="input-with-icon-textfield"
                                    name="telefono"
                                    sx={inputStyles}
                                    size="small"
                                    label="Teléfono"
                                    value={values.telefono}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ContactPhone />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.telefono && Boolean(errors.telefono)}
                                    helperText={touched.telefono && errors.telefono}
                                />
                            </div>


                            <div>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </div>
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    label="Oficina"
                                    name="tipo"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.tipo}
                                    size="small"
                                    onChange={handleCustomChange}
                                    onBlur={handleBlur}
                                    error={touched.tipo && Boolean(errors.tipo)}
                                    helperText={touched.tipo && errors.tipo}
                                >
                                    {["central", "businessCenter", "oficinaCalle", "Colaborador"].map((value, index) => (
                                        <MenuItem key={index} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))}

                                </TextField>

                            </div>
                            <div >
                                <TextField
                                    fullWidth
                                    label="Id Externo"
                                    name="id_ext"
                                    margin="normal"
                                    size="small"
                                    type="number"
                                    sx={inputStyles}
                                    value={values.id_ext}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.id_ext && Boolean(errors.id_ext)}
                                    helperText={touched.id_ext && errors.id_ext}
                                />
                            </div>

                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    label="Asignar Coordinador"
                                    name="id_coor"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.id_coor}
                                    size="small"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.id_coor && Boolean(errors.id_coor)}
                                    helperText={touched.id_coor && errors.id_coor}
                                >
                                    {coodinador ? coodinador.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.nombre + " " + option.apellidos + " - " + option.cargo}
                                        </MenuItem>
                                    )) : <MenuItem disabled>No hay Coordinadores disponibles</MenuItem>}

                                </TextField>

                            </div>
                            <div>
                                <FormControl sx={inputStyles} size="small">
                                    <InputLabel id="demo-multiple-chip-label3">Responsable de Oficina</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label3"
                                        id="demo-multiple-chip-label3"
                                        multiple
                                        name="id_respo"
                                        sx={inputStyles}
                                        margin="normal"
                                        value={values.id_respo}
                                        onChange={handleCustomChange}
                                        input={<OutlinedInput id="select-multiple-chip-label" label="Departamentos" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={responsable && responsable.find(depto => depto.id === value)?.nombre + " " + responsable.find(depto => depto.id === value)?.apellidos || 'Nombre no encontrado'} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                        onBlur={handleBlur}
                                        error={touched.id_respo && Boolean(errors.id_respo)}
                                        helperText={touched.id_respo && errors.id_respo}
                                    >
                                        {responsable ? responsable.map((depto) => (
                                            <MenuItem
                                                key={depto.id}
                                                value={depto.id}
                                                style={getStyles(depto.id, values.id_respo, theme)}
                                            >
                                                {depto.nombre + " " + depto.apellidos}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                    {touched.id_respo && errors.id_respo && (
                                        <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                                            {errors.id_respo}
                                        </div>
                                    )}
                                </FormControl>
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    label="Color Oficina Gráfico"
                                    name="color_grafico"
                                    type="color"
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.color_grafico}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.color_grafico && Boolean(errors.color_grafico)}
                                    helperText={touched.color_grafico && errors.color_grafico}
                                />
                            </div>

                        </Box>
                    </div>
                </div>
            </form>
        </div>
    )
}
const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};
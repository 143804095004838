import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Divider, Collapse
} from '@mui/material';
import { thousandFormatNumber } from '../../utilities/formDataHelper';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Resumen = ({ dataQueso, dataBarras, comisionNombre }) => {
    const [expandedRow, setExpandedRow] = useState(null);
    const [arrow, setArrow] = useState({ name: "oficina", order: false });
    const [data, setData] = useState(ordenarSucursales(Object.entries(dataQueso.groupedPrices), arrow.order, arrow.name));
    // console.log(data);


    function ordenarSucursales(data, ascendente = null, propiedad = 'oficina') {
        // console.log(ascendente, propiedad);

        return data.sort((a, b) => {
            let valorA, valorB;

            // Determinar el valor basado en la propiedad
            if (propiedad === 'oficina') {
                valorA = a[0].toUpperCase();
                valorB = b[0].toUpperCase();
            } else if (propiedad === 'total') {
                valorA = a[1].totalSucursal;
                valorB = b[1].totalSucursal;
            } else if (propiedad === 'porcentaje') {
                valorA = a[1].porcentaje;
                valorB = b[1].porcentaje;
            } else {
                throw new Error(`Propiedad desconocida: ${propiedad}`);
            }

            // Comparar valores según el orden
            if (ascendente) {
                // Orden ascendente (A-Z)
                if (valorA < valorB) return -1;
                if (valorA > valorB) return 1;
                return 0;
            } else {
                // Orden descendente (Z-A)
                if (valorA < valorB) return 1;
                if (valorA > valorB) return -1;
                return 0;
            }
        });
    }


    //Ordernar alfabeticamente
    // dataQueso.groupedPrices = Object.keys(dataQueso.groupedPrices)
    //     .sort((a, b) => a.localeCompare(b))
    //     .reduce((acc, key) => {
    //         acc[key] = dataQueso.groupedPrices[key]; // Reconstruye el objeto en el orden deseado
    //         return acc;
    //     }, {});

    const handleRowClick = (sucursal) => {
        setExpandedRow(expandedRow === sucursal ? null : sucursal);
    };
    // console.log(Object.entries(dataQueso.groupedPrices));
    useEffect(() => {
        // Ordenar directamente los datos originales
        const datosOrdenados = ordenarSucursales(
            Object.entries(dataQueso.groupedPrices), // Siempre usa los datos originales
            arrow.order,
            arrow.name
        );
    
        // Actualizar el estado con los datos ordenados
        setData(datosOrdenados);
    
        // console.log('Arrow actualizado:', arrow);
        // console.log('Datos ordenados:', datosOrdenados);
    }, [arrow, dataQueso.groupedPrices]); // Asegúrate de incluir las dependencias relevantes
    
    const sortedDates = dataBarras?.xLabels?.sort((a, b) => new Date(a) - new Date(b));

    if (dataBarras.xLabels.length !== 0 && dataQueso.totalPrecios !== 0) {
        return (
            <Paper elevation={3} sx={{ mb: "2em" }}>
                <Paper sx={{ paddingBottom: 2, m: 0, textAlign: "center" }} elevation={0}>
                    <Typography variant="h5" sx={{ marginTop: 3, fontWeight: "bold", color: '#33383b', fontSize: 26 }}>
                        {comisionNombre.name} {thousandFormatNumber(dataQueso.totalPrecios, "€")}
                    </Typography>
                </Paper>
                <Divider variant="middle" sx={{ borderColor: '#c4af6c', borderWidth: '1.5px', marginBottom: '20px' }} />
                <Paper sx={{ p: 2, mb: 2, backgroundColor: '#ffffff' }} elevation={0}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="12%">Color</TableCell>

                                    <TableCell width="15%">Oficina {(arrow.name == "oficina" && arrow.order == true) ? <ArrowUpwardIcon sx={{ fontSize: "1.2em", cursor: "pointer" }} onClick={() => setArrow({ name: "oficina", order: !arrow.order })} /> : <ArrowDownwardIcon sx={{ fontSize: "1.2em", cursor: "pointer" }} onClick={() => setArrow({ name: "oficina", order: !arrow.order })} />}</TableCell>

                                    <TableCell width="16%" align='right'>Total Oficina {(arrow.name == "total" && arrow.order == true) ? <ArrowUpwardIcon sx={{ fontSize: "1.2em", cursor: "pointer" }} onClick={() => setArrow({ name: "total", order: !arrow.order })} /> : <ArrowDownwardIcon sx={{ fontSize: "1.2em", cursor: "pointer" }} onClick={() => setArrow({ name: "total", order: !arrow.order })} />}</TableCell>

                                    <TableCell width="20%" align='right'>Porcentaje {(arrow.name == "porcentaje" && arrow.order == true) ? <ArrowUpwardIcon sx={{ fontSize: "1.2em", cursor: "pointer" }} onClick={() => setArrow({ name: "porcentaje", order: !arrow.order })} /> : <ArrowDownwardIcon sx={{ fontSize: "1.2em", cursor: "pointer" }} onClick={() => setArrow({ name: "porcentaje", order: !arrow.order })} />}</TableCell>
                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map(([sucursal, info]) => {
                                    const serie = dataBarras.series.find(serie => serie.label === sucursal);
                                    const isExpanded = expandedRow === sucursal;

                                    return (
                                        <React.Fragment key={sucursal}>
                                            <TableRow
                                                onClick={() => handleRowClick(sucursal)}
                                                sx={{
                                                    cursor: 'pointer',
                                                    backgroundColor: isExpanded ? '#f0f0f0' : 'inherit',
                                                    height: "4em"
                                                }}
                                            >
                                                <TableCell>
                                                    <Box
                                                        sx={{
                                                            width: 20,
                                                            height: 20,
                                                            backgroundColor: serie?.color || '#000'
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>{sucursal}</TableCell>
                                                <TableCell align='right'>{thousandFormatNumber(info.totalSucursal, "€")}</TableCell>
                                                <TableCell align='right'>{info.porcentaje.toFixed(2)}%</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                                        <Box margin={1} sx={{ mx: 53 }}>
                                                            {/* <Typography variant="subtitle1" gutterBottom>
                                                                Detalles de {sucursal}
                                                            </Typography> */}
                                                            <Table size="small" sx={{ marginBottom: 2.5 }}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="center" width="5%"  >Fecha</TableCell>
                                                                        <TableCell align="right" width="5%" sx={{ paddingRight: 7 }} >Valor</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {sortedDates.map((label, index) => (
                                                                        // console.log(index),
                                                                        // console.log(sortedDates.length),

                                                                        <TableRow key={index}>
                                                                            <TableCell align="center" sx={{ borderBottom: index == sortedDates.length - 1 && "none" }}>{label}</TableCell>
                                                                            <TableCell align="right" sx={{ borderBottom: index == sortedDates.length - 1 && "none", paddingRight: 7 }}>
                                                                                {thousandFormatNumber(serie?.data[index] || 0, "€")}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Paper>
        );
    } else {
        return <></>;
    }
};

export default Resumen;

import { useEffect, useState } from "react";
import { Tabs, Tab, Col } from "react-bootstrap";
import { GetLanguageFlags } from "../../utilities";
import {
    Box,
    Paper,
    InputAdornment,
    Divider,
    TextField
} from '@mui/material';

import { SwitchCustom  } from "./Tipos";

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
};
const paperStyles = { 
    display: 'flex', 
    flexWrap: 'wrap', 
    gap: '10px', 
    marginBottom:'10px'
};
const PropiedadForm = ({ idiomas, property }) => {

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (property && Object.keys(property).length > 0) {
            setLoading(false);
        }
    }, [property]);
    
    const handleChange = (evt) => {
        // console.log(evt.target.name);
        // console.log(evt.target.checked)
    };
    
    return (
        <>
            <Box sx={{ width: "100%", minHeight: "100vh" }}>
                <Box >   
                {!loading ? 
                    <>  
                        <h3 style={{textAlign:'left'}}>Descripciones</h3>
                        <Paper elevation={0} sx={paperStyles}>
                            <Tabs style={{marginLeft:'15px', width:'97.3%', textAlign: 'left'}} defaultActiveKey={0} id="dynamic-tabs">
                                {idiomas.map((idioma, i) => {
                                    return (
                                        <Tab 
                                            eventKey={i} 
                                            title={
                                                <Col lg={12}>
                                                    <GetLanguageFlags
                                                        countryCode={idioma?.iso_code}
                                                        key={idioma.id}
                                                    />
                                                </Col>
                                                
                                            }
                                            key={i}
                                            
                                        >
                                            <TextField
                                                onChange={(e) =>handleChange(e)}
                                                id="filled-helperText"
                                                label={`Titulo ${idioma.idioma}`}
                                                size="small"
                                                fullWidth
                                                name={`titulo_${idioma.id}`}
                                                defaultValue={
                                                    `${property?.descrips?.find(idi => idi.id_idioma === idioma.id)?.titulo || ""}`
                                                }
                                                sx={inputStyles}
                                            />
                                            <br/>
                                            <br/>
                                            <TextField
                                                style={{width:'100%'}}
                                                multiline
                                                label={`Descripción ${idioma.idioma}`}
                                                rows={12}
                                                onChange={(e) =>handleChange(e)}
                                                name={`descrip_${idioma.id}`}
                                                defaultValue={
                                                    `${(property?.descrips?.find(idi => idi.id_idioma === idioma.id)?.descrip || "").replace(/[\^*~'\\]/gi, '\n')}`
                                                }
                                                sx={inputStyles}
                                            />
                                             
                                        </Tab>
                                    );
                                })}
                            </Tabs>
                        </Paper>
                        <h3 style={{textAlign:'left'}}>Características</h3>
                        <Divider variant="middle" />
                        <Paper elevation={0} sx={paperStyles}>
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Tipo"
                                size="small"
                                name="tipo_ofer"
                                defaultValue={`${property?.tipo_ofer ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="aseos"
                                size="small"
                                name="aseos"
                                defaultValue={`${property?.aseos ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Baños"
                                size="small"
                                name="banyos"
                                defaultValue={`${property?.banyos ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Habitaciones Dobles"
                                size="small"
                                name="habdobles"
                                defaultValue={`${property?.habdobles ?? ''}`}
                                sx={inputStyles}
                            />

                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Habitaciones"
                                size="small"
                                name="habitaciones"
                                defaultValue={`${property?.habitaciones ?? ''}`}
                                sx={inputStyles}
                            />

                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Conservación"
                                size="small"
                                name="conservacion"
                                defaultValue={`${property?.conservacion ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Acción"
                                size="small"
                                name="accion"
                                defaultValue={`${property?.accion ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Precio de Alquiler"
                                size="small"
                                name="precioalq"
                                defaultValue={`${property?.precioalq ?? ''}`}
                                sx={inputStyles}
                            />
                            
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Tipo Mensual"
                                size="small"
                                name="tipomensual"
                                defaultValue={`${property?.tipomensual ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Impuesto de Bienes Inmuebles (IBI)"
                                size="small"
                                name="ibi"
                                defaultValue={`${property?.ibi ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Energía Letra"
                                size="small"
                                name="energialetra"
                                defaultValue={`${property?.energialetra ?? ''}`}
                                sx={inputStyles}
                            />

                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Energía Recibido"
                                size="small"
                                name="energiarecibido"
                                defaultValue={`${property?.energiarecibido ?? ''}`}
                                sx={inputStyles}
                            />

                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Energía Valor"
                                size="small"
                                name="energiavalor"
                                defaultValue={`${property?.energiavalor ?? ''}`}
                                sx={inputStyles}
                            />

                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Emisiones Letra"
                                size="small"
                                name="emisionesletra"
                                defaultValue={`${property?.emisionesletra ?? ''}`}
                                sx={inputStyles}
                            />

                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Emisiones Valor"
                                size="small"
                                name="emisionesvalor"
                                defaultValue={`${property?.emisionesvalor ?? ''}`}
                                sx={inputStyles}
                            />
                           
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Distancia al Mar"
                                size="small"
                                name="distmar"
                                defaultValue={`${property?.distmar ?? ''}`}
                                sx={{
                                    ...inputStyles,
                                    width: "223px",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset", 
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">m</InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Orientación"
                                size="small"
                                name="orientacion"
                                defaultValue={`${property?.orientacion ?? ''}`}
                                sx={inputStyles}
                            />
                             <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Gastos Comunitarios"
                                size="small"
                                name="gastos_com"
                                defaultValue={`${property?.gastos_com ?? ''}`}
                                sx={inputStyles}
                            />
                           
                        </Paper>
                        {/* INTERIOR */}
                        <h3 style={{textAlign:'left'}}>Datos de interior</h3>
                        <Divider variant="middle"/>
                        <Paper elevation={0} sx={paperStyles}>
                            
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Tipo Fachada"
                                size="small"
                                name="tfachada"
                                defaultValue={`${property?.tfachada ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Tipo Cocina"
                                size="small"
                                name="tcocina"
                                defaultValue={`${property?.tcocina ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Tipo Postigo"
                                size="small"
                                name="tpostigo"
                                defaultValue={`${property?.tpostigo ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Tipo Baño"
                                size="small"
                                name="tbano"
                                defaultValue={`${property?.tbano ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Tipo Interior"
                                size="small"
                                name="tinterior"
                                defaultValue={`${property?.tinterior ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Carpintería Interior"
                                size="small"
                                name="carpint"
                                defaultValue={`${property?.carpint ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Carpintería Exterior"
                                size="small"
                                name="carpext"
                                defaultValue={`${property?.carpext ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Planta"
                                size="small"
                                name="planta"
                                defaultValue={`${property?.planta ?? ''}`}
                                sx={inputStyles}
                            />
                             <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Número de Planta"
                                size="small"
                                name="numplanta"
                                defaultValue={`${property?.numplanta ?? ''}`}
                                sx={inputStyles}
                            />
                           
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Salón"
                                size="small"
                                name="salon"
                                defaultValue={`${property?.salon ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Plaza de Garaje"
                                size="small"
                                name="plaza_gara"
                                defaultValue={`${property?.plaza_gara ?? ''}`}
                                sx={inputStyles}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Garaje Doble"
                                size="small"
                                name="garajedoble"
                                defaultValue={`${property?.garajedoble ?? ''}`}
                                sx={inputStyles}
                            />
 
                        </Paper>
                        {/* SUPERFICIE */}
                        <h3 style={{textAlign:'left'}}>Datos de Superficies</h3>
                        <Divider variant="middle"  />
                        <Paper elevation={0} sx={paperStyles}>
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Metros Construidos"
                                size="small"
                                name="m_cons"
                                defaultValue={`${property?.m_cons ?? ''}`}
                                sx={{
                                    ...inputStyles,
                                    width: "223px",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset",
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">m<sup>2</sup></InputAdornment>
                                    )
                                }}
                               
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Metros de Parcela"
                                size="small"
                                name="m_parcela"
                                defaultValue={`${property?.m_parcela ?? ''}`}
                                sx={{
                                    ...inputStyles,
                                    width: "223px",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset", 
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">m<sup>2</sup></InputAdornment>
                                    )
                                }}
                            />

                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Metros Útiles"
                                size="small"
                                name="m_utiles"
                                defaultValue={`${property?.m_utiles ?? ''}`}
                                sx={{
                                    ...inputStyles,
                                    width: "223px",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset !important", 
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">m<sup>2</sup></InputAdornment>
                                    )
                                }}
                            />

                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Metros de Cocina"
                                size="small"
                                name="m_cocina"
                                defaultValue={`${property?.m_cocina ?? ''}`}
                                sx={{
                                    ...inputStyles,
                                    width: "223px",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset", 
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">m<sup>2</sup></InputAdornment>
                                    )
                                }}
                            />

                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Metros de Comedor"
                                size="small"
                                name="m_comedor"
                                defaultValue={`${property?.m_comedor ?? ''}`}
                                sx={{
                                    ...inputStyles,
                                    width: "223px",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset", 
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">m<sup>2</sup></InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                onChange={(e) =>handleChange(e)}
                                id="filled-helperText"
                                label="Metros de Terraza"
                                size="small"
                                name="m_terraza"
                                defaultValue={`${property?.m_terraza ?? ''}`}
                                sx={{
                                    ...inputStyles,
                                    width: "223px",
                                    "& .MuiInputAdornment-root": {
                                        width: "unset", 
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">m<sup>2</sup></InputAdornment>
                                    )
                                }}
                            />
                        </Paper>
                        {/* EXTERIORES */}
                        <h3 style={{textAlign:'left'}}>Datos de exterior</h3>
                        <Divider variant="middle"  />
                        <Paper elevation={0} sx={paperStyles}>
                            <SwitchCustom 
                                handleChange = { handleChange} 
                                value={property?.gasciudad}
                                label={'Gas Ciudad'}
                                name={'gasciudad'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.linea_tlf}
                                label={'Línea Telefónica'}
                                name={'linea_tlf'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.tren}
                                label={'Tren'}
                                name={'tren'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.metro}
                                label={'Metro'}
                                name={'metro'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.primera_line}
                                label={'Primera Línea de Playa'}
                                name={'primera_line'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.hospitales}
                                label={'Hospitales'}
                                name={'hospitales'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.golf}
                                label={'Golf'}
                                name={'golf'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.zonas_infantiles}
                                label={'Zonas Infantiles'}
                                name={'zonas_infantiles'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.colegios}
                                label={'Colegios'}
                                name={'colegios'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.arboles}
                                label={'Árboles'}
                                name={'arboles'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.piscina_com}
                                label={'Piscina Comunitaria'}
                                name={'piscina_com'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.piscina_prop}
                                label={'Piscina Propia'}
                                name={'piscina_prop'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.urbanizacion}
                                label={'Urbanización'}
                                name={'urbanizacion'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.preinstaacc}
                                label={'Preinstalación Aire Acondicionado'}
                                name={'preinstaacc'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.apartseparado}
                                label={'Apartamento Separado'}
                                name={'apartseparado'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.montana}
                                label={'Montaña'}
                                name={'montana'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.rural}
                                label={'Rural'}
                                name={'rural'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.costa}
                                label={'Costa'}
                                name={'costa'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.vallado}
                                label={'Vallado'}
                                name={'vallado'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.autobuses}
                                label={'Autobuses'}
                                name={'autobuses'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.centros_comerciales}
                                label={'Centros Comerciales'}
                                name={'centros_comerciales'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.tranvia}
                                label={'Tranvia'}
                                name={'tranvia'}
                            />
                        </Paper>
                        {/* OTROS DATOS */}
                        <h3 style={{textAlign:'left'}}>Otros datos de importancia</h3>
                        <Divider variant="middle" />
                        <Paper elevation={0} sx={paperStyles}>
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.airecentral}
                                label={'Aire Central'}
                                name={'airecentral'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.aire_con}
                                label={'Aire Acondicionado'}
                                name={'aire_con'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.alarma}
                                label={'Alarma'}
                                name={'alarma'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.alarmaincendio}
                                label={'Alarma Incendio'}
                                name={'alarmaincendio'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.alarmarobo}
                                label={'Alarma Robo'}
                                name={'alarmarobo'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.arma_empo}
                                label={'Armario Empotrado'}
                                name={'arma_empo'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.ascensor}
                                label={'Ascensor'}
                                name={'ascensor'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.balcon}
                                label={'Balcón'}
                                name={'balcon'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.bar}
                                label={'Bar'}
                                name={'bar'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.jardin}
                                label={'Barbacoa'}
                                name={'jardin'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.barbaco}
                                label={'Jardín'}
                                name={'barbaco'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.cajafuerte}
                                label={'Caja Fuerte'}
                                name={'cajafuerte'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.calefacentral}
                                label={'Calefacción Central'}
                                name={'calefacentral'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.calefaccion}
                                label={'Calefacción'}
                                name={'calefaccion'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.comunidadincluida}
                                label={'Comunidad Incluida'}
                                name={'comunidadincluida'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.adaptadominus}
                                label={'Adaptado a Discapacitados'}
                                name={'adaptadominus'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.adaptadominus}
                                label={'Chimenea'}
                                name={'adaptadominus'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.diafano}
                                label={'Diáfano'}
                                name={'diafano'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange } 
                                value={property?.electro}
                                label={'Electrodomésticos'}
                                name={'electro'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.depoagua}
                                label={'Depósito de Agua'}
                                name={'depoagua'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.descalcificador}
                                label={'Descalcificador'}
                                name={'descalcificador'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.despensa}
                                label={'Despensa'}
                                name={'despensa'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.esquina}
                                label={'Esquina'}
                                name={'esquina'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.galeria}
                                label={'Galería'}
                                name={'galeria'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.gimnasio}
                                label={'Gimnasio'}
                                name={'gimnasio'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.habjuegos}
                                label={'Habitación de Juegos'}
                                name={'habjuegos'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.hidromasaje}
                                label={'Hidromasaje'}
                                name={'hidromasaje'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.jacuzzi}
                                label={'Jacuzzi'}
                                name={'jacuzzi'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.lavanderia}
                                label={'Lavandería'}
                                name={'lavanderia'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.luminoso}
                                label={'Luminoso'}
                                name={'luminoso'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.muebles}
                                label={'Muebles'}
                                name={'muebles'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.muebles}
                                label={'Ojo de Buey'}
                                name={'ojobuey'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.patio}
                                label={'Patio'}
                                name={'patio'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.puerta_blin}
                                label={'Puerta Blindada'}
                                name={'puerta_blin'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.sauna}
                                label={'Sauna'}
                                name={'sauna'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={property?.solarium}
                                label={'Solarium'}
                                name={'solarium'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.sotano ?? ''}`}
                                label="Sótano"
                                name={'sotano'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.buhardilla ?? ''}`}
                                label="Buhardilla"
                                name={'sotano'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.pergola ?? ''}`}
                                label="Pérgola"
                                name={'pergola'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.pergola ?? ''}`}
                                label="Trastero"
                                name={'trastero'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.video_port ?? ''}`}
                                label="Video Portero"
                                name={'video_port'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.vistasalmar ?? ''}`}
                                label="Vistas al Mar"
                                name={'vistasalmar'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.satelite ?? ''}`}
                                label="Satélite"
                                name={'satelite'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.mirador ?? ''}`}
                                label="Mirador"
                                name={'mirador'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.bombafriocalor ?? ''}`}
                                label="Bomba Frío/Calor"
                                name={'bombafriocalor'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.tv ?? ''}`}
                                label="TV"
                                name={'tv'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.terraza ?? ''}`}
                                label="Terraza"
                                name={'terraza'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.terrazaacris ?? ''}`}
                                label="Terraza Acrístalada"
                                name={'terrazaacris'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.vestuarios ?? ''}`}
                                label="Vestuarios"
                                name={'vestuarios'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.cocina_inde ?? ''}`}
                                label="Cocina Independiente"
                                name={'cocina_inde'}
                            />
                            <SwitchCustom 
                                handleChange = {handleChange }
                                value={`${property?.luz ?? ''}`}
                                label="Luz"
                                name={'luz'}
                            />
                        </Paper>            
                    </>
                  : <></>}
                
                </Box>
            </Box> 
            <style jsx='true'>
                {`
                    .tab-content {
                        width:100%;
                        text-align: left;
                    }
                `}
            </style>
        </>
    );
}
    
export default PropiedadForm;
import React, { useState, useEffect } from "react";
import {
  getPropertyDocuments,
  downloadCustomerDocument,
  addFilesProperties,
  deleteFilePropertie,
  updateFilesProperties,
  downloadFilePropertie,
} from "../../services";
import { GetLanguageFlags, SnackbarUtilities } from "../../utilities";
import { Link, useParams } from "react-router-dom";
import { Download, Trash, Plus, Xbox } from "react-bootstrap-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import TrFormulario from "../../components/TrFormulario";
import Swal from "sweetalert2";

const DocumentosPropiedad = ({ ref, documentos, idiomas }) => {
  // const [documentos, setDocumentos] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [docSeleccionados, setdocSeleccionados] = useState([]);
  const params = useParams();
  const [numCheck, setNumCheck] = useState(0);
  const [docs, setDocs] = useState(documentos);
  const [nuevasLineas, setNuevasLineas] = useState([]);
  const [showNuevoDocumento, setShowNuevoDocumento] = useState(false);
  useEffect(() => {
    const cantidad = documentos.filter(
      (objeto) => objeto.status === true
    ).length;
    setTotalItems(cantidad);
  }, []);
  const handleDoubleClick = () => {
    setIsEditing(true);
  };
  const addNuevalinea = async (e) => {
    const nuevaLinea = (
      <TrFormulario
        key={nuevasLineas.length}
        onSave={addNuevoDocumento}
        idiomas={idiomas}
        check={check}
        onCancel={() => setShowNuevoDocumento(false)}
      />
    );
    setNuevasLineas([...nuevasLineas, nuevaLinea]);
    setShowNuevoDocumento(false);
  };
  const addNuevoDocumento = async (nuevoDocumento) => {
    let res = "";
    let updatedDocs = [];
    
    if (nuevoDocumento.id) {
    
      updatedDocs = docs.map((doc) => {
        if (doc.id === nuevoDocumento.id) {
          return { ...doc, ...nuevoDocumento };
        }
        return doc;
      });
      
      setIsEditing(false);
      res = await updateFilesProperties(params.ref, nuevoDocumento);
      // console.log(res);
      if (res == 201) {
        Swal.fire({
          icon: "success",
          title: "Documento editado!",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al editar Documento!",
          text: res.message,
        });
      }
    } else {
      updatedDocs = [...docs, nuevoDocumento];
      res = await addFilesProperties(params.ref, nuevoDocumento);
      
      if (res == 201) {
        Swal.fire({
          icon: "success",
          title: "Documento subido!",
          showConfirmButton: false,
          timer: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al Subir Documento!",
          text: res[0].message,
        });
      }
    }

    setDocs(updatedDocs);
    setShowNuevoDocumento(false);
    setNuevasLineas([]);
  };
  const deleteDocumento = async (id_doc, index) => {
    const res = await deleteFilePropertie(id_doc);

    if (res == 200) {
      Swal.fire({
        icon: "success",
        title: "Documento Eliminado!",
        showConfirmButton: false,
        timer: 1500,
      });
      setDocs(docs.filter((_, i) => i !== index));
    } else {
      Swal.fire({
        icon: "error",
        title: "Error al Eliminar Documento!",
        text: res.message,
      });
    }
  };
  const checkTodo = (evt) => {
    let arr = [];
    if (evt.target.checked) {
      setNumCheck(totalItems);

      setdocSeleccionados(arr);
    } else {
      setNumCheck(0);
      documentos.forEach((e, i) => {
        document.getElementById("seleccionar" + i).checked = false;
      });
      setdocSeleccionados([]);
    }
  };
  const check = (evt) => {
    if (evt.target.checked) {
      setNumCheck(numCheck + 1);
    } else {
      setNumCheck(numCheck - 1);
      document.getElementById("seleccionar-todo").checked = false;
    }
  };
  const downlonad = async (id, nombre) => {
    downloadFilePropertie(id, nombre);
  };
  return (
    <>
      <div className="container contenido-documentos my-3">
        <div className="header">
          <div className="tituto">
            <h3>{totalItems} Documentos</h3>
          </div>
          <div className="descargar">
            <h6 className="me-2">{numCheck} Seleccionados</h6>
          </div>
        </div>
        <div className="info-documentos">
          {totalItems === 0 ? (
            // <div style={{backgroundColor:"#fff"}}><p>No hay documentos</p></div>
            <table id="docs_list" className="table table-bordered text-left">
              <thead>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="seleccionar-todo"
                      id="seleccionar-todo"
                      onChange={checkTodo}
                    />
                  </td>
                  <td>Sección</td>
                  <td>Titulo Documento</td>
                  <td style={{ textAlign: "center" }}>Idioma</td>
                  <td>
                    <button
                      className="btn btn-outline-dark"
                      onClick={(e) => {
                        addNuevalinea(e);
                      }}
                    >
                      <Plus />
                    </button>
                  </td>
                </tr>
              </thead>
              <tbody>
                {nuevasLineas.length > 0 ? (
                  nuevasLineas.map((linea, index) => {
                    return <React.Fragment key={index}>{linea}</React.Fragment>;
                  })
                ) : (
                  <tr>
                    <td colSpan={5}> No hay Documentos</td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <table className="table table-bordered text-left">
              <thead>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="seleccionar-todo"
                      id="seleccionar-todo"
                      onChange={checkTodo}
                    />
                  </td>
                  <td>Sección</td>
                  <td>Titulo Documento</td>
                  <td style={{ textAlign: "center" }}>Idioma</td>
                  <td>
                    <button
                      className="btn btn-outline-dark"
                      onClick={(e) => {
                        e.preventDefault();
                        addNuevalinea(e);
                      }}
                    >
                      <Plus />
                    </button>
                  </td>
                </tr>
              </thead>
              <tbody>
                {docs.map((d, i) =>
                  d.status ? (
                    <React.Fragment key={i}>
                      {isEditing ? (
                        <TrFormulario
                          onSave={addNuevoDocumento}
                          idiomas={idiomas}
                          check={check}
                          isEditing={isEditing}
                          initialValues={{
                            id: d.id,
                            nombre: d.nombre,
                            seccion:d.seccion,
                            id_idioma: d.id_idioma,
                          }}
                          onCancel={() => setIsEditing(false)}
                        />
                      ) : (
                        <tr id={`linea-${i}`} onDoubleClick={handleDoubleClick}>
                          <td>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={`${i}`}
                              id={`seleccionar${i}`}
                              onChange={check}
                            />
                          </td>
                          <td>
                            {d.seccion}
                          </td>
                          <td>
                            <OverlayTrigger
                              id={`txt-form-${i}`}
                              placement="top"
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    Doble clic para editar el nombre y subir el
                                    archivo
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <span style={{ cursor: "pointer" }}>
                                {d.nombre === undefined ? d.titulo : d.nombre}
                              </span>
                            </OverlayTrigger>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {idiomas.map((id) =>
                              id.id === d.id_idioma ? <GetLanguageFlags countryCode={id?.iso_code}/> : null
                            )}
                          </td>
                          <td style={{ textAlign: "center !important" }}>
                            <button
                              className="btn btn-outline-dark"
                              onClick={() => {
                                downlonad(
                                  d.id,
                                  d.nombre === undefined ? d.titulo : d.nombre
                                );
                              }}
                            >
                              <Download />
                            </button>{" "}
                            <button
                              className="btn btn-outline-dark"
                              onClick={() => {
                                deleteDocumento(d.id, i);
                              }}
                            >
                              <Trash />
                            </button>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ) : null
                )}
                {showNuevoDocumento && (
                  <TrFormulario
                    onSave={addNuevoDocumento}
                    idiomas={idiomas}
                    check={check}
                    onCancel={() => setShowNuevoDocumento(false)}
                  />
                )}
                {nuevasLineas.map((linea, index) => {
                  return <React.Fragment key={index}>{linea}</React.Fragment>;
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentosPropiedad;

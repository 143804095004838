import React, { useState } from "react";
import { Box, TextField, List, ListItem, ListItemText, Popover } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function CustomDropdown({ value, options, onAddOption, onChange }) {
    const [inputValue, setInputValue] = useState(""); // Valor temporal para entrada
    const [anchorEl, setAnchorEl] = useState(null); // Controla el desplegable

    const handleOpenDropdown = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDropdown = () => {
        setAnchorEl(null);
    };

    const handleAddOption = () => {
        const newOption = Number(inputValue);
        if (newOption >= 1 && newOption <= 400) {
            onAddOption(newOption); // Notifica al padre para actualizar globalmente
        }
        setInputValue(""); // Limpia el campo de entrada
        handleCloseDropdown();
    };

    return (
        <Box>
            <TextField
                value={value}
                onClick={handleOpenDropdown}
                size="small"
                variant="standard"
                sx={{
                    width: 60
                }}
                InputProps={{
                    endAdornment: (
                        <ArrowDropDownIcon
                            sx={{ cursor: "pointer", fontSize: 20 }}
                            onClick={handleOpenDropdown}
                        />
                    ),
                }}
                onChange={(e) => {
                    const val = e.target.value;
                    if (val === "" || (val >= 1 && val <= 400)) {
                        setInputValue(val);
                    }
                }}
            />
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseDropdown}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: { maxHeight: 200, width: 82 },
                }}
            >
                <List>
                    {options.map((option, index) => (
                        <ListItem button key={index} onClick={() => onChange(option)}>
                            <ListItemText primary={option} />
                        </ListItem>
                    ))}
                    <ListItem>
                        <TextField
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder="  +"
                            size="small"
                            variant="standard"
                            type="number"
                            fullWidth
                            inputProps={{
                                min: 1,
                                max: 400,
                            }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleAddOption();
                                }
                            }}
                        />
                    </ListItem>
                </List>
            </Popover>
        </Box>
    );
}

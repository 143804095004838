import React, { useEffect } from 'react';
import { Box, Button, TextField, Typography, Container, Paper } from '@mui/material';
import imgBackground from '../../images/casa-fondo.jpg';
import Logo from '../../images/logo_laehomes_color.svg';
import * as Yup from 'yup';
import { useFormValidation } from "../../utilities/formValidator";
import { SnackbarUtilities } from "../../utilities/snackbar-manager";

const Login = () => {


    const initialValues = {
        currentPass: '',
        newPass: '',
        repeatNewPass: '',
    };

    const validationSchema = Yup.object().shape({
        currentPass: Yup.string().required('Campo obligatorio').min(8, 'Debe tener al menos 8 caracteres'),
        newPass: Yup.string().required('Campo obligatorio').min(8, 'Debe tener al menos 8 caracteres'),
        repeatNewPass: Yup.string().required('Campo obligatorio').min(8, 'Debe tener al menos 8 caracteres'),
    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setFieldTouched } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            if (values) {
                // console.log(values);
                // const res = await signIn(values);
                // if (res) {
                //     console.log(res);
                //     if (res.token) {
                //         setUser(res);
                //         console.log("entro");

                //         navigate("/", { state: { fromLogin: true } });
                //         SnackbarUtilities.success("Logeado correctamente correctamente!!");
                //     }
                // } else {
                //     console.log("error");
                //     SnackbarUtilities.error("Error en algun validador del campo");

                // }
                SnackbarUtilities.error("Caracteristica pendiente de implementar");
                // const formData = new FormData();
            } else {
                
                // SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundImage: `url(${imgBackground})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                margin: 0
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    display: 'flex',
                    '& > :not(style)': {
                        m: 1,
                        width: 380,
                        height: '65%',
                    },
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    margin: 0
                }}
            >
                <Box
                    sx={{
                        px: '5vh',
                        py: '5vh',
                        textAlign: "center",
                        margin: 0
                    }}
                >
                    <img src={Logo} alt="LAE HOMES" style={{ width: '70%', marginBottom: 10 }} />
                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 3, color: "#bababa" }} >
                        Cambia Contraseña
                    </Typography>
                    <form onSubmit={handleSubmit} autocomplete="off">

                        <TextField
                            sx={inputStyles}
                            label="Contraseña Actual"
                            variant="outlined"
                            id="textbox"
                            type="password"
                            //focused
                            name='currentPass'
                            fullWidth
                            value={values.currentPass}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autocomplete="off"
                            error={touched.currentPass && Boolean(errors.currentPass)}
                            helperText={touched.currentPass && errors.currentPass}

                        />
                        <TextField
                            sx={inputStyles}
                            label="Contraseña Nueva"
                            variant="outlined"
                            id="textbox"
                            type="password"
                            //focused
                            name='newPass'
                            fullWidth
                            value={values.newPass}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autocomplete="off"
                            error={touched.newPass && Boolean(errors.newPass)}
                            helperText={touched.newPass && errors.newPass}

                        />
                        <TextField
                            sx={inputStyles}
                            label="Repetir Contraseña Nueva"
                            variant="outlined"
                            id="textbox"
                            type="password"
                            //focused
                            name='repeatNewPass'
                            fullWidth
                            value={values.repeatNewPass}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autocomplete="off"
                            error={touched.repeatNewPass && Boolean(errors.repeatNewPass)}
                            helperText={touched.repeatNewPass && errors.repeatNewPass}

                        />
                        <Button variant="contained" type="submit" sx={{
                            backgroundColor: '#212529',
                            ':hover': {
                                backgroundColor: '#fff',
                                boxShadow: "none",
                                border: "1px solid #33383b" // Cambia este valor al color deseado
                            },
                            color: "rgb(196, 175, 108)",

                        }} fullWidth >
                            Cambiar Contraseña
                        </Button>
                    </form>
                </Box>
            </Paper>
        </Box>
    );
};
const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 4,
    marginTop: 0
};
export default Login;

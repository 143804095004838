import {
    Button,
    Box,
    Typography,
    Popover,
    MenuItem,
    Select,
    FormControl,
    Menu
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const styleMenuItem = {
    border: '2px solid #A69150', // Borde dorado
    borderRadius: '8px', // Bordes redondeados
    margin: '4px'
}
const HojaDescuentoForm = ({
    styledButton,
    handleClick,
    anchorEl,
    handleClose,
    ide,
    open,
    handleChange,
    vinculo2 }) => {

    const onClose = () => {
        handleClose(null)
    }
    const handleListItemClick = (e) => {
        const value = e.currentTarget.getAttribute("value");
        const name = e.currentTarget.getAttribute("name");
        const event = {target:{name, value}};
        // console.log(event.target);
        handleChange(event);
        handleClose(null);
    };
    
    return (
        <>
            <Button
                variant="outline-dark"
                aria-controls="simple-menu"
                aria-haspopup="true"
                size="small"
                title="Descuento"
                sx={{
                    ...styledButton,
                    marginRight: '5px',
                    backgroundColor: '#33383b',
                    color: '#c4af6c'
                }}
                onClick={(e) => handleClick(e)}
                // onChange={(e) => handleChange(e)}
                startIcon={
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <Typography sx={{ paddingLeft: '0', fontSize: '12px !important' }}>
                            {vinculo2 === undefined ? 'DESCUENTO' : vinculo2 !== "NADIE" ? `${vinculo2}` : 'DESCUENTO'}
                        </Typography>
                    </Box>
                }
                endIcon={<KeyboardArrowDownIcon />}
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
            >
                {['NADIE', 'COLABORADOR'].map((val, index) => (
                    <MenuItem 
                        key={index} 
                        value={val}
                        name={'vinculo2'}
                        sx={
                            val === vinculo2 ? styleMenuItem:''
                        }
                        onClick={(e)=>handleListItemClick(e)}
                       
                    >
                        {val}
                    </MenuItem>
                ))}
            </Menu>
           
        </>
    );
}

export default HojaDescuentoForm;
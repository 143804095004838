import { useEffect, useState } from "react";
import {
    TextField,
    InputAdornment,
} from '@mui/material';

const HojaImporteForm = ({
    propiedad,
    setImporte,
    importe,
    handleChange,
    inputStyles,
    comision,
    tipoCom,
    formatNumbers,
    formData }) => {

    const [formattedComision, setFormattedComision] = useState(comision);
    const [editingComision, setEditingComision] = useState(false);
    const [editingImporte, setEditingImporte] = useState(false);

    useEffect(() => {
        if (!editingComision) {
            const formattedValue = parseFloat(comision).toLocaleString("de-DE", {});
            setFormattedComision(formattedValue);
        }
    }, [tipoCom, comision, editingComision]);

    const handleComisionBlur = () => {
        setEditingComision(false);
        const formattedValue = parseFloat(comision).toLocaleString("de-DE", {});
        setFormattedComision(formattedValue);
    };

    const handleComisionFocus = () => {
        setEditingComision(true);
        setFormattedComision(comision);
    };

    // Manejo de "importe"
    const handleImporteBlur = () => {
        setEditingImporte(false);
        setImporte(parseFloat(importe)); // Asegurar que sea un número
    };

    const handleImporteFocus = () => {
        setEditingImporte(true);
    };
    return (

        <>
            <TextField
                onChange={handleChange}
                onBlur={handleImporteBlur}
                onFocus={handleImporteFocus}
                label="Total"
                size="small"
                name="total"
                inputProps={{ style: { textAlign: "right" } }}
                value={editingImporte ? importe : formatNumbers(importe)}
                sx={{ ...inputStyles, maxWidth: "30%" }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            sx={{ paddingLeft: "1%" }}
                            position="end"
                        >
                            €
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                onChange={handleChange}
                label="Tipo Com."
                size="small"
                name="tipoCom"
                inputProps={{ style: { textAlign: "right" } }}
                value={tipoCom ?? ""}
                sx={{ ...inputStyles, maxWidth: "20%" }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            sx={{ paddingLeft: "1%" }}
                            position="end"
                        >
                            %
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                onChange={handleChange}
                onBlur={handleComisionBlur}
                onFocus={handleComisionFocus}
                label="Comisión"
                size="small"
                name="comision"
                inputProps={{ style: { textAlign: "right" } }}
                value={editingComision ? comision : formattedComision}
                sx={{ ...inputStyles, maxWidth: "20%" }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            sx={{ paddingLeft: "1%" }}
                            position="end"
                        >
                            €
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
};

export default HojaImporteForm;
import * as Flags from "react-flags-select";

const exceptionRules = {
  EN: "Us",
};

const GetLanguageFlags = ({ countryCode, height = "24px", width = "24px" }) => {
  const format =
    countryCode.charAt(0).toUpperCase() + countryCode.slice(1).toLowerCase();
  const formattedCountryCode = exceptionRules[countryCode] ?? format;
  return Flags[formattedCountryCode]({ height, width });
};

export default GetLanguageFlags;

import React, { useEffect } from 'react'
import { Button } from '@mui/material';
import { useUser } from '../hooks/UserContextLogin';

export default function ButtonPermission({ action, resource, children, ...props}) {
    const { user, setUser } = useUser();
    
    const hasPermission = (action, resource) => {
        return user?.rol?.permisos?.acciones?.some(permission =>
            (permission.action === action || permission.action === 'all') && permission.resource === resource
        );
    };

    if (!hasPermission(action, resource)) {
        return null;
    }
    return (
      <Button {...props}>
        {children}
      </Button>
    );
  };

import { useState, useEffect } from 'react';
import { 
    Drawer, 
    Box, 
    Typography,
    Checkbox, 
    IconButton 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../Tables/DataTable';
import { useContactos } from '../../hooks';
import { FiltroContactosDrawer } from '../Filtros';
import { useFormValidation } from '../../utilities/formValidator';
import * as Yup from 'yup';
const CheckboxStyles = {
    '&.Mui-checked': {
        color: '#c4af6c',
    },
    '& .MuiSvgIcon-root': {
        color: '#33383b',
        fontSize: 28
    },
    '&:hover': {
        backgroundColor: 'rgba(196, 175, 108, 0.04)',
    },
};
const DrawerContactos = ({ open, setOpen, setContacto, contacto, toSave, setColaborador, setPrescriptor, sucursal }) => {
    const { loading, data, fetchData } = useContactos();
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState({id_sucursal:sucursal?.id, tipo:'Colaborador'});
    const [initialValues, setInitialValues] = useState({
        id_sucursal: null,
        tipo: 'Colaborador',
        nif: '',
        buscador:''
    });
    const validationSchema = Yup.object({
        id_sucursal: Yup.number().nullable(),
        nif: Yup.string().notRequired().nullable(),
        buscador:Yup.string().notRequired().nullable(),
    });
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setValues, handleReset } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const { nif, buscador, tipo} = values;
            await fetchData({ offset: currentPage, limit: 1000,
                filtros: { 
                    id_sucursal: initialValues.id_sucursal, 
                    tipo:tipo,
                    numero_identificacion:nif, 
                    buscador:buscador
                } 
            });
        },
    });
    useEffect(()=> {
        if (sucursal && sucursal?.id) {
            setFiltros(prevData => ({
                ...prevData,
                id_sucursal: sucursal?.id
            }));
            setInitialValues(prevValues => ({
                ...prevValues,
                id_sucursal: sucursal?.id
            }));
        }
    },[sucursal]);
    const headers = [
        {
            checkCli: 'checkCli',
            numeric: false,
            disabledPadding: false,
            label: '',
            overrideFunc: (data, row) => (
                <>
                    <Checkbox
                        sx={CheckboxStyles}
                        onChange={(e) => addContacto(e, row)}
                        checked={contacto?.id === row?.id}
                    />
                </>
            ),
        },
        {
            numero_identificacion: 'numero_identificacion',
            numeric: false,
            disabledPadding: false,
            label: 'Nif',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.numero_identificacion}
                </Typography>
            )
        },
        {
            nombre: 'nombre',
            numeric: false,
            disabledPadding: false,
            label: 'Nombre',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.nombre}
                </Typography>
            )
        },
        {
            apellidos: 'apellidos',
            numeric: false,
            disabledPadding: false,
            label: 'Apellidos',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.apellidos}
                </Typography>
            )
        },
        {
            razonSocial: 'razonSocial',
            numeric: false,
            disabledPadding: false,
            label: 'R. Social',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.razon_social ?? row?.nombre_comercial}
                </Typography>
            )
        },
        {
            telefono: 'telefono',
            numeric: false,
            disabledPadding: false,
            label: 'Teléfono',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.telefono}
                </Typography>
            )
        },
        {
            email: 'email',
            numeric: false,
            disabledPadding: false,
            label: 'Email',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.email}
                </Typography>
            )
        },
    ];
    const addContacto = (e, con) => {
        const { checked } = e.target;
        // console.log(toSave)
        // console.log(con);

        setContacto(prevState => {
            if(checked){
                return {
                    ...prevState,
                    [toSave]:[con]
                };
            } else{
                return {
                    ...prevState,
                    [`${toSave}`]: (prevState[`${toSave}`] || []).filter(item => item !== con) 
                }
            }
        });
    };
    return (
        <>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)} sx={{zIndex:2000}}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <IconButton size='small' onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <div className='container'>
                    <Box component="section" sx={{ p: 3 }}>
                        <Typography variant="h3" gutterBottom>Colaboradores</Typography>
                        <Typography variant="p" gutterBottom>Debes Seleccionar un colaborador</Typography>
                    </Box>
                </div>
                <FiltroContactosDrawer
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    filtros={filtros}
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFiltros={setFiltros}
                    handleSubmit={handleSubmit}
                />
                <div className='middleline'>
                    <DataTable
                        headers={headers}
                        data={data}
                        isLoading={loading}
                        filtros={filtros}
                        fetcher={fetchData}
                        mensaje={"No hay contactos disponibles"}
                    />
                </div>
            </Drawer>
        </>
    );
}

export default DrawerContactos;
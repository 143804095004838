import { useState } from "react";
import {
    TextField,
} from '@mui/material';
const HojaClienteForm = ({ handleChange, cliente, inputStyles }) => {
    return (
        <>
            <TextField
                onChange={handleChange}
                label="Titular"
                size="small"
                name="nombre"
                value={
                    `${cliente?.nombre ?? ''} ${cliente?.apellidos ?? ''}`.trim() ||
                    cliente?.razon_social ||
                    ''
                }
                sx={{ ...inputStyles, maxWidth: '65%' }}
                fullWidth
            />
            <TextField
                onChange={handleChange}
                label="NIF"
                size="small"
                name="numero_documento"
                value={cliente?.numero_identificacion || ''}
                sx={{ ...inputStyles, maxWidth: '15%' }}
                fullWidth
            />
            <TextField
                onChange={handleChange}
                label="Cónyuge"
                size="small"
                name="conyuge"
                value={cliente?.conyuge || cliente?.nomconyuge || ''}
                sx={{ ...inputStyles, maxWidth: '65%', }}
                fullWidth
            />
            <TextField
                onChange={handleChange}
                label="NIF"
                size="small"
                name="NifCon"
                value={cliente?.NifCon || cliente?.idConyuge || ''}
                sx={{ ...inputStyles, maxWidth: '15%' }}
                fullWidth
            />
            <TextField
                onChange={handleChange}
                label="Dirección"
                size="small"
                name="direccion"
                value={cliente?.direccion || ''}
                sx={{ ...inputStyles, maxWidth: '65%', }}
                fullWidth
            />
            <TextField
                onChange={handleChange}
                label="CP"
                size="small"
                name="cp"
                value={cliente?.cp || ''}
                sx={{ ...inputStyles, maxWidth: '15%' }}
                fullWidth
            />
            <TextField
                onChange={handleChange}
                label="Población"
                size="small"
                name="poblacion"
                value={cliente?.poblacion || ''}
                sx={{ ...inputStyles, maxWidth: '30%', }}
                fullWidth
            />
            <TextField
                onChange={handleChange}
                id="telefono"
                label="Teléfono"
                size="small"
                name="telefono"
                value={cliente?.telefono || ''}
                sx={{ ...inputStyles, maxWidth: '20%', }}
                fullWidth
            />
            <TextField
                onChange={handleChange}
                id="email"
                label="Email"
                size="small"
                name="email"
                value={cliente?.email || ''}
                sx={{ ...inputStyles, maxWidth: '29%', }}
                fullWidth
            />
            <TextField
                onChange={handleChange}
                label="Representante legal"
                size="small"
                name="repLegal"
                value={cliente?.repLegal || ''}
                sx={{ ...inputStyles, maxWidth: '65%', }}
                fullWidth
            />
            <TextField
                onChange={handleChange}
                label="Teléfono"
                size="small"
                name="telRepresentante"
                value={cliente?.telRepresentante || ''}
                sx={{ ...inputStyles, maxWidth: '15%', }}
                fullWidth
            />
        </>
    );
}

export default HojaClienteForm;
import { useState,useEffect } from 'react';
import { 
    Drawer, 
    Box, 
    Typography, 
    Checkbox,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../Tables/DataTable';
import { FiltroContactosDrawer } from '../Filtros';
import { useFormValidation } from '../../utilities/formValidator';
import { useClientes } from '../../hooks';
import * as Yup from 'yup';
const CheckboxStyles = {
    '&.Mui-checked': {
        color: '#c4af6c',
    },
    '& .MuiSvgIcon-root': {
        color: '#33383b',
        fontSize: 28
    },
    '&:hover': {
        backgroundColor: 'rgba(196, 175, 108, 0.04)',
    },
};
const DrawerClientes = ({ open, setOpen, setCliente, cliente, toSave, sucursal }) => {
    const { loading, data, fetchData } =  useClientes();
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState({ tipo: 'Cliente'});
    const [initialValues, setInitialValues] = useState({
        id_sucursal: null,
        tipo: 'Cliente',
        nif: '',
        buscador:''
    });
    const validationSchema = Yup.object({
        id_sucursal: Yup.number().nullable(),
        nif: Yup.string().notRequired().nullable(),
        buscador:Yup.string().notRequired().nullable(),
    });
    const headers = [ 
        {
            checkCli: 'checkCli',
            numeric: false,
            disabledPadding: false,
            label: '',
            overrideFunc: (data, row) => (
                <Checkbox
                    sx={CheckboxStyles}
                    onChange={(e) => addCliente(e, row)}
                    checked={cliente?.id === row?.id}
                />
            ),
        },
        {
            numero_identificacion:'numero_identificacion',
            numeric: false,
            disabledPadding: false,
            label: 'Nif',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.numero_identificacion}
                </Typography>
            )
        },
        {
            nombre:'nombre',
            numeric: false,
            disabledPadding: false,
            label: 'Nombre',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.nombre}
                </Typography>
            )
        },
        {
            apellidos:'apellidos',
            numeric: false,
            disabledPadding: false,
            label: 'Apellidos',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.apellidos}
                </Typography>
            )
        },
        {
            razonsocial:'razonsocial',
            numeric: false,
            disabledPadding: false,
            label: 'R. Social',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.razon_social}
                </Typography>
            )
        },
        {
            telefono:'telefono',
            numeric: false,
            disabledPadding: false,
            label: 'Teléfono',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.telefono}
                </Typography>
            )
        },
        {
            email:'email',
            numeric: false,
            disabledPadding: false,
            label: 'Email',
            overrideFunc: (data, row) => (
                <Typography>
                    {row?.email}
                </Typography>
            )
        },
    ];
    const addCliente = (e, cli) => {
        const {checked} = e.target; 
        if(checked){
            // console.log(cli);
            setCliente(prevState => ({ ...prevState, [`${toSave}`]: cli, [`id_${toSave}`]:cli?.id }));
        }else{
            setCliente(prevState => ({ ...prevState, [`${toSave}`]: '' }));
        }
    };
    useEffect(()=> {
        if (sucursal && sucursal?.id) {
            setFiltros(prevData => ({
                ...prevData,
                id_sucursal: sucursal?.id
            }));
            setInitialValues(prevValues => ({
                ...prevValues,
                id_sucursal: sucursal?.id
            }));
        }
    },[sucursal]);
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setValues, handleReset } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const { nif, buscador, tipo} = values;
            await fetchData({ offset: currentPage, limit: 1000,
                filtros: { 
                    id_sucursal: initialValues.id_sucursal, 
                    tipo:tipo,
                    numero_identificacion:nif, 
                    buscador:buscador
                } 
            });
        },
    });
  
    return (
        <>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)} sx={{zIndex:2000}}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <IconButton size='small' onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <div className='container'>
                    <Box component="section" sx={{ p:2}}>
                        <Typography variant="h3" gutterBottom>
                            Clientes
                        </Typography>
                        <Typography variant="p" gutterBottom>Debes Seleccionar un Cliente</Typography>
                    </Box>
                </div>
                <FiltroContactosDrawer
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    filtros={filtros}
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFiltros={setFiltros}
                    handleSubmit={handleSubmit}
                />
                <div className='middleline'>
                    <DataTable 
                        headers={headers}
                        data={data}
                        filtros={filtros}
                        isLoading={loading}
                        fetcher={fetchData}
                        mensaje={"No hay clientes disponibles"}
                    />
                </div>
            </Drawer> 
        </>
    );
}

export default DrawerClientes;
export const estadosHf = [
    {
        name:'PENDIENTE VALIDAR ',
        completeName: "Pendiente por validar",
        value:'pendiente_validacion_responsable',
    },
    {
        name:'VALIDADA POR RESPO.',
        completeName: "Validada por el Responsable",
        value:'validado_por_responsable',
    },
    {
        name:'VALIDADA POR ADMIN.',
        completeName: "Validada por el Administrador",
        value:'verificado_por_administrador',
    },
];

import { useState } from "react";
import { listSucursales } from "../services";

function useSucursales(){
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta:{},
        rows: []
    });
    const[loading, setLoading] = useState(true);
    const [dataCustomNames, setDataCustomNames] = useState(null);
    const customNames = [
        'ID', 'id_ext', 'Provincia', 'Municipio', 'Dirección', 'Teléfono',
        'CP','Email','Estado'
    ];
    const formatData = (data) => {
        return data.map((row) => ({
            id: row.id,
            id_ext: row.id_ext,
            provincia: row.provincia,
            municipio: row.municipio,
            direccion: row.direccion,
            telefono: row.telefono,
            cp: row.cp,
            email: row.email,
            estado:row.estado,
            responsable: row.responsable,
            coordinador: row.coordinador,
            nombre_sucursal: row.nombre_sucursal
        }));
    }
    const customNameDataTable = (formattedData) => {
        // Obtener las claves del primer objeto para asignar key y label
        const keys = Object.keys(formattedData[0] || {});
    
        // Retornar un array de objetos con key y label
        return keys.map((key, index) => ({
            key,  // La clave original
            label: customNames[index] || key  // El nombre personalizado o la clave si no hay un nombre en customNames
        }));
    }
    const fetchData = async({offset, limit, filtros,orderBy,order}) => {
        // setLimite(limit);
        // const payload = {
        //     params: {page: offset, limit},
        //     filtros
        // };
        setLimite(limit);
        let payload;
        if (orderBy && order) {
             payload = {
                params: {page: parseInt(offset), limit:parseInt(limit),orderBy:`${orderBy},${order}`},
                filtros
            };
        }else {
             payload = {
                params: {page: parseInt(offset), limit:parseInt(limit)},
                filtros
            };
        }

        try {
            setLoading(true);
            let response = await listSucursales(payload);   
            const { meta, data } = response;

            const formattedData = formatData(data);  // Formatear los datos
            const customData = customNameDataTable(formattedData);  // Personalizar los nombres
            setDataCustomNames(customData);

            setData({
                meta,
                rows: formatData(data),
            })
        } catch (error) {
            console.log(error);
        }finally{
            setLoading(false);
        }
    };
    return {
        data,
        fetchData,
        loading,
        formatData,
        limite,
        dataCustomNames
    };
}
export default useSucursales;
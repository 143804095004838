import { useState, useContext, useEffect } from "react";
import { listHojasForm } from "../services/hojaForm.service";
import { formatDate, formatDateGuiones } from "../utilities";
function useHojasForm() {
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta: {},
        rows: [],
    });
    const [loading, setLoading] = useState(true);

    const formatData = (data) => {
        return data?.map((row) => {
            return {
                id: row.id,
                referencia: row.referencia,
                id_sucursal: row.id_sucursal,
                fechaOperacion: formatDateGuiones(row?.fechaOperacion),
                accion: row.accion,
                tipoCom: row.tipoCom,
                id_comprador: row.id_comprador,
                id_captador: row.id_captador,
                porCaptador: row.porCaptador,
                totalCaptador: row.totalCaptador,
                id_vendedor: row.id_vendedor,
                id_agVendedor: row.id_agVendedor,
                porVendedor: row.porVendedor,
                totalVendedor: row.totalVendedor,
                total: row.total,
                comisionTotal: row.comisionTotal,
                porLae: row.porLae,
                comisionLae: row.comisionLae,
                totalComisionNeta: row.totalComisionNeta,
                opCompartida: row.opCompartida,
                haya: row.haya,
                vinculo2: row.vinculo2,
                vinculo3: row.vinculo3,
                propiedades: row.propiedades,
                observacion: row.observacion,
                sucursal: row.su_sucursal,
                estado: row.estado
            };
        });
    };
    const formatUnicData = (data) => {
        const vendedor = {
            id:data?.vendedor?.id,
            id_sucursal:data?.vendedor?.id_sucursal,
            id_reg:data?.vendedor?.id_reg,
            tipo_documento:data?.vendedor?.tipo_documento,
            numero_identificacion:data?.vendedor?.numero_identificacion,
            nacionalidad:data?.vendedor?.nacionalidad,
            t_aaff:data?.vendedor?.t_aaff,
            email_homes:data?.vendedor?.email_homes,
            n_cuenta:data?.vendedor?.n_cuenta,
            inmobiliaria:data?.vendedor?.inmobiliaria,
            estado_civil:data?.vendedor?.estado_civil,
            idConyuge:data?.vendedor?.idConyuge,
            nomconyuge:data?.vendedor?.nomconyuge,
            tipo_doc_conyuge:data?.vendedor?.tipo_doc_conyuge,
            rep_legal:data?.vendedor?.rep_legal,
            tel_rep_legal:data?.vendedor?.tel_rep_legal,
            email_rep_legal:data?.vendedor?.email_rep_legal,
            observaciones:data?.vendedor?.observaciones,
            razon_social:data?.vendedor?.datos_vendedor?.razon_social,
            nombre:data?.vendedor?.datos_vendedor?.nombre,
            apellidos:data?.vendedor?.datos_vendedor?.apellidos,
            email:data?.vendedor?.datos_vendedor?.email1,
            email2:data?.vendedor?.datos_vendedor?.email2,
            telefono1:data?.vendedor?.datos_vendedor?.telefono1,
            telefono2:data?.vendedor?.datos_vendedor?.telefono2,
            direccion:data?.vendedor?.datos_vendedor?.direccion,
            provincia:data?.vendedor?.datos_vendedor?.provincia,
            municipio:data?.vendedor?.datos_vendedor?.municipio,
            cp:data?.vendedor?.datos_vendedor?.cp,
        };
        const comprador = {
            id:data?.comprador?.id,
            id_sucursal:data?.comprador?.id_sucursal,
            id_reg:data?.comprador?.id_reg,
            tipo_documento:data?.comprador?.tipo_documento,
            numero_identificacion:data?.comprador?.numero_identificacion,
            nacionalidad:data?.comprador?.nacionalidad,
            t_aaff:data?.comprador?.t_aaff,
            email_homes:data?.comprador?.email_homes,
            n_cuenta:data?.comprador?.n_cuenta,
            inmobiliaria:data?.comprador?.inmobiliaria,
            estado_civil:data?.comprador?.estado_civil,
            idConyuge:data?.comprador?.idConyuge,
            nomconyuge:data?.comprador?.nomconyuge,
            tipo_doc_conyuge:data?.comprador?.tipo_doc_conyuge,
            rep_legal:data?.comprador?.rep_legal,
            tel_rep_legal:data?.comprador?.tel_rep_legal,
            email_rep_legal:data?.comprador?.email_rep_legal,
            observaciones:data?.comprador?.observaciones,
            razon_social:data?.comprador?.datos_comprador?.razon_social,
            nombre:data?.comprador?.datos_comprador?.nombre,
            apellidos:data?.comprador?.datos_comprador?.apellidos,
            email:data?.comprador?.datos_comprador?.email1,
            email2:data?.comprador?.datos_comprador?.email2,
            telefono:data?.comprador?.datos_comprador?.telefono1,
            telefono2:data?.comprador?.datos_comprador?.telefono2,
            direccion:data?.comprador?.datos_comprador?.direccion,
            provincia:data?.comprador?.datos_comprador?.provincia,
            municipio:data?.comprador?.datos_comprador?.municipio,
            cp:data?.comprador?.datos_comprador?.cp,
        };
        const propiedades = data?.propiedades?.map(prop => ({
            referencia: prop.referencia,
            provincia: prop.provincia,
            municipio: prop.municipio,
            id: prop.id,
            direccion: prop.direccion,
            importe: prop.precioinmo || prop.importe,
            cp: prop.cp,
            promocion: prop.promocion || ''
        }));
        const colaboradores = data?.colaboradores?.map(col => ({
            id: col?.id,
            numero_identificacion: col?.numero_identificacion,
            n_cuenta: col?.n_cuenta,
            inmobiliaria: col?.inmobiliaria,
            estado_civil: col?.estado_civil,
            idConyuge: col?.idConyuge,
            nomconyuge: col?.nomconyuge,
            tipo_doc_conyuge: col?.tipo_doc_conyuge,
            rep_legal: col?.rep_legal,
            tel_rep_legal: col?.tel_rep_legal,
            email_rep_legal: col?.email_rep_legal,
            razon_social:col?.datos_colaborador?.razon_social,
            nombre:col?.datos_colaborador?.nombre,
            apellidos:col?.datos_colaborador?.apellidos,
            email1:col?.datos_colaborador?.email1,
            email2:col?.datos_colaborador?.email2,
            telefono:col?.datos_colaborador?.telefono1,
            telefono2:col?.datos_colaborador?.telefono2,
            direccion:col?.datos_colaborador?.direccion,
            provincia:col?.datos_colaborador?.provincia,
            municipio:col?.datos_colaborador?.municipio,
            cp:col?.datos_colaborador?.cp,
            porCola:col?.rHojafColaborador?.porCola,
            totalCol:col?.rHojafColaborador?.totalCol,
        }));
        const aaff = data?.admins_fincas?.map(af => ({
            id: af?.id,
            numero_identificacion: af?.numero_identificacion,
            n_cuenta: af?.n_cuenta,
            inmobiliaria: af?.inmobiliaria,
            estado_civil: af?.estado_civil,
            idConyuge: af?.idConyuge,
            nomconyuge: af?.nomconyuge,
            tipo_doc_conyuge: af?.tipo_doc_conyuge,
            rep_legal: af?.rep_legal,
            tel_rep_legal: af?.tel_rep_legal,
            email_rep_legal: af?.email_rep_legal,
            razon_social:af?.datos_aaff?.razon_social,
            nombre:af?.datos_aaff?.nombre,
            apellidos:af?.datos_aaff?.apellidos,
            email1:af?.datos_aaff?.email1,
            email2:af?.datos_aaff?.email2,
            telefono1:af?.datos_aaff?.telefono1,
            telefono2:af?.datos_aaff?.telefono2,
            direccion:af?.datos_aaff?.direccion,
            provincia:af?.datos_aaff?.provincia,
            municipio:af?.datos_aaff?.municipio,
            cp:af?.datos_aaff?.cp,
            porAaff: af?.rHojaAaff?.porAaff,
            totalAaff: af?.rHojaAaff?.totalAaff,
        }));

        let new_data = {
            id: data?.id,
            accion: data?.accion,
            referencia: data?.referencia,
            id_sucursal: data?.id_sucursal,
            id_coor: data?.coor_hoja?.id,
            id_respo: data?.respo_hoja?.id,
            comisionTotal: data?.comisionTotal,
            coordinador: data?.coor_hoja,
            responsable: data?.respo_hoja,
            tipoCom: data?.tipoCom,
            comprador: comprador,
            vendedor: vendedor,
            comisionLae: data?.comisionLae,
            total: data?.total,
            sucursal: data?.su_sucursal,
            captador: data?.captador,
            agenteVendedor: data?.agenteVendedor,
            totalCaptador: data?.totalCaptador,
            totalComisionNeta: data?.totalComisionNeta,
            totalVendedor: data?.totalVendedor,
            fechaOperacion: data?.fechaOperacion,
            haya: data?.haya,
            vinculo: data?.vinculo,
            vinculo2: data?.vinculo2,
            vinculo3: data?.vinculo3,
            opCompartida: data?.opCompartida,
            id_agVendedor: data?.id_agVendedor,
            id_captador: data?.id_captador,
            id_comprador: data?.id_comprador,
            id_vendedor: data?.id_vendedor,
            porCoor: data?.porCoor,
            totalCoor: data?.totalCoor,
            porRespo: data?.porRespo,
            totalRespo: data?.totalRespo,
            observacion: data?.observacion,
            porCaptador: data?.porCaptador,
            porLae: data?.porLae,
            porVendedor: data?.porVendedor,
            colaboradores: colaboradores,
            aaff:aaff,
            propiedades: propiedades,
            estado: data?.estado
        };
        return new_data;
    };
    const fetchData = async ({ offset, limit, filtros, orderBy, order }) => {
        setLimite(limit);
        let payload;
        if (orderBy && order) {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit), orderBy: `${orderBy},${order}` },
                filtros
            };
        } else {
            payload = {
                params: { page: parseInt(offset), limit: parseInt(limit) },
                filtros
            };
        }
        try {
            setLoading(true);
            let response = await listHojasForm(payload);
            // console.log(response.data.data[0].su_sucursal.nombre_sucursal);
            const { meta, data } = response.data;
            // console.log(formatData(data)[0].sucursal.nombre_sucursal);

            // console.log(data)
            // console.log(formatData(data));

            setData({
                meta,
                rows: formatData(data),
            });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return {
        data,
        fetchData,
        loading,
        formatData,
        formatUnicData,
        limite
    };

};

export default useHojasForm;
import { 
    Menu,
    MenuItem
} from "@mui/material";

const styleMenuItem = {
    border: '2px solid #A69150', // Borde dorado
    borderRadius: '8px', // Bordes redondeados
    margin: '4px'
}
const HojaVerifyFormMenu = ({anchorEl, open, onClose, selectedValue, estadosHf,}) => {
    const handleClose = () => {
        onClose(null);
        // onClose();

    };

    const handleListItemClick = (value) => {
        onClose(value);
    };
    return(
        <>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {estadosHf.map((ehf, index)=> {
                    return(
                        <MenuItem 
                            sx={
                                ehf.value === selectedValue ? styleMenuItem:''
                            } 
                            key={index} 
                            onClick={()=>handleListItemClick(ehf.value)}
                        >
                            {ehf.name}
                        </MenuItem>
                    )
                })}
                

            </Menu>
           
        </>
    );
};

export default HojaVerifyFormMenu;
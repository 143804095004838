import { useEffect, useState } from "react";
import {
    TextField,
    InputAdornment,
    IconButton,
    Button,
    Box,
    MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import provinces from "@doncicuto/es-provinces";
import { findMostSimilarItem, normalizarProvincias } from "../../../utilities/validator-textos";
import municipalitiesWithProvinces from "../../../utilities/municipalitiesWithProvinces.json"; // Ruta al JSON

const HojaPropiedadForm = ({ propiedad, handleChange, handleBlur, inputStyles, index, formatNumbers, iconButtonStyles, cleanProps }) => {
    const [editinImporte, setEditingImporte] = useState(false);
    const [formattedImporte, setFormattedImporte] = useState(propiedad.importe || propiedad.precioinmo);
    const [provinceSelected, setProvinceSelected] = useState(propiedad.provincia ?? null);
    const [municipios, setMunicipios] = useState([]);
    const [loading, setLoading] = useState(false);
    const importe = propiedad.importe ? parseFloat(propiedad.importe) : 0; 
    const precioInmo = propiedad.precioinmo ? parseFloat(propiedad.precioinmo) : 0; 
    const handleChangeWrapper = (e) => {
        handleChange(e, propiedad, index);
    };
    const handleBlurWrapper = () => {
        handleBlur(propiedad, index);
        setEditingImporte(false)
     
        const valueToFormat = importe !== null ? importe : precioInmo;

        if (valueToFormat !== null) {
            const formattedValue = valueToFormat.toLocaleString('de-DE');
            setFormattedImporte(formattedValue);
        } else {
            setFormattedImporte(''); 
        }

    };
    const handleImporteFocus = () => {
        setEditingImporte(true)
        setFormattedImporte(importe || precioInmo);
    };
    console.log(propiedad);
    useEffect(() => {
        const selectedProvince = provinces.find((p) => p.name === normalizarProvincias(provinceSelected));
        
        if (provinceSelected) {
            setLoading(true);

            // Filtrar municipios basados en la provincia seleccionada
            const filteredMunicipalities = municipalitiesWithProvinces.filter(
                (m) => m.provCode == selectedProvince.code
            );

            setMunicipios(filteredMunicipalities);
            setLoading(false);
        } else {
            setMunicipios([]);
        }
    }, [provinceSelected]);
    useEffect(()=>{
        if(!editinImporte){
            const formatedValue = parseFloat(importe || precioInmo).toLocaleString("de-DE", {});
            setFormattedImporte(formatedValue)
        }
    },[editinImporte]);
    return (
        <Box display="flex" alignItems="center" sx={{ margin: '5px 0' }}>
            <Box sx={{ flexGrow: 1 }}>
                <TextField
                    onChange={(e) => handleChange(e, propiedad, index)}
                    label="Dirección"
                    size="small"
                    name={`direccion_propiedad_${index}`}
                    value={propiedad.direccion ?? propiedad.rHojafPropiedad?.direccion}
                    sx={{ ...inputStyles, maxWidth: '82%', margin: '5px 5px' }}
                    fullWidth
                />
                <TextField
                    id="demo-simple-select-label"
                    select
                    label="Provincia"
                    name={`provincia_propiedad_${index}`}
                    margin="normal"
                    sx={{ ...inputStyles, width: '35%', margin: '5px 5px' }}
                    value={normalizarProvincias(propiedad.provincia) ?? normalizarProvincias(propiedad.rHojafPropiedad?.provincia)}
                    size="small"
                    onChange={handleChangeWrapper}
                >
                    {provinces && <MenuItem value="">
                        <em>Ninguna</em>
                    </MenuItem>}
                    {provinces.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => (
                        <MenuItem key={index} value={item.name}>
                            {item.name}
                        </MenuItem>
                    ))}
                </TextField>
                {/* {console.log(propiedad)}
                {console.log(findMostSimilarItem(propiedad.municipio,municipios && municipios,"name"))} */}
                <TextField  
                    onChange={handleChangeWrapper}
                    label="Municipio"
                    size="small"
                    name={`municipio_propiedad_${index}`}
                    value={propiedad.municipio ?? propiedad.rHojafPropiedad?.municipio}
                    sx={{ ...inputStyles, maxWidth: '30%', margin: '5px 5px' }}
                    fullWidth
                >
                </TextField>

                <TextField
                    onChange={handleChangeWrapper}
                    label="CP"
                    size="small"
                    name={`cp_propiedad_${index}`}
                    value={propiedad.cp ?? propiedad.rHojafPropiedad?.cp}
                    sx={{ ...inputStyles, maxWidth: '15%', margin: '5px 5px' }}
                    fullWidth
                />

                <TextField
                    onChange={handleChangeWrapper}
                    label="Promoción"
                    size="small"
                    name={`promocion_propiedad_${index}`}
                    value={propiedad.promocion ?? propiedad.rHojafPropiedad?.promocion}
                    sx={{ ...inputStyles, maxWidth: '34%', margin: '5px 5px' }}
                    fullWidth
                />
                <TextField
                    onChange={handleChangeWrapper}
                    onBlur={handleBlurWrapper}
                    onFocus={handleImporteFocus}
                    label="Importe"
                    size="small"
                    inputProps={{style: {textAlign:'right'}}}
                    name={`importe_propiedad_${index}`}
                    value={editinImporte ? importe || precioInmo : formattedImporte}
                    // value={formattedImporte || propiedad.importe || propiedad.precioinmo}
                    sx={{ ...inputStyles, maxWidth: '34%', margin: '5px 5px' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment sx={{ paddingLeft: '1%' }} position="end">€</InputAdornment>
                        )
                    }}
                    fullWidth
                />

            </Box>
            <Box sx={{ marginLeft: '5px', marginBottom: '17%' }}>
                <Button sx={{ ...iconButtonStyles }} onClick={() => { cleanProps(index) }} size="small">
                    <DeleteIcon />
                </Button>
            </Box>
        </Box>
    );
}

export default HojaPropiedadForm;
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ErrorOutline } from '@mui/icons-material';
import { Justify } from 'react-bootstrap-icons';

export default function AlertDialog({ txtbtnOpentitle, title, description, txtBtnAceptar = "Aceptar", txtBtnCancelar = "Cancelar", onClickOk, disable }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (

        
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} sx={{
                color: "#ffffff",
                borderColor: "#fff",
                borderRadius: 10,
                backgroundColor: "#c4af6c",
                '&:hover': {
                    backgroundColor: "#000",
                    borderColor: "#000"
                }
            }}
            disabled={disable}
            >
                {txtbtnOpentitle}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px 0" }}>
                    <ErrorOutline sx={{ fontSize: 100, color: "#facea8", m: 0, p: 0 }} />
                </div>
                {/* <ErrorOutline sx={{ fontSize: 40, color: "#facea8" }} /> */}
                <DialogTitle id="alert-dialog-title" sx={{ display: "flex", justifyContent: "center", paddingTop: 0 }}>
                    {title}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{
                        // color: "#33383b",
                        fontSize: "1.2rem",
                        // padding: "10px",
                        // textAlign: "center"
                    }}>
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "center", gap: 0.6, marginBottom: "10px" }}>
                    <Button onClick={handleClose} sx={{
                        color: "#fff",
                        backgroundColor: "#c4af6c",
                        '&:hover': {
                            color: "#fff",
                            backgroundColor: "#000"
                        }
                    }}>{txtBtnCancelar}</Button>
                    <Button
                        onClick={() => {
                            if (onClickOk) {
                                onClickOk();  // Ejecuta la acción de onClickOk
                            }
                            handleClose(); // Luego cierra el diálogo
                        }}
                        sx={{
                            color: "#fff",
                            backgroundColor: "#c4af6c",
                            '&:hover': {
                                color: "#fff",
                                backgroundColor: "#000"
                            }
                        }} autoFocus>
                        {txtBtnAceptar}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

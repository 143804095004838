import axios from "axios";
const urlPath = "comisiones";

export const listComisiones = async (data) => {
    try {
        const response = await axios.post(`${urlPath}/list`, data.filtros, { params: data.params});

        return response.data;
    } catch (error) {
        console.log(error);
    }
};
import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, Divider } from '@mui/material';

// Componente para la tarjeta con las propiedades
const PropertyCard = ({
    title,
    propertyData,  // El objeto con las propiedades (clave-valor) que se va a renderizar
    width = 400,
    height = 'auto',
    cardColor = '#ffffff',
    textColor = '#000000',
    backgroundColor = '#33383b',
    borderRadius = '8px',
    titleFontSize = '1.5rem',
    propertyFontSize = '1.5rem',
}) => {
    return (
        <Card sx={{
            maxWidth: width,
            height: height,
            margin: '10px', // Separación entre tarjetas
            backgroundColor: cardColor,
            borderRadius: borderRadius
        }}>
            <CardContent>
                {/* Título dinámico */}
                <Typography variant="h5" gutterBottom sx={{ color: textColor, fontSize: titleFontSize }}>
                    {title}
                </Typography>

                <Divider sx={{ backgroundColor: "#33383b", height: "2px" }} />

                {/* Listado de propiedades (clave: valor) */}
                <List>
                    {Object.keys(propertyData).map((key, index) => (
                        <ListItem key={index} style={{ padding: 4 }}>
                            <ListItemText
                                primary={`${key}: ${propertyData[key]}`}
                                primaryTypographyProps={{
                                    style: { color: textColor, fontSize: propertyFontSize, padding: 0, margin: 0 }
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

// // Función para formatear la fecha
// const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString('es-ES', {
//         day: '2-digit',
//         month: '2-digit',
//         year: 'numeric'
//     });
// };

// Ejemplo de uso del componente reutilizable
const BasicCard = ({ data }) => {

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',  // Organizar de izquierda a derecha
            flexWrap: 'wrap',      // Hacer que las tarjetas se envuelvan si no caben en una fila
            justifyContent: 'flex-start', // Alinear tarjetas al principio
            gap: '10px'            // Espacio entre las tarjetas
        }}>
            {data.map((item, index) => (
                <PropertyCard
                    key={index}
                    title={`Referencia ${item.referencia}`}
                    propertyData={{
                        'Tipo': item?.accion == 'COMPRA_VENTA' ? 'Compra-Venta' : item?.accion,
                        'Total Propiedad': item.total.toLocaleString('de-DE') + ' €',
                        // 'Total Captador': item.totalCaptador.toLocaleString('de-DE') + ' €',
                        // 'Total Vendedor': item.totalVendedor.toLocaleString('de-DE') + ' €',
                        'Total Comisión': item?.valor.toLocaleString('de-DE') + ' €',
                        'Fecha de Operación': new Date(item.fechaOperacion).toLocaleDateString('es-ES', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        }),
                        // 'Total Comisión Neta': item.totalComisionNeta.toLocaleString('es-ES') ,
                    }}
                    width={270} // Mínimo ancho dinámico
                    height={250}
                    cardColor={item.accion === 'ALQUILER'
                        ? '#DBF1E4'
                        : item.accion === 'COMPRA_VENTA'
                            ? '#C4DDFF'
                            : item.accion === 'ALQ_OPCION_COMPRA'
                                ? '#DBF1E4'
                                : item.accion === 'TRASPASO'
                                    ? '#C4DDFF'
                                    : '#FCF6F6'}// Color de fondo personalizado
            textColor="#333" // Color del texto personalizado
            borderRadius="16px" // Borde redondeado
            titleFontSize="1.1rem" // Tamaño de fuente del título
            propertyFontSize="0.9rem" // Tamaño de fuente de las propiedades
                />
            ))}
        </div>
    );
};

export default BasicCard;

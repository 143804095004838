import React, { useContext, useState } from "react";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import { useEffect } from "react";
import { SyncLoader } from "react-spinners";
import { deleteTrabajador, listTrabajadores } from "../../services";
import { DataTable } from "../../components";
import {
    Typography,
} from "@mui/material";
import { dynamicTableHeader} from "../../utilities/transformHeaders";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackbarUtilities } from "../../utilities";
import { useNavigate, Link } from "react-router-dom";
import useTrabajadores from "../../hooks/useTrabajadores";
import { UserContext } from '../../hooks/UserContext'; // Importa el contexto
import { useFormValidation } from "../../utilities/formValidator";
import * as Yup from 'yup';
import { listSucursales, listDepartamentos } from "../../services";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { FaSearch } from "react-icons/fa";
import { MdCleaningServices } from "react-icons/md";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SearchIcon from '@mui/icons-material/Search';
import UserProfile from "./TrabajadorInfoCard";
import { useUser } from "../../hooks/UserContextLogin";
import ButtonPermission from "../../utilities/ButtonPermission";
import { verificarPermisosAcciones } from "../../utilities/permissionHelper";
import {
    Button, TextField, Box, MenuItem, InputAdornment, Modal, FormControlLabel, FormControl, InputLabel, Select, OutlinedInput, Chip
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "65%",
    height: "88%",
    bgcolor: 'background.paper',

    p: 4,
};

const Trabajadores = () => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const theme = useTheme();

    const { data, fetchData, loading, limite, dataCustomNames } = useTrabajadores();
    const [currentPage, setCurrentPage] = useState(1);
    const { usuario } = useContext(UserContext); // Accede al contexto del usuario
    const [sucursalMap, setSucursalMap] = useState(null);
    const [departamentosList, setDepartamentosList] = useState(null);
    const [filtros, setFiltros] = useState();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { user, setUser } = useUser();

    const initialValues = {
        sucursal_id: [],
        departamento_id: '',
        buscador: '',
        active: true,
    };

    const validationSchema = Yup.object().shape({
        sucursal_id: Yup.array().nullable(),
        departamento_id: Yup.string().nullable(),
        buscador: Yup.string().nullable(),
        active: Yup.boolean().nullable(),
    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setValues, handleReset } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (values) {
                fetchData({ offset: currentPage, limit: 1000, filtros: values });
            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    const iconColumn = []

    if (!user?.rol?.permisos?.tablas['Trabajadores']?.includes('Verificado')) {
        iconColumn.push(
            {
                item: (name) => {
                    // console.log(name);

                    return (
                        <Typography
                            sx={{

                                color: "#33383b !important",
                                "&:hover": { color: "#33383b" },
                                "&:active": { color: "#33383b" },
                                fontSize: "0.9rem",
                                fontWeight: "1rem"
                            }}
                        >
                            <div style={{ marginLeft: "30px" }}>{name?.verified ? <CheckIcon sx={{ color: "green", fontSize: 38 }} /> : <CloseIcon sx={{ color: "red", fontSize: 38 }} />}</div>
                        </Typography>
                    )
                },
                position: 8,
                titleHeader: "Verificado",
                nameColumn: "verified"
            }
        )
    }
    if (!user?.rol?.permisos?.tablas['Trabajadores']?.includes('Acciones')) {
        // console.log("ENTRÓ");
        iconColumn.push({
            item: (name) => {
                return (
                    <div style={{ display: "flex", justifyContent: "left" }}>
                        {verificarPermisosAcciones(['edit', 'delete', 'all'], user, 'Trabajadores', name.numsucursal, name, deleteItem, "/trabajadores/form/")}
                    </div>
                )
            },
            position: 9,
            titleHeader: "Acciones",
            nameColumn: "",
            order: "disable",
        });
    }
    const navigate = useNavigate();

    const deleteItem = async (id) => {
        try {
            const response = await deleteTrabajador(id);
            if (response == 200) {
                SnackbarUtilities.success("Trabajador eliminado correctamente!");
                fetchData({ offset: currentPage, limit: 1000 });
                // Aquí puedes agregar cualquier lógica adicional, como actualizar la lista de trabajadores
            } else {
                SnackbarUtilities.error("Error al eliminar el trabajador. Inténtalo de nuevo más tarde.");
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleClick = async () => {
        navigate("/Trabajadores/form");
    }
    const fetchSucursales = async () => {
        const response = await listSucursales({
            offset: 1, limit: 1000,
            params: {
                orderBy: 'nombre_sucursal,ASC',
            },
        });
        setSucursalMap(response.data || []);
    };
    const fetchDepartamentos = async () => {
        const response = await listDepartamentos({ offset: 1, limit: 1000 });
        setDepartamentosList(response.data.sort((a, b) => a.nombre.localeCompare(b.nombre)) || []);
    };
    useEffect(() => {
        fetchSucursales();
        fetchDepartamentos();
    }, []);
    // console.log(Trabajadores);
    const accordiondata = (item) => {
        return (<UserProfile UserInfo={item.id}></UserProfile>)
    }
    return (
        <div className="container">
            {/* <BreadCrumb /> */}
            <div className="topline">
                <div>
                    <h3>Trabajadores</h3>
                </div>
                <div>
                    <ButtonPermission onClick={handleClick} variant="contained" endIcon={<NoteAddIcon />}
                        action="create"
                        resource="Trabajadores"
                        sx={{
                            color: '#c4af6c',
                            backgroundColor: '#33383b',
                            height: 40,
                            marginBottom: 1,
                            borderColor: 'transparent',
                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}>
                        Crear
                    </ButtonPermission>
                </div>
            </div>

            <form onSubmit={handleSubmit}>

                <div className="middleline">
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: { sm: '1fr 1fr' },
                                gap: 2,
                            }}
                        >
                            <div>
                                <TextField
                                    id="input-with-icon-textfield"
                                    label="Buscador"
                                    margin="normal"
                                    name="buscador"
                                    sx={inputStyles}
                                    size="small"
                                    value={values.buscador}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.buscador && Boolean(errors.buscador)}
                                    helperText={touched.buscador && errors.buscador}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <div>
                                <FormControl sx={inputStyles} size="small">
                                    <InputLabel id="demo-multiple-chip-label3">Oficinas</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label3"
                                        id="demo-multiple-chip-label3"
                                        multiple
                                        name="sucursal_id"
                                        sx={inputStyles}
                                        margin="normal"
                                        value={values.sucursal_id}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="select-multiple-chip-label" label="Sucursales" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={sucursalMap && sucursalMap.find(worker => worker.id === value)?.nombre_sucursal || 'Nombre no encontrado'} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                        onBlur={handleBlur}
                                        error={touched.sucursal_id && Boolean(errors.sucursal_id)}
                                        helperText={touched.sucursal_id && errors.sucursal_id}
                                    >
                                        {sucursalMap ? sucursalMap.map((worker) => (
                                            <MenuItem
                                                key={worker.id}
                                                value={worker.id}
                                                style={getStyles(worker.id, values.sucursal_id, theme)}
                                            >
                                                {worker.nombre_sucursal ?? "LAE HOMES - " + worker.municipio}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                    {touched.sucursal_id && errors.sucursal_id && (
                                        <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                                            {errors.sucursal_id}
                                        </div>
                                    )}
                                </FormControl>
                            </div>
                            <div>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Selecciona Departamento"
                                    size="small"
                                    name="departamento_id"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.departamento_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.departamento_id && Boolean(errors.departamento_id)}
                                    helperText={touched.departamento_id && errors.departamento_id}
                                >
                                    {departamentosList &&
                                        <MenuItem value="">
                                            <em>Ninguno</em>
                                        </MenuItem>
                                    }

                                    {departamentosList ? departamentosList.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.nombre}
                                        </MenuItem>
                                    )) : null}
                                </TextField>
                            </div>
                            {
                                user.rol.permisos.customViews?.Trabajadores?.includes('View Trabajadores Active') && <div>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        label="Trabajadores Activos"
                                        size="small"
                                        name="active"
                                        margin="normal"
                                        sx={inputStyles}
                                        value={values.active}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.active && Boolean(errors.active)}
                                        helperText={touched.active && errors.active}
                                    >
                                        {[{ name: "SÍ", key: true }, { name: "NO", key: false }].map((option, index) => (
                                            <MenuItem key={index} value={option.key}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            }

                            <div style={{ display: "flex", flex: 0.5, justifyContent: "start", gap: '10px' }}>

                                <Button
                                    type="submit"
                                    variant="outlined"
                                    sx={{
                                        color: '#c4af6c',
                                        backgroundColor: '#33383b',
                                        height: 40,
                                        borderColor: 'transparent',
                                        '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                                    }}
                                >
                                    <FaSearch style={{ color: '#c4af6c', '&:hover': { color: '#33383b' } }} />
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        color: '#33383b',
                                        backgroundColor: '#c4af6c',
                                        borderColor: 'transparent',
                                        '&:hover': { color: '#c4af6c', backgroundColor: '#fff', borderColor: '#33383b', }
                                    }}
                                    onClick={() => {
                                        fetchData({
                                            offset: currentPage, limit: 1000, params: {
                                                orderBy: 'nombre,ASC',
                                            }
                                        });
                                        handleReset();
                                    }}                        >
                                    <MdCleaningServices style={{ color: '#33383b', '&:hover': { color: '#c4af6c' } }} />
                                </Button>
                            </div>
                        </Box>

                    </div>

                </div>

            </form>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                        Text in a modal
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </Typography> */}
                </Box>
            </Modal>
            {
                data && (
                    <DataTable
                        data={data}
                        fetcher={fetchData}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        mensaje={"No hay trabajadores disponibles"}
                        isLoading={loading}
                        orderTable={"ASC"}
                        orderTableBy={"nombre"}
                        // filters={values}
                        // onClickRow={(row)=>console.log(row.id)}
                        accordionData={accordiondata}
                        filtros={values}
                        headers={dynamicTableHeader({ headerArrayData: dataCustomNames, showJustThis: ["Nombre", "Apellidos", "Email", "Teléfono Personal", "Teléfono Empresarial"], addNewCustomHeader: iconColumn })}
                    />
                )
            }


        </div>
    );
}

export default Trabajadores;

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};
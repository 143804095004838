import React, { useState } from "react";
import {
    ImageList,
    ImageListItem,
    Dialog,
    Box
} from '@mui/material';
const Multimedia = ({ property }) => {
    const { fotos } = property;
    const [showModal, setShowModal] = useState(false);
    const [image, setImage] = useState('');
    const handleClicOpen = (url) => {
        setImage('');
        setShowModal(!showModal);
        setImage(url);
    };
    return (
        <>
            <div className="contenido">
                <div className="header">
                    <h2>Imágenes</h2>
                </div>
                <hr />
                {(fotos && fotos?.length !== 0) ?
                    <>
                        <ImageList sx={{ width: '100%', height: 600 }} variant="quilted" cols={4} rowHeight={164}>
                            {fotos.map(foto => {
                                return (
                                    <>
                                        <ImageListItem key={foto.id} onClick={() => { handleClicOpen(foto.url) }}>
                                            <img
                                                srcSet={`${foto.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                src={`${foto.url}?w=164&h=164&fit=crop&auto=format`}
                                                // alt={item.title}
                                                loading="lazy"
                                            />
                                        </ImageListItem>

                                    </>
                                );
                            })}
                        </ImageList>
                        <Dialog onClick={handleClicOpen} open={showModal}>
                            <img
                                srcSet={`${image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${image}?w=164&h=164&fit=crop&auto=format`}
                                // alt={item.title}
                                loading="lazy"
                            />
                            {/* <img src={`${foto.url}`} onClick={handleClicOpen}/> */}
                        </Dialog>
                    </>
                    :
                    <>{'¡No hay fotos publicadas!'}</>


                }
            </div>
            <div className="contenido">
                <div className="header">
                    <h2>Tour/Video</h2>
                </div>
                <hr />
                <Box style={{ display: 'flex' }}>
                    {property.tour && (
                        <iframe
                            title="Tour"
                            width="50%"
                            height="400px"
                            src={property.tour}
                            allowFullScreen
                            style={{ marginRight: '10px' }}
                        />
                    )}

                    {property.video1_url && (
                        <iframe
                            title="Video"
                            width="50%"
                            height="400px"
                            src={property.video1_url}
                            allowFullScreen
                        />
                    )}
                </Box>
            </div>

        </>
    );
};

export default Multimedia;

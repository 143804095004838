import React, { useContext, useState, useEffect } from "react";
import { SnackbarUtilities } from "../../utilities";
import { useFormValidation } from "../../utilities/formValidator";
import * as Yup from 'yup';
import { listSucursales } from "../../services/sucursales.service.js";
import { DataTable } from "../../components";
import { dynamicTableHeader } from "../../utilities/transformHeaders.js";
import useComisiones from "../../hooks/useComisiones.jsx";
import {
    Button, TextField, Box, MenuItem, FormControlLabel, FormControl, InputLabel, Select, OutlinedInput, Chip,
    Typography
} from "@mui/material";
import { CleaningServices, Search } from '@mui/icons-material';
import ExtraTableData from "./ExtraTableData.jsx";
import { useUser } from "../../hooks/UserContextLogin.js";
import ResumenComisiones from "./ResumenComisiones.jsx";
import { useTheme } from '@mui/material/styles';
import { listTrabajadores } from "../../services/trabajadores.service.js";


export default function Comisiones() {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const theme = useTheme();
    const [sucursalMap, setSucursalMap] = useState(null);
    const [perfilesList, setPerfilesList] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const { loading, limite, data, fetchData, dataCustomNames } = useComisiones();
    const { user, setUser } = useUser();
    const [filtro, setFiltro] = useState({ sucursal_id: user?.rol?.permisos?.customViews['Comisiones']?.includes('View Oficinas') ? user?.usuarioDatos?.sucursal_id : null});
    const [trabajadoresList, setTrabajadoresList] = useState(null);
    // console.log(user?.usuarioDatos);
    // console.log(user?.rol?.permisos?.customViews);
    
    const initialValues = {
        fechaDesde: '',
        fechaHasta: '',
        // sucursal_id: user?.rol?.permisos?.customViews['Comisiones']?.includes('View Oficinas') ? user?.usuarioDatos?.oficinas_asignadas?.map((item)=>item.id) : [],
        sucursal_id: user?.rol?.permisos?.customViews['Comisiones']?.includes('View Oficinas') ? [user?.usuarioDatos?.sucursal_id]: [],
        id_trabajador_comision: user?.rol?.permisos?.customViews['Comisiones']?.includes('View Suyas') ? [user?.usuarioDatos?.id]: [],
    };
    
    const validationSchema = Yup.object().shape({
        fechaDesde: Yup.string()
            .nullable(),
        fechaHasta: Yup.string()
            .nullable(),
        sucursal_id: Yup.array().nullable(),
        id_trabajador_comision: Yup.array().nullable(),
        
    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleReset, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        nullConverter: true,
        onSubmit: async (values) => {
            let { fechaDesde, fechaHasta, ...newValues } = values;
            if (fechaDesde && fechaHasta) {
                newValues.fecha = {
                    fechaDesde,
                    fechaHasta,
                };
            }
            if (newValues.id_trabajador_comision.length == 0) {
                delete newValues.id_trabajador_comision;
            }
            if (newValues.sucursal_id == "Todas") {
                newValues.sucursal_id = null;
            }
            // newValues = { ...values, ...newValues }

            if (newValues) {
                fetchData({ offset: currentPage, limit: 50, filtros: newValues });
            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    const fetchSucursales = async () => {
        const response = await listSucursales({
            offset: 1, limit: 50, filtros: { no_off_central: 10 },
            params: {
                orderBy: 'nombre_sucursal,ASC',
            },
        });
        setSucursalMap(response.data || []);
    };

    const fetchTrabajador = async (id_sucursal = null) => {
        const response = await listTrabajadores({ offset: 1, limit: 1000, filtros: { sucursal_id: id_sucursal, user_rol: [1, 2, 21] } });
        setTrabajadoresList(response.data.sort((a, b) => a.nombre.localeCompare(b.nombre)) || []);
    }
    useEffect(() => {
        fetchSucursales();
        fetchTrabajador();
    }, []);
    useEffect(() => {
        // console.log(values.sucursal_id);

        if (values?.sucursal_id?.length > 0) {
            // console.log("entroo");            
            setCustomFieldValue("id_trabajador_comision", [])
            fetchTrabajador(values.sucursal_id);
        }
    }, [values.sucursal_id]);

    const accordiondata = (item) => {
        return (<ExtraTableData UserInfo={item.hoja_formalizacion_data}></ExtraTableData>)
    }

    const iconColumn = [
        {
            item: (name) => {
                // console.log(name);                
                return (
                    <div style={{ display: "flex", justifyContent: "flex-end", width: 120 }}>
                        <Typography  >
                            {name.total_comision.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                        </Typography>
                    </div>
                )
            },
            position: 3,
            titleHeader: "Total Comisión",
            nameColumn: "total_comision"
        },
        {
            item: (name) => {
                return (
                    <div style={{ display: "flex", justifyContent: "flex-end", width: 120 }}>
                        <Typography  >
                            {name.numero_hoja}
                        </Typography>
                    </div>
                )
            },
            position: 4,
            titleHeader: "Número Hoja Formalización",
            nameColumn: "numero_hoja"
        }
    ];

    return (
        <div className="container">
            {/* <BreadCrumb /> */}
            <div className="topline">
                <div>
                    <h3>Comisiones</h3>
                </div>
                {
                    (process.env.NODE_ENV == "development" && Object.keys(errors).length > 0) &&
                    <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                        {JSON.stringify(errors)}
                    </div>
                }
            </div>
            <form onSubmit={handleSubmit}>

                <div className="middleline">
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <ResumenComisiones data={data}></ResumenComisiones>

                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: { sm: '1fr 1fr' },
                                gap: 2,
                            }}
                        >
                            <div>
                                <FormControl sx={inputStyles} size="small">
                                    <InputLabel id="demo-multiple-chip-label3">Oficinas</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label3"
                                        id="demo-multiple-chip-label3"
                                        multiple
                                        disabled={user?.rol?.permisos?.customViews['Comisiones']?.some(view => ['View Oficinas', 'View Suyas'].includes(view)) ? true : false}
                                        name="sucursal_id"
                                        sx={inputStyles}
                                        margin="normal"
                                        value={values.sucursal_id}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="select-multiple-chip-label" label="Sucursales" />}
                                        renderValue={(selected) => (                                            
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={sucursalMap && sucursalMap.find(worker => worker.id === value)?.nombre_sucursal || 'Nombre no encontrado'} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                        onBlur={handleBlur}
                                        error={touched.sucursal_id && Boolean(errors.sucursal_id)}
                                        helperText={touched.sucursal_id && errors.sucursal_id}
                                    >
                                        {sucursalMap ? sucursalMap.map((worker) => (
                                            <MenuItem
                                                key={worker.id}
                                                value={worker.id}
                                                style={getStyles(worker.id, values.sucursal_id, theme)}
                                            >
                                                {worker.nombre_sucursal ?? "LAE HOMES - " + worker.municipio}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                    {touched.sucursal_id && errors.sucursal_id && (
                                        <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                                            {errors.sucursal_id}
                                        </div>
                                    )}
                                </FormControl>
                            </div>

                            <div>
                                <FormControl sx={inputStyles} size="small">
                                    <InputLabel id="demo-multiple-chip-label3">Trabajadores</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label3"
                                        id="demo-multiple-chip-label3"
                                        // disabled={(trabajadoresList ? false : true)}
                                        disabled={user?.rol?.permisos?.customViews['Comisiones']?.some(view => ['View Suyas'].includes(view)) ? true : false}
                                        multiple
                                        name="id_trabajador_comision"
                                        sx={inputStyles}
                                        margin="normal"
                                        value={values.id_trabajador_comision}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="select-multiple-chip-label" label="Departamentos" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={trabajadoresList && trabajadoresList.find(worker => worker.id === value)?.nombre || 'Nombre no encontrado'} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                        onBlur={handleBlur}
                                        error={touched.id_trabajador_comision && Boolean(errors.id_trabajador_comision)}
                                        helperText={touched.id_trabajador_comision && errors.id_trabajador_comision}
                                    >
                                        {trabajadoresList ? trabajadoresList.map((worker) => (
                                            <MenuItem
                                                key={worker.id}
                                                value={worker.id}
                                                style={getStyles(worker.id, values.id_trabajador_comision, theme)}
                                            >
                                                {worker.nombre + " " + worker.apellidos + " - " + worker.cargo}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                    {touched.id_trabajador_comision && errors.id_trabajador_comision && (
                                        <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                                            {errors.id_trabajador_comision}
                                        </div>
                                    )}
                                </FormControl>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", gap: 6 }}>
                                <TextField
                                    sx={inputStyles}
                                    label="Inicio"
                                    name="fechaDesde"
                                    type="date"
                                    size="small"
                                    margin="normal"
                                    value={values.fechaDesde}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.fechaDesde && Boolean(errors.fechaDesde)}
                                    helperText={touched.fechaDesde && errors.fechaDesde}
                                />
                                <TextField
                                    sx={inputStyles}
                                    label="Fin"
                                    name="fechaHasta"
                                    type="date"
                                    size="small"
                                    margin="normal"
                                    value={values.fechaHasta}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.fechaHasta && Boolean(errors.fechaHasta)}
                                    helperText={touched.fechaHasta && errors.fechaHasta}
                                />
                            </div>
                            <div style={{ display: "flex", flex: 0.5, justifyContent: "start", gap: '10px' }}>

                                <Button
                                    type="submit"
                                    variant="outlined"
                                    sx={{
                                        color: '#c4af6c',
                                        backgroundColor: '#33383b',
                                        height: 40,
                                        borderColor: 'transparent',
                                        '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                                    }}
                                >
                                    <Search style={{ fontSize: "1.3rem", '&:hover': { color: '#33383b' } }} />
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        color: '#33383b',
                                        backgroundColor: '#c4af6c',
                                        borderColor: 'transparent',
                                        '&:hover': { color: '#c4af6c', backgroundColor: '#fff', borderColor: '#33383b', }
                                    }}
                                    onClick={() => {
                                        handleReset();
                                        fetchData({ offset: currentPage, limit: 1000, filtros: initialValues });
                                        fetchTrabajador();
                                    }}                        >
                                    <CleaningServices style={{ color: '#33383b', fontSize: "1.3rem", '&:hover': { color: '#c4af6c' } }} />
                                </Button>
                            </div>
                        </Box>

                    </div>

                </div>

            </form>
            <DataTable
                data={data}
                headers={dynamicTableHeader({
                    headerArrayData: dataCustomNames,
                    showJustThis: ['Nombre', 'Apellidos'],
                    addNewCustomHeader: iconColumn
                })}
                orderTable={"ASC"}
                orderTableBy={"nombre"}
                accordionData={accordiondata}
                isLoading={loading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                fetcher={fetchData}
                mensaje={"No hay administradores de comisiones disponibles"}
                filtros={values}
                height="100%"
            />

        </div>

    )
}

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};
import React, { useEffect, useState } from 'react';
import { Box, Avatar, Typography, List, ListItem, ListItemText, Paper, Tabs, Tab, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { getTrabajador } from '../../services';
import { getUrlFromString } from '../../utilities/img_url';
import { formatDate } from '../../utilities';
import { useUser } from "../../hooks/UserContextLogin";
import { useLocation } from 'react-router-dom';

const Sidebar = styled(Box)({
    backgroundColor: '#33383b',
    color: 'white',
    height: '100%',
    width: '40vh',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

const Content = styled(Box)({
    height: '100%',
    paddingRight: '20px',
    paddingLeft: '20px',
    flex: 1,
});

const Section = styled(Paper)({
    height: "100%"
    // padding: '20px',
    // paddingInline:'20px',
    // border:'2px solid red',
    // paddingRight:'20em',
    // paddingLeft:'20em'
});

const UserProfile = ({ UserInfo }) => {
    const loc = useLocation();
    const isOficinasPath = loc.pathname.includes('Oficinas');
    const [selectedTab, setSelectedTab] = useState("trabajador"); // Inicializa con un identificador
    const [UserData, setUserData] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const { user, setUser } = useUser();
    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    // console.log(isOficinasPath)
    useEffect(() => {
        const getUserInfo = async () => {
            try {

                const user = await getTrabajador(UserInfo);
                // console.log(user);
                setImageFile(getUrlFromString(user.data.url_profile_img, user));
                setUserData(user.data);
            } catch (error) {
                console.error('Failed to fetch user data', error);
            } finally {
                setLoading(false);
            }
        };

        if (UserInfo) {
            getUserInfo();
        }
    }, [UserInfo]);

    const renderContent = () => {
        switch (selectedTab) {
            case "trabajador":
                return (
                    <Section>
                        <Typography variant="h5" gutterBottom align='center' mb={1.5} mt={0} p={1.5} bgcolor={"#33383b"} color={"white"}>
                            Información del trabajador
                        </Typography>
                        <Box display="flex" flexDirection="row" gap={3} px={1.5} pt={2} >
                            <Box display="flex" flexDirection="column" justifyContent="space-between" mb={1.5} p={1.5} width={'100%'}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Nombre</Typography>
                                    <Typography variant="body1">{UserData?.nombre}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Apellidos</Typography>
                                    <Typography variant="body1">{UserData?.apellidos}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Cargo</Typography>
                                    <Typography variant="body1">{UserData?.cargo}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Email</Typography>
                                    <Typography variant="body1">{UserData?.email}</Typography>
                                </Box>
                            </Box>

                            <Box display="flex" flexDirection="column" mb={1.5} p={1.5} width={'100%'}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Teléfono Personal</Typography>
                                    <Typography variant="body1">{UserData?.telefonoPersonal}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Teléfono Empresa</Typography>
                                    <Typography variant="body1">{UserData?.telefono}</Typography>
                                </Box>

                                {user?.rol?.permisos?.customViews['Trabajadores']?.includes('View fechaNacimiento') && <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Fecha de Nacimiento</Typography>
                                    <Typography variant="body1">{formatDate(UserData?.fechaNac)}</Typography>
                                </Box>
                                }
                                {user?.rol?.permisos?.customViews['Trabajadores']?.includes('View DNI') &&
                                    <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                        <Typography variant="body1">DNI</Typography>
                                        <Typography variant="body1">{UserData?.dni}</Typography>
                                    </Box>
                                }

                            </Box>
                        </Box>
                    </Section>
                );
            case "oficina":
                return (
                    <Section>
                        <Typography variant="h5" gutterBottom align='center' mb={1.5} mt={0} p={1.5} bgcolor={"#33383b"} color={"white"}>
                            Información de la Oficina
                        </Typography>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" gap={3} px={1.5} pt={2} >
                            <Box display="flex" flexDirection="column" mb={1.5} p={1.5} width={'100%'}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Nombre</Typography>
                                    <Typography variant="body1">{UserData?.trabajador_sucursal?.nombre_sucursal}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Provincia</Typography>
                                    <Typography variant="body1">{UserData?.trabajador_sucursal?.provincia}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Municipio</Typography>
                                    <Typography variant="body1">{UserData?.trabajador_sucursal?.municipio}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Dirección</Typography>
                                    <Typography variant="body1">{UserData?.trabajador_sucursal?.direccion}</Typography>
                                </Box>
                            </Box>

                            <Box display="flex" flexDirection="column" mb={1.5} p={1.5} width={'100%'}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Teléfono</Typography>
                                    <Typography variant="body1">{UserData?.trabajador_sucursal?.telefono}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Código postal</Typography>
                                    <Typography variant="body1">{UserData?.trabajador_sucursal?.cp}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Email</Typography>
                                    <Typography variant="body1">{UserData?.trabajador_sucursal?.email}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                    <Typography variant="body1">Tipo</Typography>
                                    <Typography variant="body1">{UserData?.trabajador_sucursal?.tipo}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Section>
                );
            case "departamento":
                return (
                    <Section>
                        <Typography variant="h5" gutterBottom align='center' mb={1.5} mt={0} p={1.5} bgcolor={"#33383b"} color={"white"}>
                            Información del Departamento
                        </Typography>
                        {
                            (UserData?.trabajador_departamento.lenght == 0) ? <Box display="flex" flexDirection="row" justifyContent="space-between" gap={3} px={1.5} pt={2}>
                                <Box display="flex" flexDirection="column" mb={1.5} p={1.5} width={'100%'}>
                                    {UserData?.trabajador_departamento.map((item, index) => {
                                        // console.log(index);

                                        if (index < 3) {
                                            return (
                                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                                    <Typography variant="body1">Departamento</Typography>
                                                    <Typography variant="body1">{item.nombre}</Typography>
                                                </Box>
                                            )
                                        }

                                    })}
                                </Box>
                                <Box display="flex" flexDirection="column" mb={1.5} p={1.5} width={'100%'}>
                                    {UserData?.trabajador_departamento.map((item, index) => {
                                        if (index > 3) {
                                            return (
                                                <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                                    <Typography variant="body1">Departamento</Typography>
                                                    <Typography variant="body1">{item.nombre}</Typography>
                                                </Box>
                                            )
                                        }

                                    })}
                                </Box>
                            </Box>
                                :
                                <Box display="flex" justifyContent={"center"} mb={1.5} p={1.5} width={'100%'}>
                                    <Typography>No hay departamentos asociados</Typography>
                                </Box>
                        }
                        <Box display="flex" flexDirection="row" justifyContent="space-between" gap={3} px={1.5} pt={2}>
                            <Box display="flex" flexDirection="column" mb={1.5} p={1.5} width={'100%'}>
                                {UserData?.trabajador_departamento.map((item, index) => {
                                    // console.log(index);

                                    if (index < 3) {
                                        return (
                                            <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                                <Typography variant="body1">Departamento</Typography>
                                                <Typography variant="body1">{item.nombre}</Typography>
                                            </Box>
                                        )
                                    }

                                })}
                            </Box>
                            <Box display="flex" flexDirection="column" mb={1.5} p={1.5} width={'100%'}>
                                {UserData?.trabajador_departamento.map((item, index) => {
                                    if (index > 3) {
                                        return (
                                            <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor={"rgba(0, 0, 0, 0.04)"} mb={2} p={2}>
                                                <Typography variant="body1">Departamento</Typography>
                                                <Typography variant="body1">{item.nombre}</Typography>
                                            </Box>
                                        )
                                    }

                                })}
                            </Box>
                        </Box>
                    </Section>
                );
            default:
                return null;
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box display="flex" height="400px">
            <Sidebar >
                <Avatar
                    alt="Profile Picture"
                    src={imageFile ? imageFile : "/broken-image.jpg"}
                    sx={{ width: 120, height: 120, marginBottom: '20px' }}
                />
                <Typography variant="h6" gutterBottom align='center' mb={2}>
                    {UserData?.nombre} {UserData?.apellidos}
                </Typography>
                <Tabs
                    orientation="vertical"
                    value={selectedTab}
                    onChange={handleChange}
                    indicatorColor="fff"
                    aria-label="User profile tabs"
                    sx={{
                        borderRight: 1,
                        width: '100%',
                        borderColor: 'divider',
                        '& .MuiTab-root': {
                            color: '#ffffff', // Cambiar color del texto
                            backgroundColor: '#33383b', // Cambiar color de fondo
                            '&.Mui-selected': {
                                color: '#c4af6c', // Cambiar color del texto seleccionado
                                backgroundColor: '#444', // Cambiar color de fondo seleccionado
                            }
                        },
                    }}
                >
                    <Tab label="Trabajador" value="trabajador" sx={{ fontSize: 15 }} />
                    {/* <Tab label="Oficina" value="oficina" sx={{ fontSize: 15 }} /> */}
                    {!isOficinasPath && <Tab label="Oficina" value="oficina" sx={{ fontSize: 15 }} />}
                    <Tab label="Departamento" value="departamento" sx={{ fontSize: 15 }} />
                </Tabs>
            </Sidebar>
            <Content >
                {renderContent()}
            </Content>
        </Box>
    );
};

export default UserProfile;

import { useState } from 'react';

function useDialog() {
    const [dialogConfig, setDialogConfig] = useState({
        open: false,
        title: "",
        description: "",
        txtBtnAceptar: "Aceptar",
        txtBtnCancelar: "Cancelar",
        onClickOk: null
    });

    const openDialog = ({ title, description,txtBtnAceptar,txtBtnCancelar, onClickOk }) => {
        setDialogConfig({
            open: true,
            title,
            description,
            txtBtnAceptar,
            txtBtnCancelar,
            onClickOk
        });
    };

    const closeDialog = () => {
        setDialogConfig((prevConfig) => ({ ...prevConfig, open: false }));
    };

    return [dialogConfig, openDialog, closeDialog];
}

export default useDialog;

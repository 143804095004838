import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    PencilFill,
    TrashFill,
} from "react-bootstrap-icons";


//Comprueba si existe el permiso de ver o editar o eliminar de una sección
export const checkSectionViewPermision = (user, action, resource) => {
    return user?.rol?.permisos?.acciones?.some(permission =>
        permission.action === action && permission.resource === resource
    );
}

export const getPermissionProperties = (editarNormal, user_sucursal_id, sucursal_id, user) => {
    // console.log(editarNormal,user_sucursal_id,sucursal_id);
    const checkEditar = user.rol?.permisos?.tablas?.Propiedades?.includes("Editar");
    // console.log(checkEditar);
    let result = null;
    if (editarNormal == true) {
        return result = false;
    } else if (checkEditar == true && sucursal_id == user_sucursal_id) {
        return result = false;
    } else {
        return result = true;
    }
}
//Añadir boton eliminar o editar acciones segun permisos edit,delete,view, all
export const verificarPermisosAcciones = (acciones, userPermision, permissionResourceName, sucursal_id, name, deleteItem, editUrl) => {

    const actionButtons = {
        edit: (name) => (
            <Link to={`${editUrl}${permissionResourceName == "Propiedades" ? name.referencia : name.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark">
                <EditIcon />
            </Link>
        ),
        delete: (name, deleteItem) => (
            deleteItem ?
                <button className="btn btn-outline-dark" onClick={() => deleteItem(name.id)} style={{ marginLeft: "20px" }}>
                    <DeleteIcon />
                </button>
                : <></>
        ),
        all: (name, deleteItem) => (
            <>
                <Link to={`${editUrl}${permissionResourceName == "Propiedades" ? name.referencia : name.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark">
                    <EditIcon />
                </Link>
                {deleteItem ?
                    <button className="btn btn-outline-dark" onClick={() => deleteItem(name.id)} style={{ marginLeft: "20px" }}>
                        <DeleteIcon />
                    </button>
                    : <></>}
            </>

        ),
    };
    // console.log(userPermision);

    const { rol } = userPermision || {};
    const permisos = rol?.permisos?.acciones || [];
    const customViews = rol?.permisos?.customViews?.[permissionResourceName] || [];
    // Procesar acciones de acuerdo a los permisos
    return acciones.map((accion) => {
        // Filtrar permisos para la acción y el recurso
        const permisosFiltrados = permisos.filter(p => p.action == accion && p.resource == permissionResourceName);
        // Si tiene permisos personalizados de edición y sucursal coincide
        if (customViews.includes('Custom Edit') && sucursal_id === userPermision.usuarioDatos?.sucursal_id_ext && accion != "all") {
            return actionButtons[accion](name, deleteItem);
        }
        // console.log(permisosFiltrados);
        
        // Si tiene un permiso normal
        if (permisosFiltrados.some(p => p.action === accion && p.resource === permissionResourceName)) {
            return actionButtons[accion](name, deleteItem);
        }
        return null; // No tiene permisos para esta acción
    }).filter(Boolean); // Filtrar los que son null
};
//Publicar boton
export const verificarPermisosPublicar = (acciones, userPermision, permissionResourceName, sucursal_id, name) => {
    // Extraemos el rol y los permisos del usuario
    const { rol } = userPermision || {};
    const permisos = rol?.permisos?.acciones || [];
    const customViews = rol?.permisos?.customViews?.[permissionResourceName] || [];

    // Función que verifica si el usuario tiene los permisos necesarios para la acción
    const tienePermiso = (accion) => {
        // Verificamos si el usuario tiene permisos personalizados de edición y la sucursal coincide
        const permisosFiltrados = permisos.filter(p => p.action === accion && p.resource === permissionResourceName);

        if (customViews.includes('Custom Edit') && sucursal_id === userPermision.usuarioDatos?.sucursal_id_ext) {

            return false; // Tiene permiso para editar la sucursal
        } else if (permisosFiltrados.length > 0) {

            return false;
        } else if (permisosFiltrados.length <= 0) {
            return true;
        }

    };

    // Iteramos sobre las acciones y verificamos si tiene permiso para alguna
    return acciones.every(accion => tienePermiso(accion));
};

//Acciones de hojaForm, ver estado y roles de usuario
export const accionesHojasForm = (user, data, editLink, handleDelete) => {
    const renderValue = (row, editLink, handleDelete) => {
        return (
            <div style={{ display: 'flex', gap: '10px' }}>
                <Link to={`${editLink}`}>
                    <button className="btn btn-outline-dark">
                        <PencilFill />
                    </button>
                </Link>
                <Link to={`#`} onClick={() => handleDelete(row.id)}>
                    <button className="btn btn-outline-danger">
                        <TrashFill />
                    </button>
                </Link>
            </div>
        )
    }
    let res = null;
    if (user?.rol?.permisos?.customViews['HojaDeFormalizacion']?.includes('View HojasForm Estado')) {

        if (user?.rol?.code == "RDOF") {
            switch (data.estado) {
                case "pendiente_validacion_responsable":
                    res = renderValue(data, editLink, handleDelete);
                    break;
                case "validado_por_responsable":
                    res = renderValue(data, editLink, handleDelete);
                    break;
                default:
                    res = <div>Datos Validados</div>
                    break;
            }
        } else if (user?.rol?.code == "COOR") {
            switch (data.estado) {
                case "pendiente_validacion_responsable":
                    res = renderValue(data, editLink, handleDelete);
                    break;
                default:
                    res = <div>Datos Validados</div>
                    break;
            }
        }

    }
    if (checkSectionViewPermision(user, "all", "HojaDeFormalizacion")) {
        res = renderValue(data, editLink, handleDelete);
    }
    return res;
}

export const contactosRolPermisos = (user, perfilesContactos, tipo = null) => {

    let result = null;
    // Obtenemos los permisos de "Contactos"
    const permisosContactos = user?.rol?.permisos?.customViews['Contactos'] || [];

    // Verificamos si alguno de los valores en `perfilesContactos` está en `permisosContactos`
    const tienePermiso = perfilesContactos.some(perfil => permisosContactos.includes(perfil));
    result = {
        filtros: {
            sucursal_id: tienePermiso ? user?.usuarioDatos?.sucursal_id : '',
            id_trabajador: (user?.rol?.permisos?.customViews['Contactos']?.includes('Clientes Suyos') && tipo == "Cliente") ? user.usuarioDatos.trabajador_id : '',
            // tipo: (user?.rol?.permisos?.customViews['Contactos']?.includes('Clientes Suyos')) ? 'Administrador de Fincas' : '',
        },
        adicionales: {
            disable: tienePermiso ? true : false,
        }
    }
    // Si tiene permiso, devolvemos el ID de la sucursal, si no, devolvemos una cadena vacía
    return result;
}
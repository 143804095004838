import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import {
    Button,
    Typography,
    Chip,
    TextField,
    Box,
    MenuItem

} from '@mui/material';
import {
    Download,
    XCircleFill,
    PencilFill,
    EyeFill,
    TrashFill,
} from "react-bootstrap-icons";
import { DataTable } from "../../components";
import { AiFillFileAdd } from "react-icons/ai";
import { useHojasForm } from "../../hooks";
import { deleteHojaForm } from "../../services";
import Swal from "sweetalert2";
import { useUser } from "../../hooks/UserContextLogin";
import * as Yup from 'yup';
import { listSucursales } from "../../services/sucursales.service.js";
import { SnackbarUtilities } from "../../utilities";
import { useFormValidation } from "../../utilities/formValidator";
import { dynamicTableHeader } from "../../utilities/transformHeaders.js";
import { FaSearch } from "react-icons/fa";
import { MdCleaningServices } from "react-icons/md";
import ButtonPermission from "../../utilities/ButtonPermission.jsx";
import { accionesHojasForm } from "../../utilities/permissionHelper.js";

const styledButton = {
    backgroundColor: '#33383b',
    color: '#c4af6c',
    '&:hover': {
        backgroundColor: '#c4af6c',
        color: '#33383b',
    },
};

const HojasFormalizacion = () => {
    const { user, setUser } = useUser();
    const [sucursalMap, setSucursalMap] = useState(null);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState({ sucursal_id: user?.rol?.permisos?.customViews['HojaDeFormalizacion']?.includes('Create HojasF') ? user?.usuarioDatos?.sucursal_id : null });
    const { loading, limite, data, fetchData } = useHojasForm();
    
    const initialValues = {
        fechaDesde: '',
        fechaHasta: '',
        sucursal_id: user?.rol?.permisos?.customViews['HojaDeFormalizacion']?.includes('Create HojasF') ? user?.usuarioDatos?.sucursal_id : '',
        accion: '',
        estado: ''
    };

    const validationSchema = Yup.object().shape({
        fechaDesde: Yup.string()
            .nullable(),
        fechaHasta: Yup.string()
            .nullable(),
        sucursal_id: Yup.string().nullable(),
        accion: Yup.string().nullable(),
        estado: Yup.string().nullable(),
        // id_trabajador: Yup.number().nullable(),
        // perfil: Yup.string().nullable(),
    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleReset, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        nullConverter: true,
        onSubmit: async (values) => {
            // console.log(values);
            let { fechaDesde, fechaHasta, ...newValues } = values;
            if (fechaDesde && fechaHasta) {
                newValues.fecha = {
                    fechaDesde,
                    fechaHasta
                };
            }
            // newValues = {...filtro,...newValues}
            // console.log({...filtro,...newValues});
            // console.log(newValues);

            if (newValues) {
                fetchData({ offset: currentPage, limit: 1000, filtros: newValues });
                // console.log(values);
            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });
    const fetchSucursales = async () => {
        // const response = await listSucursales({ offset: 1, limit: 1000 });
        const response = await listSucursales({ offset: 1, limit: 1000, filtros: { no_off_central: 10 } });
        // setSucursalMap(response.data || []);
        // console.log(response);
        
        setSucursalMap(response.data.sort((a, b) => a.provincia.localeCompare(b.provincia)) || []);

    };
    const headers = [
        {
            referencia: "referencia",
            numeric: false,
            disablePadding: false,
            label: "Referencia",
            name: "referencia",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row.referencia}
                </Typography>
            ),
        },
        {
            oficina: "oficina",
            numeric: false,
            disablePadding: false,
            label: "Oficina",
            name: "id_sucursal",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row.sucursal?.nombre_sucursal}
                </Typography>
            ),
        },
        {
            fechaOp: "fechaOp",
            numeric: false,
            disablePadding: false,
            name: "fechaOperacion",
            label: "Fecha Operación",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row?.fechaOperacion}
                </Typography>
            ),
        },
        {
            name: "inmueble",
            numeric: false,
            disablePadding: false,
            order: 'disable',
            label: "Ref/Tipo inmmueble",
            overrideFunc: (data, row) => (
                row?.propiedades?.map(prop => (
                    <Typography
                        sx={{
                            color: "#33383b !important",
                            "&:hover": { color: "#33383b" },
                            "&:active": { color: "#33383b" },
                            fontSize: "0.9rem",
                            fontWeight: "1rem"
                        }}
                    >
                        {prop.referencia} / {prop.tipo_ofer}
                    </Typography>
                ))

            ),
        },
        {
            name: "accion",
            numeric: false,
            disablePadding: false,
            label: "Operación",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem"
                    }}
                >
                    {row.accion}
                </Typography>
            ),
        },
        {
            name: "estado",
            numeric: false,
            disablePadding: false,
            label: "Estado",
            overrideFunc: (data, row) => (
                <>
                    {
                        row.estado === 'pendiente_validacion_responsable'
                            ? <Chip variant="outlined" label="Validación Pendiente" color="warning" />
                            : row.estado === 'validado_por_responsable'
                                ? <Chip variant="outlined" label="Validado Responsable" color="success" />
                                : row.estado === 'verificado_por_administrador'
                                    ? <Chip variant="outlined" label="Validado" color="info" />
                                    : <Chip variant="outlined" label={row.estado} color="default" />
                    }

                </>
            ),
        },
        {
            name: "total",
            numeric: false,
            disablePadding: false,
            label: "Precio",
            overrideFunc: (data, row) => (
                <Typography
                    sx={{
                        color: "#33383b !important",
                        "&:hover": { color: "#33383b" },
                        "&:active": { color: "#33383b" },
                        fontSize: "0.9rem",
                        fontWeight: "1rem",
                        textAlign: "right"
                    }}
                >
                    {row.total.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                </Typography>
            ),
        },
        {
            numeric: false,
            disablePadding: false,
            label: "Acciones",
            order: "disable",
            overrideFunc: (data, row) => (
                accionesHojasForm(user, row, `/HojaDeFormalizacion/editar/${row.id}`, handleDelete)
            ),
        }
    ];


    useEffect(() => {
        fetchSucursales();
    }, [])


    const handleDelete = (id) => {
        Swal.fire({
            title: "¿Está seguro de que quiere eliminar la publicación?",
            icon: "warning",
            showDenyButton: true,
            confirmButtonText: `Si`,
            confirmButtonColor: "#48cc90",
            denyButtonText: `No`,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const res = await deleteHojaForm(id);
                if (res.status === 201) {
                    await fetchData({ offset: currentPage, limit: limite, filtros: filtros });
                    Swal.fire(
                        "¡Eliminado!",
                        "Publicacion Eliminada correctamente",
                        "success"
                    );

                }
            }
        });
    }
    return (
        <>
            <div className="container">
                {/* <BreadCrumb /> */}
                <div className="topline">
                    <div>
                        <h3>Hojas de formalización</h3>
                    </div>
                    <div>
                        {/* <button className='btn btn-default me-2' onClick={() => reportProperties(filtrosSeleccionados)}><Download /></button> */}
                        {/* <Link to={'/hojaFormalizacion/crear'} state={{ sucursal: 1 }}>
                            <Button size="medium" sx={styledButton} startIcon={<AiFillFileAdd />}>Crear Hoja</Button>
                        </Link> */}
                        <ButtonPermission onClick={() => navigate("/HojaDeFormalizacion/crear",
                            { state: { sucursal: user?.rol?.permisos?.customViews['HojaDeFormalizacion']?.includes('Create HojasF') ? user?.sucursal : null } })}
                            variant="contained" endIcon={<AiFillFileAdd />}
                            action="create"
                            resource="HojaDeFormalizacion"
                            sx={{
                                color: '#c4af6c',
                                backgroundColor: '#33383b',
                                height: 40,
                                marginBottom: 1,
                                borderColor: 'transparent',
                                '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                            }}>
                            Crear Hoja
                        </ButtonPermission>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>

                    <div className="middleline">
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: { sm: '1fr 1fr' },
                                    gap: 2,
                                }}
                            >
                                <div >
                                    <TextField
                                        id="demo-simple-select-label"
                                        select
                                        disabled={user?.rol?.permisos?.customViews['HojaDeFormalizacion']?.includes('Create HojasF') ? true : false}
                                        label="Oficina"
                                        name="sucursal_id"
                                        margin="normal"
                                        sx={inputStyles}
                                        value={values.sucursal_id}
                                        size="small"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.sucursal_id && Boolean(errors.sucursal_id)}
                                        helperText={touched.sucursal_id && errors.sucursal_id}
                                    >
                                        {sucursalMap &&
                                            <MenuItem value="">
                                                <em>Ninguna</em>
                                            </MenuItem>
                                        }
                                        {sucursalMap ? sucursalMap.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.nombre_sucursal ?? "LAE HOMES - " + option.municipio}
                                            </MenuItem>
                                        )) : <MenuItem disabled>No hay oficinas disponibles</MenuItem>}

                                    </TextField>

                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", gap: 6 }}>
                                    <TextField
                                        sx={inputStyles}
                                        label="Inicio"
                                        name="fechaDesde"
                                        type="date"
                                        size="small"
                                        margin="normal"
                                        value={values.fechaDesde}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.fechaDesde && Boolean(errors.fechaDesde)}
                                        helperText={touched.fechaDesde && errors.fechaDesde}
                                    />
                                    <TextField
                                        sx={inputStyles}
                                        label="Fin"
                                        name="fechaHasta"
                                        type="date"
                                        size="small"
                                        margin="normal"
                                        value={values.fechaHasta}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.fechaHasta && Boolean(errors.fechaHasta)}
                                        helperText={touched.fechaHasta && errors.fechaHasta}
                                    />
                                </div>
                                <div >
                                    <TextField
                                        id="demo-simple-select-label"
                                        select
                                        label="Operación"
                                        name="estado"
                                        margin="normal"
                                        sx={inputStyles}
                                        value={values.accion}
                                        size="small"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.accion && Boolean(errors.accion)}
                                        helperText={touched.accion && errors.accion}
                                    >
                                        {[{ name: "Ninguna", key: "" }, { name: "ALQUILER", key: "ALQUILER" }, { name: "ALQ. OPCIÓN COMPRA", key: "ALQ_OPCION_COMPRA" }, { name: "COMPRA-VENTA", key: "COMPRA_VENTA" }, { name: "TRASPASO", key: "TRASPASO" }].map((option, index) => (
                                            <MenuItem key={index} value={option.key}>
                                                {option.name}
                                            </MenuItem>
                                        ))}

                                    </TextField>

                                </div>
                                <div >
                                    <TextField
                                        id="demo-simple-select-label"
                                        select
                                        label="Estado Hoja"
                                        name="estado"
                                        margin="normal"
                                        sx={inputStyles}
                                        value={values.estado}
                                        size="small"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.estado && Boolean(errors.estado)}
                                        helperText={touched.estado && errors.estado}
                                    >
                                        {[{ name: "Ninguna", key: "" }, { name: "Pendiente validación responsable", key: "pendiente_validacion_responsable" }, { name: "Validado por responsable", key: "validado_por_responsable" }, { name: "Validado por administrador", key: "verificado_por_administrador" }].map((option, index) => (
                                            <MenuItem key={index} value={option.key}>
                                                {option.name}
                                            </MenuItem>
                                        ))}

                                    </TextField>

                                </div>
                                <div style={{ display: "flex", flex: 0.5, justifyContent: "start", gap: '10px' }}>

                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        sx={{
                                            color: '#c4af6c',
                                            backgroundColor: '#33383b',
                                            height: 40,
                                            borderColor: 'transparent',
                                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                                        }}
                                    >
                                        <FaSearch style={{ color: '#c4af6c', '&:hover': { color: '#33383b' } }} />
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            color: '#33383b',
                                            backgroundColor: '#c4af6c',
                                            borderColor: 'transparent',
                                            '&:hover': { color: '#c4af6c', backgroundColor: '#fff', borderColor: '#33383b', }
                                        }}
                                        onClick={() => {
                                            // setFiltros({});
                                            fetchData({ offset: currentPage, limit: 1000 });
                                            handleReset();
                                        }}                        >
                                        <MdCleaningServices style={{ color: '#33383b', '&:hover': { color: '#c4af6c' } }} />
                                    </Button>
                                </div>
                            </Box>

                        </div>

                    </div>

                </form>
                <div className="middleline">
                    <DataTable
                        headers={headers}
                        data={data}
                        isLoading={loading}
                        chickable = {false}
                        fetcher={fetchData}
                        mensaje={"No hay Hojas disponibles"}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        filtros={filtros}
                        // orderTable={"ASC"}
                        // orderTableBy={"id_sucursal"}
                    />
                </div>
            </div>
        </>
    );
};
const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};
export default HojasFormalizacion;
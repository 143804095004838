
//from 2024-09-03T00:00:00.000Z to 03/09/2024
export function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}
//from 2024-09-03T00:00:00.000Z to 2024-09-03 this format is for html input type DATE
export function formatDateGuiones(dateString) {
    if (dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    }else {
        return null;
    }

}
//from 2024-09-03T00:00:00.000Z to 03-09-2024
export function formatDateGuionesEs(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}
//Current date this format 01-01-2024'
export const getCurrentDateEs = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${day}-${month}-${year}`;
}
//Current date this format 04/01/2024'
export const getCurrentDateSlash = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${day}/${month}/${year}`;
};
//Current date this format 2024-12-09
export const getCurrentDateIso = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};


//Current date input MUI type date this format 2024-01-01'
export const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export const formatDateToHF = (dateHf) => {
    if (!dateHf) return '';
    return dateHf.split('T')[0];
};

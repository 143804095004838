import React, { useContext, useState, useEffect } from "react";
import { SnackbarUtilities } from "../../utilities";
import { useFormValidation } from "../../utilities/formValidator";
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import { FaSearch } from "react-icons/fa";
import { MdCleaningServices } from "react-icons/md";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import provinces from "@doncicuto/es-provinces";
import { TextField, Button, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import useAdminFincas from "../../hooks/useAdminFincas.jsx";
import { dynamicTableHeader } from "../../utilities/transformHeaders";
import { DataTable } from "../../components";
import ButtonPermission from "../../utilities/ButtonPermission.jsx";
import { useNavigate, Link } from "react-router-dom";
import { deleteAAFF } from "../../services/aaff.service.js";
import { Edit, Close, Check, Delete, NoteAdd } from '@mui/icons-material/';
import municipalitiesWithProvinces from "../../utilities/municipalitiesWithProvinces.json"; // Ruta al JSON
import { CircularProgress } from '@mui/material';
import { useContactos, useUser } from "../../hooks/index.js";
import { listSucursales } from "../../services/sucursales.service.js";
import * as MuiIcons from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { contactosRolPermisos } from "../../utilities/permissionHelper.js";
import { articleDelete } from "../../services/articles.service.js";

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};

export default function Contactos() {
    const [provincia, setProvincia] = useState("");
    const { data, fetchData, loading, dataCustomNames } = useContactos();
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const [provinceSelected, setProvinceSelected] = useState(null);
    const [municipios, setMunicipios] = useState([]);
    const [loadingMunicipio, setLoadingMunicipio] = useState(false);
    const [sucursalMap, setSucursalMap] = useState(null);
    const { user, setUser } = useUser();
    const [resetPagination, setResetPagination] = useState(null);


    const filtros = contactosRolPermisos(user, ["Clientes Sucursal", "Colaborador Sucursal", "AdminFincas Sucursal"]);
    // console.log(filtros);

    const initialValues = {
        sucursal_id: filtros.filtros.sucursal_id,
        departamento_id: '',
        buscador: '',
        provincia: '',
        municipio: '',
        t_aaff: '',
        tipo: '',
        inmobiliaria: null,
        id_trabajador: filtros.filtros.id_trabajador,
    };


    const validationSchema = Yup.object().shape({
        sucursal_id: Yup.string().nullable(),
        departamento_id: Yup.string().nullable(),
        buscador: Yup.string().nullable(),
        provincia: Yup.string().nullable(),
        municipio: Yup.string().nullable(),
        t_aaff: Yup.string().nullable(),
        tipo: Yup.string().nullable(),
        inmobiliaria: Yup.boolean().nullable(),
        id_trabajador: Yup.string().nullable(),
    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setValues, handleReset } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (values) {
                if (values.tipo == "Cliente") {
                    values.id_trabajador = contactosRolPermisos(user, ["Clientes Sucursal", "Colaborador Sucursal", "AdminFincas Sucursal"], values.tipo).filtros.id_trabajador
                    fetchData({ offset: currentPage, limit: 1000, filtros: values });
                } else {
                    values.id_trabajador = ''
                    fetchData({ offset: currentPage, limit: 1000, filtros: values });
                    // console.log(values);
                }

            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });
    const iconColumn = [{
        item: (name) => {
            return (
                <div style={{ display: "flex", justifyContent: "left" }}>
                    <Link to={`/contactos/form/${name.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark" >
                        <Edit />
                    </Link>
                    <button className="btn btn-outline-dark" onClick={() => deleteItem(name.id)} style={{ marginLeft: "20px" }}>
                        <Delete />
                    </button>
                </div>
            )
        },
        position: 9,
        titleHeader: "Acciones",
        nameColumn: "",
        order: "disable",
    },
    {
        item: (name) => {
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "left" }}>
                    {name.perfil?.map((perfil) => {
                        const IconComponent = MuiIcons[perfil.icon]; // Obtén el icono de perfil
                        return (
                            <div key={perfil.id} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                {IconComponent && <IconComponent style={{ color: '#A69150', marginRight: "10px" }} />}
                                <span>{perfil.name}</span>
                            </div>
                        );
                    })}
                </div>
            );
        },
        position: 1,
        titleHeader: "Perfil",
        nameColumn: ""

    }]
    const deleteItem = async (id) => {
        try {
            const response = await articleDelete(id);
            // console.log(response);
            if (response == 200) {
                SnackbarUtilities.success("Trabajador eliminado correctamente!");
                fetchData({ offset: currentPage, limit: 1000 });
                // Aquí puedes agregar cualquier lógica adicional, como actualizar la lista de trabajadores
            } else {
                SnackbarUtilities.error("Error al eliminar al Trabajador. Inténtalo de nuevo más tarde.");
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleCustomChange = (event) => {
        const { name, value, type, checked } = event.target;
        const selectedProvince = provinces.filter((p) => p.name == value);
        if (selectedProvince) {
            // console.log(selectedProvince[0].code);
            setProvinceSelected(selectedProvince[0]?.code);
            handleChange(event)
        }

    }
    const fetchSucursales = async () => {
        // const response = await listSucursales({ offset: 1, limit: 1000 });
        // setSucursalMap(response.data || []);
        const response = await listSucursales({
            offset: 1, limit: 1000, filtros: { no_off_central: 10 },
            params: {
                orderBy: 'nombre_sucursal,ASC',
            },
        });
        setSucursalMap(response.data || []);

    };
    useEffect(() => {
        if (provinceSelected) {
            setLoadingMunicipio(true);

            // Filtrar municipios basados en la provincia seleccionada
            const filteredMunicipalities = municipalitiesWithProvinces.filter(
                (m) => m.provCode == provinceSelected
            );

            setMunicipios(filteredMunicipalities);
            setLoadingMunicipio(false);
        } else {
            setMunicipios([]);
        }
    }, [provinceSelected]);

    useEffect(() => {
        fetchSucursales();
    }, []);

    return (
        <div className="container">
            {/* <BreadCrumb /> */}
            <div className="topline">
                <div>
                    <h3>Contactos</h3>
                </div>
                <div>
                    <ButtonPermission onClick={() => { navigate("/Contactos/form") }} variant="contained" endIcon={<NoteAdd />}
                        action="edit"
                        resource="Contactos"
                        sx={{
                            color: '#c4af6c',
                            backgroundColor: '#33383b',
                            height: 40,
                            marginBottom: 1,
                            borderColor: 'transparent',
                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}>
                        Crear
                    </ButtonPermission>
                </div>
            </div>
            <form onSubmit={handleSubmit}>

                <div className="middleline">
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: { sm: '1fr 1fr 1fr 1fr' },
                                gap: 2,
                            }}
                        >
                            <div >
                                <TextField
                                    id="input-with-icon-textfield"
                                    label="Buscador"
                                    margin="normal"
                                    name="buscador"
                                    sx={inputStyles}
                                    size="small"
                                    value={values.buscador}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.buscador && Boolean(errors.buscador)}
                                    helperText={touched.buscador && errors.buscador}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    label="Perfil"
                                    name="tipo"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.tipo}
                                    size="small"
                                    onChange={handleCustomChange}
                                    onBlur={handleBlur}
                                    error={touched.tipo && Boolean(errors.tipo)}
                                    helperText={touched.tipo && errors.tipo}
                                >
                                    <MenuItem value="">
                                        <em>Ninguno</em> {/* Texto que aparecerá como opción */}
                                    </MenuItem>
                                    {["Administrador de Fincas", "Cliente", "Colaborador", "Gremios"].map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    label="Provincia"
                                    name="provincia"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.provincia}
                                    size="small"
                                    onChange={handleCustomChange}
                                    onBlur={handleBlur}
                                    error={touched.provincia && Boolean(errors.provincia)}
                                    helperText={touched.provincia && errors.provincia}
                                >
                                    <MenuItem value="">
                                        <em>Ninguno</em>
                                    </MenuItem>
                                    {provinces.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => (
                                        <MenuItem key={index} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    disabled={provinceSelected ? false : true}
                                    label="Municipio"
                                    name="municipio"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.municipio}
                                    size="small"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.municipio && Boolean(errors.municipio)}
                                    helperText={touched.municipio && errors.municipio}
                                >
                                    <MenuItem value="">
                                        <em>Ninguno</em>
                                    </MenuItem>
                                    {loading ? (
                                        <MenuItem disabled>
                                            <CircularProgress size={24} />
                                        </MenuItem>
                                    ) : (
                                        municipios.map((item, index) => (
                                            <MenuItem key={index} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))
                                    )}
                                </TextField>
                            </div>
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    label="Administrador de Fincas"
                                    name="t_aaff"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.t_aaff}
                                    size="small"
                                    onChange={handleCustomChange}
                                    onBlur={handleBlur}
                                    error={touched.t_aaff && Boolean(errors.t_aaff)}
                                    helperText={touched.t_aaff && errors.t_aaff}
                                >
                                    <MenuItem value="">
                                        <em>Ninguno</em>
                                    </MenuItem>
                                    {["INTEGRAL", "PRESCRIPTOR"].map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    disabled={contactosRolPermisos(user, ["Clientes Sucursal", "Colaborador Sucursal", "AdminFincas Sucursal"]).adicionales.disable}
                                    label="Oficina"
                                    name="sucursal_id"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.sucursal_id}
                                    size="small"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.sucursal_id && Boolean(errors.sucursal_id)}
                                    helperText={touched.sucursal_id && errors.sucursal_id}
                                >
                                    {sucursalMap &&
                                        <MenuItem value="">
                                            <em>Ninguna</em>
                                        </MenuItem>
                                    }
                                    {sucursalMap ? sucursalMap.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.nombre_sucursal ?? "LAE HOMES - " + option.municipio}
                                        </MenuItem>
                                    )) : <MenuItem disabled>No hay oficinas disponibles</MenuItem>}

                                </TextField>

                            </div>
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    label="Inmobiliaria"
                                    name="inmobiliaria"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.inmobiliaria ?? ''}
                                    size="small"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.inmobiliaria && Boolean(errors.inmobiliaria)}
                                    helperText={touched.inmobiliaria && errors.inmobiliaria}
                                >
                                    {[{ name: "Ninguno", value: "" }, { name: "Sí", value: true }, { name: "No", value: false }].map((option, index) => (
                                        <MenuItem key={option.index} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </div>
                            <div style={{ display: "flex", flex: 0.5, justifyContent: "start", gap: '10px' }}>

                                <Button
                                    type="submit"
                                    variant="outlined"
                                    sx={{
                                        color: '#c4af6c',
                                        backgroundColor: '#33383b',
                                        height: 40,
                                        borderColor: 'transparent',
                                        '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                                    }}
                                >
                                    <FaSearch style={{ color: '#c4af6c', '&:hover': { color: '#33383b' } }} />
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        color: '#33383b',
                                        backgroundColor: '#c4af6c',
                                        borderColor: 'transparent',
                                        '&:hover': { color: '#c4af6c', backgroundColor: '#fff', borderColor: '#33383b', }
                                    }}
                                    onClick={() => {
                                        // setFiltros({});
                                        // fetchData({ offset: currentPage, limit: 1000, filtros: filtros.filtros });
                                        handleReset();
                                        setResetPagination(true);
                                    }}                        >
                                    <MdCleaningServices style={{ color: '#33383b', '&:hover': { color: '#c4af6c' } }} />
                                </Button>
                            </div>
                        </Box>

                    </div>

                </div>

            </form>
            <DataTable
                data={data}

                isLoading={loading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                fetcher={fetchData}
                mensaje={"No hay Contactos disponibles"}
                filtros={filtros.filtros}
                chickable = {false}
                orderTable={"ASC"}
                orderTableBy={"nombre"}
                headers={dynamicTableHeader({
                    headerArrayData: dataCustomNames,
                    showJustThis: ["Nombre", "Apellidos", "Nombre Comercial", "Teléfono", "Email"],
                    addNewCustomHeader: iconColumn
                })}
                resetPagination={resetPagination}
                setResetPagination={setResetPagination}
            />

        </div>

    )
}

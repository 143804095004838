import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import BreadCrumb from "../../components/navbar/Breadcrumb";
import * as Yup from 'yup';
import { useFormValidation } from "../../utilities/formValidator";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import { listSucursales, listRoles, createTrabajador, getTrabajador, updateTrabajador, resendMailVerification, listDepartamentos } from "../../services";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import InputAdornment from '@mui/material/InputAdornment';
import DragNDrop from "../../components/DragNDrop/DragNDrop";
import { TrashFill } from "react-bootstrap-icons";
import { SnackbarUtilities } from "../../utilities";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { getUrlFromFile, getUrlFromString } from "../../utilities/img_url";
import ButtonMui from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { createFormData } from "../../utilities/formDataHelper";
import ButtonPermission from "../../utilities/ButtonPermission";
import { ContactPhone, Add, NoteAdd, Remove, Save } from '@mui/icons-material';
import { Typography, Button } from '@mui/material';
import { allCountries } from "country-telephone-data";
import Flags from "react-world-flags";

const countryCodes = allCountries.map((country) => ({
    code: `+${country.dialCode}`, // Código de teléfono
    country: country.name,        // Nombre del país
    flag: country.iso2.toUpperCase(), // Código de bandera ISO 3166-1
})).sort((a, b) => {
    // Priorizar España colocando su código primero
    if (a.flag === "ES") return -1; // Coloca España primero usando ISO
    if (b.flag === "ES") return 1;
    return 0; // Mantén el orden para los demás
});

const TrabajadorForm = () => {
    const [countryCode, setCountryCode] = useState(countryCodes[0]?.code || "+34");
    const [countryCode2, setCountryCode2] = useState(countryCodes[0]?.code || "+34");
    const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
    const [selectedCountry2, setSelectedCountry2] = useState(countryCodes[0]);
    // console.log(allCountries);

    const handleCountryCodeChange = (event) => {
        const selected = countryCodes.find((country) => country.code === event.target.value);
        setCountryCode(selected.code);
        setSelectedCountry(selected);
    };
    const handleCountryCodeChange2 = (event) => {
        const selected = countryCodes.find((country) => country.code === event.target.value);
        setCountryCode2(selected.code);
        setSelectedCountry2(selected);
    };
    const { id: trabajadorId } = useParams();
    const [sucursalMap, setSucursalMap] = useState(null);
    const [departamentoList, setDepartamentoList] = useState(null);
    const [personName, setPersonName] = React.useState([]);
    const [rolMap, setRolMap] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const history = useNavigate();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const theme = useTheme();

    const initialValues = {
        nombre: '',
        apellidos: '',
        email: '',
        cargo: '',
        dni: '',
        telefono: '',
        telefonoPersonal: '',
        fechaNac: '',
        sucursal_id: '',
        acceso: false,
        active: true,
        web: false,
        rol: '',
        url_profile_img: '',
        id_departamento: [],
    };

    const validationSchema = Yup.object().shape({
        nombre: Yup.string().required('Campo obligatorio').min(3, 'Debe tener al menos 3 caracteres'),
        apellidos: Yup.string().required('Campo obligatorio'),
        email: Yup.string().email('Formato de correo no válido').required('Campo obligatorio'),
        cargo: Yup.string().min(4,'Cargo debe de tener mínimo 4 letras').required('Campo obligatorio'),
        dni: Yup.string().notRequired().nullable(),
        telefono: Yup.string().matches(/^[0-9+\-().\s]*$/, 'Formato de teléfono no válido', { excludeEmptyString: true }).nullable(), // Permite valores vacíos
        telefonoPersonal: Yup.string().matches(/^[0-9+\-().\s]*$/, 'Formato de teléfono no válido', { excludeEmptyString: true }).nullable(),
        fechaNac: Yup.string().nullable(),
        sucursal_id: Yup.number().required('Campo obligatorio'),
        acceso: Yup.boolean(),
        active: Yup.boolean(),
        web: Yup.boolean(),
        rol: Yup.string().nullable().when('acceso', {
            is: true, // alternatively: (val) => val == true
            then: (schema) => schema.required('Campo obligatorio'),
            otherwise: (schema) => schema.notRequired(),
        }),
        url_profile_img: Yup.string().when('web', {
            is: true, // alternatively: (val) => val == true
            then: (schema) => schema.required('Campo obligatorio'),
            otherwise: (schema) => schema.notRequired(),
        }),
        id_departamento: Yup.array().nullable(),

    });

    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setValues } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // console.log(values);

            if (values) {
                let { telefono, telefonoPersonal, ...newValues } = values;
                if (telefono) {
                    newValues.telefono = countryCode + " " + values.telefono
                }
                if (telefonoPersonal) {
                    newValues.telefonoPersonal = countryCode2 + " " + values.telefonoPersonal
                }
                // console.log(newValues);

                // Agrega todos los valores del formulario a formData
                const formData = createFormData(newValues);
                const response = editMode ? await updateTrabajador(trabajadorId, formData) : await createTrabajador(formData);

                // console.log(response);
                if (response.status == 201) {
                    if (trabajadorId) {
                        SnackbarUtilities.success("Trabajador Editado correctamente!!");
                    } else {
                        SnackbarUtilities.success("Trabajador creado y dado de Alta correctamente!!");
                    }
                    setTimeout(() => {
                        history(`/trabajadores`);
                    }, 2000);
                } else if (response.response?.data?.message) {
                    SnackbarUtilities.error(response.response?.data?.message);
                }
                else {
                    SnackbarUtilities.error("error");
                }
            } else {
                SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    const fetchSucursales = async () => {
        // const response = await listSucursales({ offset: 1, limit: 1000 });
        // setSucursalMap(response.data || []);
        const response = await listSucursales({
            offset: 1, limit: 1000,
            params: {
                orderBy: 'nombre_sucursal,ASC',
            },
        });
        setSucursalMap(response.data || []);
    };
    const fetchDepartamentos = async () => {
        const response = await listDepartamentos({ offset: 1, limit: 1000 });
        // console.log(response.data);
        // setDepartamentoList(response.data || []);
        setDepartamentoList(response.data.sort((a, b) => a.nombre.localeCompare(b.nombre)) || []);

    };
    const fetchRoles = async () => {
        const response = await listRoles();
        setRolMap(response || []);
    };
    const handleChangeCustom = (event) => {
        const { name, value, type, checked } = event.target;
        // console.log(name, value, type, checked);
        handleChange(event);
        if (type == "checkbox" && checked == false) {
            setCustomFieldValue('url_profile_img', null);
            setImageFile(null);
        }
        if (name == "id_departamento") {
            const {
                target: { value },
            } = event;
            setCustomFieldValue('id_departamento', value);
            setPersonName(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value
            );
        }
        if (name == "acceso") {
            // console.log("aaaa");
            setCustomFieldValue('rol', null);
        }
        if (name == "telefono") {
            // console.log("aaaa");
            const numberFormated = value.replace(countryCode, "").trim();
            // console.log(numberFormated);
            setCustomFieldValue('telefono', numberFormated);

        }
        if (name == "telefonoPersonal") {
            // console.log("aaaa");
            const numberFormated = value.replace(countryCode2, "").trim();
            // console.log(numberFormated);
            setCustomFieldValue('telefonoPersonal', numberFormated);

        }
        // onChange={(e) => {
        //     const phone = e.target.value.replace(countryCode, "").trim(); // Remover prefijo
        //     handleChange({ ...e, target: { ...e.target, value: phone } });
        // }}
    };

    const fetchTrabajador = async () => {
        if (trabajadorId) {
            const response = await getTrabajador(trabajadorId);
            // console.log(response.data);
            if (response.data) {
                // console.log("entroooo");
                const trabajadorData = response.data;
                // console.log(trabajadorData);
                // console.log(trabajadorData.user_trabajador);
                // console.log(trabajadorData.trabajador_departamento);
                const departamentoIds = trabajadorData.trabajador_departamento
                    ? trabajadorData.trabajador_departamento.map(dep => dep.id)
                    : [];
                
                setValues({
                    nombre: trabajadorData.nombre,
                    apellidos: trabajadorData.apellidos,
                    email: trabajadorData.email,
                    cargo: trabajadorData.cargo,
                    dni: trabajadorData.dni ?? '',
                    // telefono: trabajadorData.telefono ?? '',
                    // telefonoPersonal: trabajadorData.telefonoPersonal ?? '',
                    fechaNac: trabajadorData.fechaNac && new Date(trabajadorData.fechaNac).toISOString().split('T')[0] || '',
                    sucursal_id: trabajadorData.sucursal_id,
                    acceso: trabajadorData.user_trabajador?.id ? true : false,
                    active: trabajadorData.active ?? false,
                    web: trabajadorData.web ?? false,
                    rol: trabajadorData.user_trabajador?.role?.code ?? null,
                    url_profile_img: trabajadorData.url_profile_img,
                    user_id: trabajadorData.user_id,
                    id: trabajadorData.id,
                    id_departamento: departamentoIds,
                });
                if (trabajadorData.telefono) {        
                    const numberAndCode = trabajadorData.telefono.match(/^(\+\d+)\s(.+)$/)?.slice(1, 3) ?? ['+34',trabajadorData.telefono];
                    setCustomFieldValue('telefono',numberAndCode[1]);
                    setCountryCode(numberAndCode[0]);
                    setSelectedCountry(countryCodes.find((country) => country.code == numberAndCode[0]));
                }else {
                    setCustomFieldValue('telefono','');
                }
                if (trabajadorData.telefonoPersonal) {        
                    const numberAndCode = trabajadorData.telefonoPersonal.match(/^(\+\d+)\s(.+)$/)?.slice(1, 3) ?? ['+34',trabajadorData.telefonoPersonal];
                    setCustomFieldValue('telefonoPersonal',numberAndCode[1]);
                    setCountryCode2(numberAndCode[0]);
                    setSelectedCountry2(countryCodes.find((country) => country.code == numberAndCode[0]));
                }else {
                    setCustomFieldValue('telefonoPersonal','');
                }

                setImageFile(getUrlFromString(trabajadorData.url_profile_img, response));
                setEditMode(true);
            } else {
                SnackbarUtilities.error("No se pudo obtener la información del trabajador");
            }
        }
    };

    const resendMail = async () => {
        // console.log(values);
        // console.log(formData);
        const mailData = { ...values, resendMail: true }
        try {
            const data = await resendMailVerification(mailData);
            if (data == 200) {
                SnackbarUtilities.success("Correo Reenviado Correctamente");
            } else {
                SnackbarUtilities.error("Error al Reenviar el Correo");
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (trabajadorId) {
            fetchTrabajador();
        }
        fetchSucursales();
        fetchRoles();
        fetchDepartamentos();
    }, []);

    return (
        <div className="container">
            {/* <BreadCrumb /> */}
            <div className="top-line">
                <div style={{ textAlign: "start" }}>
                    <h3>{editMode ? 'Editar Trabajador' : 'Crear Trabajador'}</h3>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
                    <ButtonPermission variant="contained" type="submit" endIcon={<NoteAdd />}
                        action="edit"
                        resource="Trabajadores"
                        sx={{
                            color: '#c4af6c',
                            backgroundColor: '#33383b',
                            height: 40,
                            marginBottom: 1,
                            borderColor: 'transparent',
                            '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                        }}>
                        Guardar
                    </ButtonPermission>
                </div>
                {
                    (process.env.NODE_ENV == "development" && Object.keys(errors).length > 0) &&
                    <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                        {JSON.stringify(errors)}
                    </div>
                }
                <div className="middleline">
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: { sm: '1fr 1fr' },
                                gap: 2,
                            }}
                        >
                            <div>
                                <TextField
                                    fullWidth
                                    label="Nombre"
                                    name="nombre"
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.nombre}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.nombre && Boolean(errors.nombre)}
                                    helperText={touched.nombre && errors.nombre}
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    label="Apellidos"
                                    name="apellidos"
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.apellidos}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.apellidos && Boolean(errors.apellidos)}
                                    helperText={touched.apellidos && errors.apellidos}
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    label="Cargo"
                                    name="cargo"
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.cargo}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.cargo && Boolean(errors.cargo)}
                                    helperText={touched.cargo && errors.cargo}
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    label="DNI"
                                    name="dni"
                                    margin="normal"
                                    size="small"
                                    sx={inputStyles}
                                    value={values.dni}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.dni && Boolean(errors.dni)}
                                    helperText={touched.dni && errors.dni}
                                />
                            </div>
                            <Box display="flex" alignItems="center" sx={{ gap: 0.8 }}>
                                {/* Select para prefijo con banderas */}
                                <FormControl size="small" sx={{ minWidth: 70 }}>
                                    <Select
                                        value={countryCode}
                                        onChange={handleCountryCodeChange}
                                        renderValue={() => (
                                            <Flags code={selectedCountry.flag} style={{ width: 25, height: 15 }} />
                                        )}
                                        sx={{ backgroundColor: "white" }}
                                    >
                                        {countryCodes.map((country) => (
                                            <MenuItem key={country.code} value={country.code}>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Flags code={country.flag} style={{ width: 20, height: 15 }} />
                                                    {country.code} ({country.country})
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* Input para teléfono */}
                                <TextField
                                    id="input-with-icon-textfield"
                                    name="telefono"
                                    sx={inputStyles}
                                    label="Teléfono"
                                    // value={values.telefono}
                                    value={`${countryCode} ${values.telefono}`}
                                    size="small"
                                    onChange={handleChangeCustom}
                                    onBlur={handleBlur}
                                    error={touched.telefono && Boolean(errors.telefono)}
                                    helperText={touched.telefono && errors.telefono}
                                />
                            </Box>
                            <Box display="flex" alignItems="center" sx={{ gap: 0.8 }}>
                                {/* Select para prefijo con banderas */}
                                <FormControl size="small" sx={{ minWidth: 70 }}>
                                    <Select
                                        value={countryCode2}
                                        onChange={handleCountryCodeChange2}
                                        renderValue={() => (
                                            <Flags code={selectedCountry2.flag} style={{ width: 25, height: 15 }} />
                                        )}
                                        sx={{ backgroundColor: "white" }}
                                    >
                                        {countryCodes.map((country) => (
                                            <MenuItem key={country.code} value={country.code}>
                                                <Box display="flex" alignItems="center" gap={1}>
                                                    <Flags code={country.flag} style={{ width: 20, height: 15 }} />
                                                    {country.code} ({country.country})
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* Input para teléfono */}
                                <TextField
                                    id="input-with-icon-textfield"
                                    name="telefonoPersonal"
                                    sx={inputStyles}
                                    label="Teléfono Personal"
                                    value={`${countryCode2} ${values.telefonoPersonal}`}
                                    size="small"
                                    onChange={handleChangeCustom}
                                    onBlur={handleBlur}
                                    error={touched.telefonoPersonal && Boolean(errors.telefonoPersonal)}
                                    helperText={touched.telefonoPersonal && errors.telefonoPersonal}
                                />
                            </Box>
                            {/* <div>
                                <TextField
                                    id="input-with-icon-textfield"
                                    name="telefonoPersonal"
                                    sx={inputStyles}
                                    size="small"
                                    label="Teléfono Personal"
                                    value={values.telefonoPersonal}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.telefonoPersonal && Boolean(errors.telefonoPersonal)}
                                    helperText={touched.telefonoPersonal && errors.telefonoPersonal}
                                />
                            </div> */}
                            <div>
                                <TextField
                                    sx={inputStyles}
                                    label="Fecha de Nacimiento"
                                    name="fechaNac"
                                    type="date"
                                    size="small"
                                    margin="normal"
                                    value={values.fechaNac}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.fechaNac && Boolean(errors.fechaNac)}
                                    helperText={touched.fechaNac && errors.fechaNac}

                                />
                            </div>
                            <div>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Selecciona Oficina"
                                    size="small"
                                    name="sucursal_id"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.sucursal_id}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.sucursal_id && Boolean(errors.sucursal_id)}
                                    helperText={touched.sucursal_id && errors.sucursal_id}
                                >
                                    {sucursalMap &&
                                        <MenuItem value="">
                                            <em>Ninguna</em>
                                        </MenuItem>
                                    }
                                    {sucursalMap ? sucursalMap.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.nombre_sucursal ?? "LAE HOMES - " + option.municipio}
                                        </MenuItem>
                                    )) : null}
                                </TextField>
                            </div>
                            <div>
                                <FormControl sx={inputStyles} size="small">
                                    <InputLabel id="demo-multiple-chip-label3">Departamentos</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label3"
                                        id="demo-multiple-chip-label3"
                                        multiple
                                        name="id_departamento"
                                        sx={inputStyles}
                                        margin="normal"
                                        value={values.id_departamento}
                                        onChange={handleChangeCustom}
                                        input={<OutlinedInput id="select-multiple-chip-label" label="Departamentos" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={departamentoList && departamentoList.find(depto => depto.id === value)?.nombre || 'Nombre no encontrado'} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                        onBlur={handleBlur}
                                        error={touched.id_departamento && Boolean(errors.id_departamento)}
                                        helperText={touched.id_departamento && errors.id_departamento}
                                    >
                                        {departamentoList ? departamentoList.map((depto) => (
                                            <MenuItem
                                                key={depto.id}
                                                value={depto.id}
                                                style={getStyles(depto.id, values.id_departamento, theme)}
                                            >
                                                {depto.nombre}
                                            </MenuItem>
                                        )) : null}
                                    </Select>
                                    {touched.id_departamento && errors.id_departamento && (
                                        <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                                            {errors.id_departamento}
                                        </div>
                                    )}
                                </FormControl>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <FormControlLabel
                                        sx={{ marginTop: 0 }}
                                        control={
                                            <Checkbox
                                                checked={values.active}
                                                size="small"
                                                name="active"
                                                color="default"
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                onChange={handleChangeCustom}
                                                onBlur={handleBlur}
                                                error={touched.active && Boolean(errors.active)}
                                                helperText={touched.active && errors.active}
                                            />
                                        }
                                        label="Activo"
                                    />
                                    {/* {touched.acceso && errors.acceso && (
                                    <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                                        {errors.acceso}
                                    </div>
                                )} */}
                                </div>
                                <div>
                                    <FormControlLabel
                                        sx={{ marginTop: 0 }}
                                        control={
                                            <Checkbox
                                                checked={values.acceso}
                                                size="small"
                                                name="acceso"
                                                color="default"
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                onChange={handleChangeCustom}
                                                onBlur={handleBlur}
                                                error={touched.acceso && Boolean(errors.acceso)}
                                                helperText={touched.acceso && errors.acceso}
                                            />
                                        }
                                        label="Acceso"
                                    />
                                    {/* {touched.acceso && errors.acceso && (
                                    <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                                        {errors.acceso}
                                    </div>
                                )} */}
                                </div>
                                <div>
                                    <FormControlLabel
                                        sx={{ marginTop: 0 }}
                                        control={
                                            <Checkbox
                                                checked={values.web}
                                                size="small"
                                                name="web"
                                                color="default"
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                onChange={handleChangeCustom}
                                                onBlur={handleBlur}
                                                error={touched.web && Boolean(errors.web)}
                                                helperText={touched.web && errors.web}
                                            />
                                        }
                                        label="Publicar Foto en la web"
                                    />
                                    {/* {touched.web && errors.web && (
                                    <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                                        {errors.web}
                                    </div>
                                )} */}
                                </div>
                            </div>

                            {values.acceso && <div>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Rol"
                                    name="rol"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.rol}
                                    size="small"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.rol && Boolean(errors.rol)}
                                    helperText={touched.rol && errors.rol}
                                >
                                    {rolMap ? rolMap.map((option) => (
                                        <MenuItem key={option.id} value={option.code}>
                                            {option.nombre}
                                        </MenuItem>
                                    )) : null}
                                </TextField>
                            </div>}
                            {imageFile ? (
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        height: "100px",
                                        marginBottom: "80px",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            right: 10,
                                            top: 10,
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setImageFile(null);
                                            setCustomFieldValue('url_profile_img', null);
                                        }}
                                    >
                                        <TrashFill color="red" height={22} width={22} />
                                    </div>
                                    <img
                                        src={imageFile}
                                        alt="imagen"
                                        style={{
                                            objectFit: "contain",
                                            height: "130px",
                                            width: "100%",
                                            marginTop: "4px"
                                        }}
                                    />
                                </div>
                            ) : (
                                <div>

                                    <DragNDrop
                                        onUpload={(files) => { setCustomFieldValue('url_profile_img', files); setImageFile(getUrlFromFile(files)) }}
                                        count={1}
                                        formats={["jpg", "png", "svg", "jpeg"]}
                                        label={<p variant="caption">Imagen Artículo</p>}
                                        title='"Suelta un archivo aqui..."'
                                        openDialogOnClick={true}
                                        style={{ marginBottom: "20px" }}
                                        onBlur={handleBlur}
                                        error={touched.url_profile_img && Boolean(errors.url_profile_img)}
                                        helperText={touched.url_profile_img && errors.url_profile_img}
                                    />
                                    {touched.url_profile_img && errors.url_profile_img && values.web && (
                                        <div className="css-k4qjio-MuiFormHelperText-root" style={{ color: '#d32f2f', textAlign: "center" }}>
                                            {errors.url_profile_img}
                                        </div>
                                    )}
                                </div>
                            )}
                            {editMode && trabajadorId && values.user_id && <div>
                                <ButtonMui style={{ backgroundColor: "#c4af6c", height: "40px", width: "100%" }} variant="contained" endIcon={<SendIcon />} onClick={() => resendMail()}>
                                    Reenviar Email Verificación
                                </ButtonMui>
                            </div>}

                        </Box>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default TrabajadorForm;

const styledButton = {
    backgroundColor: '#33383b',
    color: '#c4af6c',
    '&:hover': {
        backgroundColor: '#c4af6c',
        color: '#33383b',
    },
};

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 0,
    marginTop: 0
};

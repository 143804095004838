import React, { useEffect, useState } from "react";
import Logo from './../../images/logo_laehomes_color.svg'
import { useLocation } from 'react-router-dom';
import { SnackbarUtilities } from "../../utilities";
import { useFormValidation } from "../../utilities/formValidator";
import * as Yup from 'yup';
import { Box, Button, TextField, Typography, Container, Paper } from '@mui/material';
import imgBackground from '../../images/casa-fondo.jpg';
import jwtDecode from "jwt-decode";
import { logout, userUpdate } from "../../services";

const RestablecerPassword = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const tokenFromUrl = params.get('token');
    const [userData, setUserData] = useState(null);

    //Si hay datos en la url pues se crea el usuario
    useEffect(() => {
        if (tokenFromUrl) {
            const decodedToken = jwtDecode(tokenFromUrl);
            setUserData(decodedToken);            
        }
    }, [tokenFromUrl])

    const initialValues = {
        newPass: '',
        repeatNewPass: '',
    };

    const validationSchema = Yup.object().shape({
        newPass: Yup.string()
            .required('Campo obligatorio')
            .min(8, 'Debe tener al menos 8 caracteres')
            .matches(/[A-Z]/, 'Debe contener al menos una letra mayúscula')
            .matches(/[a-z]/, 'Debe contener al menos una letra minúscula')
            .matches(/\d/, 'Debe contener al menos un número')
            .matches(/[!@#$%^&*(),.?":{}|]/, 'Debe contener al menos un carácter especial')
            .test(
                'caracteres-validos',
                function (value) {
                    const invalidChar = value && value.match(/[^A-Za-z\d!@#$%^&*(),.?":{}|]/);
                    return invalidChar
                        ? this.createError({ message: `La contraseña contiene un carácter especial no permitido: "${invalidChar[0]}". Prueba con otros caracteres.` })
                        : true;
                }
            ),
        repeatNewPass: Yup.string()
            .oneOf([Yup.ref('newPass')], 'Las contraseñas no coinciden')
            .min(8, 'Debe tener al menos 8 caracteres')
            .matches(/[A-Z]/, 'Debe contener al menos una letra mayúscula')
            .matches(/[a-z]/, 'Debe contener al menos una letra minúscula')
            .matches(/\d/, 'Debe contener al menos un número')
            .matches(/[!@#$%^&*(),.?":{}|]/, 'Debe contener al menos un carácter especial')
            .test(
                'caracteres-validos',
                function (value) {
                    const invalidChar = value && value.match(/[^A-Za-z\d!@#$%^&*(),.?":{}|]/);
                    return invalidChar
                        ? this.createError({ message: `La contraseña contiene un carácter especial no permitido: "${invalidChar[0]}". Prueba con otros caracteres.` })
                        : true;
                }
            )
            .required('Campo obligatorio')
    });


    // Usa la función de validación personalizada
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, setCustomFieldValue, setFieldTouched, setStatus, status } = useFormValidation({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (values) {
                if (values.newPass === values.repeatNewPass) {
                    if (userData?.user_id) {

                        const response = await userUpdate(userData.user_id, { password: values.newPass });
                        
                        if (response.status == 200) {
                            SnackbarUtilities.success("Contraseña modificada correctamente");
                            setTimeout(() => {
                                logout();
                            }, 2500);
                        } else {                            
                            SnackbarUtilities.error(response.response.data.error || "Ha habido un error a la hora de cambiar la contraseña, Intente más tarde");
                        }
                    }
                    // setStatus({ success: 'Las contraseñas coinciden.' });
                    // Lógica para enviar el formulario
                } else {
                    // setStatus({ error: 'Las contraseñas no coinciden.' });
                }

            } else {

                // SnackbarUtilities.error("Error en algun validador del campo");
            }
        },
    });

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundImage: `url(${imgBackground})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                margin: 0
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    display: 'flex',
                    '& > :not(style)': {
                        m: 1,
                        width: 380,
                        height: '65%',
                    },
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    margin: 0
                }}
            >
                <Box
                    sx={{
                        px: '5vh',
                        py: '5vh',
                        textAlign: "center",
                        margin: 0
                    }}
                >
                    <img src={Logo} alt="LAE HOMES" style={{ width: '70%', marginBottom: 10 }} />
                    <Typography variant="subtitle1" gutterBottom sx={{ mb: 3, color: "#bababa" }} >
                        Establecer Contraseña de Usuario
                    </Typography>
                    <form onSubmit={handleSubmit} autocomplete="off">

                        <TextField
                            sx={inputStyles}
                            label="Contraseña"
                            variant="outlined"
                            id="textbox"
                            type="password"
                            //focused
                            name='newPass'
                            fullWidth
                            value={values.newPass}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autocomplete="off"
                            error={touched.newPass && Boolean(errors.newPass)}
                            helperText={touched.newPass && errors.newPass}

                        />
                        <TextField
                            sx={inputStyles}
                            label="Repetir Contraseña"
                            variant="outlined"
                            id="textbox"
                            type="password"
                            //focused
                            name='repeatNewPass'
                            fullWidth
                            value={values.repeatNewPass}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autocomplete="off"
                            error={touched.repeatNewPass && Boolean(errors.repeatNewPass)}
                            helperText={touched.repeatNewPass && errors.repeatNewPass}

                        />
                        {/* {status && (
                            <Typography
                                sx={{ mb: 2, color: status.error ? 'red' : 'green' }}
                            >
                                {status.error || status.success}
                            </Typography>
                        )} */}
                        <Button variant="contained" type="submit" sx={{
                            backgroundColor: '#212529',
                            ':hover': {
                                backgroundColor: '#fff',
                                boxShadow: "none",
                                border: "1px solid #33383b" // Cambia este valor al color deseado
                            },
                            color: "rgb(196, 175, 108)",

                        }} fullWidth >
                            Establecer Contraseña
                        </Button>
                    </form>
                </Box>
            </Paper>
        </Box>
    );
};

const inputStyles = {
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#33383b !important', // Cambia el color del label cuando el TextField está seleccionado
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c4af6c',
        },
    },
    '& .MuiInputAdornment-root': {
        color: '#333', // Color del adorno
    },
    width: "100%",
    marginBottom: 4,
    marginTop: 0
};

export default RestablecerPassword;

import React, { useState, useEffect } from "react";
import {
  ChevronLeft,
  XLg,
  CheckLg,
  PencilFill,
  PlusCircle,
} from "react-bootstrap-icons";
import { FaFloppyDisk } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { Row, Col } from "react-bootstrap";
import BreadCrumb from "../../components/navbar/Breadcrumb";
import {
  getPropertie,
  editTextProperties,
  createTextsPropertie,
  listLanguages,
} from "../../services";
import DatosPropiedad from "./DatosPropiedad";
import DocumentosInmueble from "./DocumentosPropiedad";
import SubirDocumento from "../../components/SubirDocumento";
import { Gb, Es } from "react-flags-select";
import Swal from "sweetalert2";
import TabFormularioIdiomas from "../../components/TabsFormularioIdiomas";
import { ObraNuevaForm, PropiedadForm } from "../../components/Forms";
import { Multimedia } from "../../components";
import { validateTextosIdioma, SnackbarUtilities } from "../../utilities";
const reducerDocumentos = (state, action) => {
  switch (action.type) {
    case "SUCCESS":
      return action.data;
    default:
      return state;
  }
};
const FichaPropiedad = () => {
  const [tab, setTab] = useState(0);
  const [tabL, setTabL] = useState(0);
  // const [idiomas, setIdiomas] = useReducer(reducerIdiomas, []);
  const [idiomas, setIdiomas] = useState([]);
  const [editando, setEditando] = useState(false);
  const [editarDatos, setEditarDatos] = useState(false);
  const [property, setProperty] = useState({});
  const [textos, setTextos] = useState([])
  const [documentos, setDocumentos] = useState([]);
  const [textosIdioma, setTextosIdioma] = useState([]);
  const params = useParams();
  const { ref } = params;
  const [load, setLoad] = useState(false);
  const [showButton, setShoWbutton] = useState("d-none");
  const [propImages, setPropImages] = useState([]);
  // const [images, setImages] = useState([]);
  const images = [];
  const enviarDatos = (newState) => {
    setEditarDatos(newState);
  };
  const fetchData = async () => {
    let data = await getPropertie(ref);
    setTextos(data?.textos);
    // console.log(data);
    
    setProperty(data);
    setDocumentos(data?.documentos);
  };
  useEffect(() => {
    fetchIdiomas();
    fetchData();
  }, []);

  const mostrarAlertas = (alertas) => {
    let alertShown = false; 
    for (let key in alertas) {
        if (alertas[key]['es'] !== '') {
            SnackbarUtilities.error(alertas[key]['es']);
            alertShown = true; 
        }
        if (alertas[key]['en'] !== '') {
            SnackbarUtilities.error(alertas[key]['en']);
            alertShown = true; 
        }
    }
    return alertShown;
  };
  const guardarDatos = async () => {
    let alert = validateTextosIdioma(textosIdioma);
    let alertShown = mostrarAlertas(alert);
    // console.log(alertShown);
    
    if (!alertShown) {
        if (textosIdioma.some((objeto) => objeto.id !== undefined)) {
            const res = await editTextProperties(params.ref, textosIdioma);
            if (res === 201) {
                // console.log(res);
                Swal.fire({
                    icon: "success",
                    title: "Textos actualizados",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error al actualizar los textos",
                    text: res[0].message,
                });
            }
        } else {
            const resC = await createTextsPropertie(params.ref, textosIdioma);
            // console.log(resC);
            if (resC === 201) {
                Swal.fire({
                    icon: "success",
                    title: "Textos añadidos!",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error al añadir los textos",
                    text: resC[0].message,
                });
            }
        }
    }
  };
  const fetchIdiomas = async () => {
    try {
      const response = await listLanguages({ offset: 1, limit: 1000 });
      setIdiomas(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className="container ficha-cliente">
        {/* <BreadCrumb data={property?.referencia} /> */}
        <div className="topline">
          <div className="usuario">
            <Link to="/propiedades">
              <button className="btn back">
                <ChevronLeft />
              </button>
            </Link>
          </div>
        </div>
        <Tabs onSelect={(index, label) => setTab(index)}>
          <Tab label="DATOS GENERALES">
            <div className="contenido">
              <div className="header">
                <h2>Datos generales</h2>
              </div>
              <hr />
              <DatosPropiedad property={property} />
            </div>
            <div className="contenido">
              <Row direction="horizontal" gap={3}>
                <Col lg={11}>
                  <div className="header text-left">
                    <h1>Contenido</h1>
                  </div>
                </Col>
                {property?.conservacion == "Obra Nueva"? 
                  <Col lg={1}>
                    <button
                      className="btn btn-default editar"
                      onClick={() => {guardarDatos()}}
                    >
                      <FaFloppyDisk style={{ verticalAlign: "bottom" }} />
                    </button>
                  </Col>
                  :
                  <></> }
              </Row>

              <hr />
              {property.conservacion == "Obra Nueva" ? 
                <Row >
                  <ObraNuevaForm
                    idiomas={idiomas} 
                    textos={textos}
                    setDatosIdioma={setTextosIdioma}
                  />
                </Row>
                :
                <Row>
                  <PropiedadForm
                    idiomas={idiomas} 
                    property={property}
                  />
                </Row> 
              }
              
            </div>
          </Tab>
          {/* OBJETO FORMULARIO PARA OBRANUEVA OTRO PARA PROP NORMAL  */}
          <Tab label="MULTIMEDIA">
            <div className="contenido">
              <Multimedia property={property}/>
            </div>
          </Tab>
          {property?.conservacion == "Obra Nueva" ? 
          <Tab label="DOCUMENTOS">
            <DocumentosInmueble
              ref={params}
              documentos={documentos}
              idiomas={idiomas}
            />
          </Tab>
          :<></>}
        </Tabs>
      </div> 
    </>
  );
};

export default FichaPropiedad;

import { useState, useContext, useEffect } from "react";
import { getPropertie, properties } from "../services";
import { orderBy } from "lodash";
function usePropiedades() {
    const [limite, setLimite] = useState(0);
    const [data, setData] = useState({
        meta:{},
        rows: [],
    });
    const [loading, setLoading] = useState(true);
    const [dataCustomNames, setDataCustomNames] = useState(null);
    const customNames = [
        'ID', 'Referencia','Agente', 'Oficina', 'Provincia',
        'Ciudad', 'Estado','MLS','Situación','id_inmov','id_sucursal'];

        const customNameDataTable = (formattedData) => {
            const keys = Object.keys(formattedData[0] || {});
            // Retornar un array de objetos con key y label
            return keys.map((key, index) => ({
                key,  // La clave original
                label: customNames[index] || key  // El nombre personalizado o la clave si no hay un nombre en customNames
            }));
        }
    const formatData = (data) => {
        // data.map((item)=>console.log(item?.sucursal?.municipio));
        return data.map((row) => (
            // console.log(row?.sucursal),
            
           {
            id: row.id,
            referencia: row.referencia,
            agente: row.agente,
            sucursal_municipio:row?.sucursal?.municipio,
            provincia: row.provincia,
            ciudad: row.ciudad,
            conservacion: row.conservacion,
            from_mls: row.from_mls,
            published: row?.published,
            id_inmov: row.id_inmov,
            numsucursal: row.numsucursal,
            sucursal_id: row.sucursal_id,
            sucursal_provincia:row?.sucursal?.provincia,
            tfachada: row.tfachada,
            tcocina: row.tcocina,
            tpostigo: row.tpostigo,
            tbano: row.tbano,
            tinterior: row.tinterior,
            distmar: row.distmar,
            planta: row.planta,
            gastos_com: row.gastos_com,
            habdobles: row.habdobles,
            habitaciones: row.habitaciones,
            m_cons: row.m_cons,
            m_parcela: row.m_parcela,
            m_uties: row.m_uties,
            m_cocina: row.m_cocina,
            m_comedor: row.m_comedor,
            m_terraza: row.m_terraza,
            numplanta: row.numplanta,
            orientacion: row.orientacion,
            salon: row.salon,
            precioinmo: row.precioinmo,
            precioalq: row.precioalq,
            accion: row.accion,
            cp: row.cp,
            zona: row.zona,
            adaptadominus: row.adaptadominus,
            airecentral: row.airecentral,
            aire_con: row.aire_con,
            alarma: row.alarma,
            alarmaincendio: row.alarmaincendio,
            alarmarobo: row.alarmarobo,
            arma_empo: row.arma_empo,
            ascensor: row.ascensor,
            balcon: row.balcon,
            bar: row.bar,
            jardin: row.jardin,
            barbaco: row.barbaco,
            cajafuerte: row.cajafuerte,
            keycalefa: row.keycalefa,
            keysuelo: row.keysuelo,
            calefacentral: row.calefacentral,
            calefaccion: row.calefaccion,
            chimenea: row.chimenea,
            depoagua: row.depoagua,
            descalcificador: row.descalcificador,
            despensa: row.despensa,
            diafano: row.diafano,
            electro: row.electro,
            esquina: row.esquina,
            galeria: row.galeria,
            plaza_gara: row.plaza_gara,
            garajedoble: row.garajedoble,
            gasciudad: row.gasciudad,
            gimnasio: row.gimnasio,
            habjuegos: row.habjuegos,
            tipomensual: row.tipomensual,
            hidromasaje: row.hidromasaje,
            jacuzzi: row.jacuzzi,
            lavanderia: row.lavanderia,
            linea_tlf: row.linea_tlf,
            luminoso: row.luminoso,
            ibi: row.ibi,
            luz: row.luz,
            muebles: row.muebles,
            ojobuey: row.ojobuey,
            parking: row.parking,
            patio: row.patio,
            piscina_com: row.piscina_com,
            piscina_prop: row.piscina_prop,
            preinstaacc: row.preinstaacc,
            primera_line: row.primera_line,
            puerta_blin: row.puerta_blin,
            satelite: row.satelite,
            sauna: row.sauna,
            solarium: row.solarium,
            sotano: row.sotano,
            mirador: row.mirador,
            apartseparado: row.apartseparado,
            bombafriocalor: row.bombafriocalor,
            buhardilla: row.buhardilla,
            pergola: row.pergola,
            tv: row.tv,
            terraza: row.terraza,
            terrazaacris: row.terrazaacris,
            todoext: row.todoext,
            trastero: row.trastero,
            urbanizacion: row.urbanizacion,
            nplazasparking: row.nplazasparking,
            vestuarios: row.vestuarios,
            video_port: row.video_port,
            vistasalmar: row.vistasalmar,
            energialetra: row.energialetra,
            energiarecibido: row.energiarecibido,
            energiavalor: row.energiavalor,
            emisionesletra: row.emisionesletra,
            emisionesvalor: row.emisionesvalor,
            cocina_inde: row.cocina_inde,
            keyelectricidad: row.keyelectricidad,
            keyfachada: row.keyfachada,
            keyagua: row.keyagua,
            keyvista: row.keyvista,
            keytecho: row.keytecho,
            montana: row.montana,
            rural: row.rural,
            costa: row.costa,
            vallado: row.vallado,
            autobuses: row.autobuses,
            centros_comerciales: row.centros_comerciales,
            tranvia: row.tranvia,
            zonas_infantiles: row.zonas_infantiles,
            colegios: row.colegios,
            arboles: row.arboles,
            hospitales: row.hospitales,
            tren: row.tren,
            metro: row.metro,
            golf: row.golf,
            estadoficha: row.estadoficha,
            exclu: row.exclu,
            tour: row.tour,
            video1: row.video1,
            video1_url: row.video1_url,
            keyagente: row.keyagente,
            email_agente: row.email_agente,
            prefijo_tlf_agente: row.prefijo_tlf_agente,
            tlf_agente: row.tlf_agente,
            numpanos: row.numpanos,
            createdAt: row.createdAt,
            updatedAt: row.updatedAt,
            status: row.status,
        }));
    };
    const fetchData = async({offset, limit, filtros, orderBy, order}) => {
        setLimite(limit);
        let payload;
        if (orderBy&&order) {
             payload = {
                params: {page: parseInt(offset), limit:parseInt(limit),orderBy:`${orderBy},${order}`},
                filtros
            };
        }else {
             payload = {
                params: {page: parseInt(offset), limit:parseInt(limit)},
                filtros
            };
        }

        
        try {
            setLoading(true);
            // console.log(localStorage.getItem("token"));
            
            let response = await properties(payload);   
            // console.log(response);

            const { meta, data } = response;
            const formattedData = formatData(data);  // Formatear los datos
            const customData = customNameDataTable(formattedData);  
            setData({
                meta,
                rows: formatData(data),
            });
            setDataCustomNames(customData);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return {
        data,
        fetchData,
        loading,
        formatData,
        limite,
        dataCustomNames
    };
}
export default usePropiedades;
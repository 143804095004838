import { Typography, Button } from "@mui/material";

export const dynamicTableHeader = ({headerArrayData,showJustThis,deleteItems,addNewCustomHeader,userPermision,permissionResourceName}) => {
  try {
    let headers = [];
    
    // Verifica si `headerArrayData` es válido
    if (!headerArrayData || headerArrayData.length === 0) {
      // console.log('headerArrayData es null o vacío.');
      return [
        {
          name: 'error',
          numeric: false,
          disablePadding: false,
          label: '',
          overrideFunc: (data, row) => <Typography>Error en los datos</Typography>,
        },
      ];
    }
    // Quitar elementos de los datos recibidos generales (si deleteItems está presente)
    if (deleteItems?.length > 0) {
      headerArrayData = headerArrayData.filter(
        (obj) => !deleteItems.includes(obj.label)
      );
    }

    // Mostrar solo los elementos indicados (si showJustThis está presente)
    if (showJustThis?.length > 0) {
      const notFound = [];
      headerArrayData = showJustThis.map((str) => {
        
        const foundObj = headerArrayData.find((obj) => obj.label === str);
        if (!foundObj) {
          notFound.push(str); // Si no encuentra el item, lo añade a notFound
        }
        return foundObj;
      }).filter((obj) => obj !== undefined); // Filtramos undefined

      if (notFound.length > 0) {
        // console.log(
        //   'Items no encontrados o nombres incorrectos:'+
        //   JSON.stringify(notFound)
        // );
      }
    }

    // Crear los headers a partir de headerArrayData
    headers = headerArrayData.map((key) => ({
      name: key.key,
      numeric: false,
      disablePadding: false,
      order: key.order || null, // Permite añadir `order: "disable"`
      label: key.label,
      overrideFunc: (data, row) => (
        <Typography
          sx={{
            color: '#33383b !important',
            '&:hover': { color: '#33383b' },
            '&:active': { color: '#33383b' },
            fontSize: '0.9rem',
            fontWeight: '1rem',
          }}
        >
          {row[key.key]}
        </Typography>
      ),
    }));

    // Añadir headers personalizados si existe addNewCustomHeader
    if (addNewCustomHeader?.length > 0) {
      addNewCustomHeader.forEach((element) => {
        // Formatear la posición para empezar desde 1, y -1 para añadir al final
        let position =
          element.position >= 0
            ? element.position - 1
            : headers.length + element.position + 1;

        headers.splice(position, 0, {
          name: element.nameColumn,
          numeric: false,
          disablePadding: false,
          label: element.titleHeader,
          order: element.order || null, // Personalizar `order`
          custom:true,
          overrideFunc: (data, row) => element.item(row),
        });
      });
    }

    //Verificar si existen permisos para ocultar o ver 
    if (userPermision?.rol?.permisos?.tablas?.hasOwnProperty(permissionResourceName)) {
      const sucursalesValues = userPermision?.rol?.permisos?.tablas[permissionResourceName];
      headers = headers.filter(item => !userPermision.rol.permisos.tablas[permissionResourceName].includes(item.label));
    }
    
    return headers;
  } catch (error) {
    console.log('Error en dynamicTableHeader:', error);

    // En caso de error, retornar un header por defecto
    return [
      {
        name: 'error',
        numeric: false,
        disablePadding: false,
        label: 'Error en los datos',
        overrideFunc: (data, row) => (
          <Typography>Error en los datos</Typography>
        ),
      },
    ];
  }
};